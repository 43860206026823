import { Component, EventEmitter, OnInit } from '@angular/core';
import { NgForm, RadioControlValueAccessor } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { CategoryApiService } from '../../../core/apis/moderation-api/category-api.service';
import { Alert } from '../../../shared/models/alert.model';
import { UpdateKeyRequestModel } from '../../models/webapi/request/update-key-request.model';
import { KeyResult } from '../../../shared/models/key-result.model';

@Component({
  selector: 'app-key-edit-modal',
  templateUrl: './key-edit-modal.component.html',
  styleUrls: ['./key-edit-modal.component.scss']
})
export class KeyEditModalComponent implements OnInit {
  updateKey: UpdateKeyRequestModel;
  alert: Alert;

  isSubmitting: boolean;

  keyUpdated: EventEmitter<string> = new EventEmitter<string>();

  constructor(public bsModalRef: BsModalRef, private categoryApi: CategoryApiService) {}

  ngOnInit() {
    const test = this.updateKey.isActive;
  }

  saveKey() {
    this.isSubmitting = true;
    this.alert = null;
    this.categoryApi.updateKey(this.updateKey).subscribe(
      (keyResult: KeyResult) => {
        this.keyUpdated.next('complete');
        this.keyUpdated.complete();
        this.bsModalRef.hide();
      },
      error => {
        console.log(error);
        this.isSubmitting = false;
        this.alert = Alert.danger(`Error updating key: ${this.updateKey.name}.`);
      }
    );
  }
}
