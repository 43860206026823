import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { CategoryApiService } from '../../../core/apis/moderation-api/category-api.service';
import { Alert } from '../../../shared/models/alert.model';
import { KeyResult } from '../../../shared/models/key-result.model';
import { CategoryRequestModel } from '../../models/webapi/request/category-request.model';

@Component({
  selector: 'app-category-edit-modal',
  templateUrl: './category-edit-modal.component.html',
  styleUrls: ['./category-edit-modal.component.scss']
})
export class CategoryEditModalComponent implements OnInit {
  category: CategoryRequestModel;
  alert: Alert;

  isSubmitting: boolean;

  categoryUpdated: EventEmitter<string> = new EventEmitter<string>();

  constructor(public bsModalRef: BsModalRef, private categoryApi: CategoryApiService) {}

  ngOnInit() {}

  saveCategory() {
    this.isSubmitting = true;
    this.alert = null;
    this.categoryApi.updateCategory(this.category).subscribe(
      (keyResult: KeyResult) => {
        this.categoryUpdated.next('complete');
        this.categoryUpdated.complete();
        this.bsModalRef.hide();
      },
      error => {
        this.isSubmitting = false;
        this.alert = Alert.danger('Error updating category.');
      }
    );
  }
}
