import { OnInit } from '@angular/core';
import { OidcSecurityService, AuthorizationState } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
var SigninOidcComponent = /** @class */ (function () {
    function SigninOidcComponent(oidc, router) {
        var _this = this;
        this.oidc = oidc;
        this.router = router;
        if (this.oidc.moduleSetup) {
            this.doCallbackLogicIfRequired();
        }
        else {
            this.oidc.onModuleSetup.subscribe(function () {
                _this.doCallbackLogicIfRequired();
            });
        }
        this.oidc.onAuthorizationResult.subscribe(function (result) {
            // console.log(result);
            if (result.authorizationState === AuthorizationState.authorized) {
                // TODO: Take them to where they were attempting to go
                _this.router.navigateByUrl('/home');
            }
            else if (result.authorizationState === AuthorizationState.unauthorized) {
                _this.router.navigateByUrl('/unauthorized');
            }
        });
    }
    SigninOidcComponent.prototype.doCallbackLogicIfRequired = function () {
        if (window.location.hash) {
            this.oidc.authorizedImplicitFlowCallback();
        }
    };
    SigninOidcComponent.prototype.ngOnInit = function () { };
    return SigninOidcComponent;
}());
export { SigninOidcComponent };
