import { RuleSetsComponent } from './pages/rulesets/rulesets.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { RuleSetComponent } from './ruleset.component';
import { RulesetWizardComponent } from './pages/ruleset-wizard/ruleset-wizard.component';

const ruleSetRoutes: Routes = [
  {
    path: 'rulesets',
    component: RuleSetComponent,
    children: [
      // Retain old paths for compatibility, but use new component
      { path: '', component: RuleSetsComponent },
      { path: 'create', component: RulesetWizardComponent },
      { path: 'create/wizard', component: RulesetWizardComponent },
      { path: ':programKey/:ruleSetKey/edit', component: RulesetWizardComponent, data: { isEdit: true } },
      { path: ':programKey/:ruleSetKey/wizard', component: RulesetWizardComponent, data: { isEdit: true } },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(ruleSetRoutes)],
  exports: [RouterModule],
  providers: []
})
export class RuleSetRoutingModule { }
