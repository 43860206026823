export class Alert {
  public type: 'success' | 'warning' | 'info' | 'danger';
  public message: string;
  public timeout: number;

  static success(message: string, timeout: number = 5000) {
    return Object.assign(new Alert(), {
      type: 'success',
      message: message,
      timeout: timeout
    });
  }

  static warning(message: string, timeout: number = 5000) {
    return Object.assign(new Alert(), {
      type: 'warning',
      message: message,
      timeout: timeout
    });
  }

  static info(message: string, timeout: number = 5000) {
    return Object.assign(new Alert(), {
      type: 'info',
      message: message,
      timeout: timeout
    });
  }

  static danger(message: string, timeout: number = 5000): Alert {
    return Object.assign(new Alert(), {
      type: 'danger',
      message: message,
      timeout: timeout
    });
  }
}
