import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Constants } from '../../../constants';
import { CrossAccountService } from './cross-account.service';
var CrossAccountComponent = /** @class */ (function () {
    // TODO: Implement this so that they're shown this if they don't have the PL Admin Account Key header/cookie!
    function CrossAccountComponent(route, router, cookieService, crossAccountService) {
        this.route = route;
        this.router = router;
        this.cookieService = cookieService;
        this.crossAccountService = crossAccountService;
    }
    CrossAccountComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        this.route.data.subscribe(function (data) {
            _this.accounts = data.crossAccount;
        });
        if (this.cookieService.check(Constants.HeaderAdminAccountKey)) {
            this.chosenAccountKey = this.cookieService.get(Constants.HeaderAdminAccountKey);
            this.chosenAccountName = this.cookieService.get(Constants.CookieAdminAccountName);
        }
    };
    CrossAccountComponent.prototype.onAccountChoice = function (accountKey, accountName) {
        if (accountKey) {
            this.chosenAccountKey = accountKey;
            this.chosenAccountName = accountName;
            this.crossAccountService.selectAccount(accountKey, accountName);
            this.redirecting = true;
            if (this.returnUrl) {
                this.router.navigateByUrl(this.returnUrl);
            }
            else {
                this.router.navigate(['/']);
            }
        }
    };
    return CrossAccountComponent;
}());
export { CrossAccountComponent };
