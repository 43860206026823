import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';

import { Constants } from '../../../constants';
import { CategoryApiService } from '../../../core/apis/moderation-api/category-api.service';
import { ProgramApiService } from '../../../core/apis/moderation-api/program-api.service';
import { Alert } from '../../../shared/models/alert.model';
import { ContinuationTokenResponseModel } from '../../../shared/models/continuation-token-response.model';
import { CategoryResultsModel } from '../../../shared/models/webapi/response/category-results.model';
import { CategoryModel } from '../../../shared/models/webapi/response/category.model';
import { KeyResultsModel } from '../../../shared/models/webapi/response/key-results.model';
import { KeyModel } from '../../../shared/models/webapi/response/key.model';
import { ProgramModel } from '../../../shared/models/webapi/response/ProgramModel';
import { ProgramResultsModel } from '../../../shared/models/webapi/response/ProgramResultsModel';
import { UpdateKeyRequestModel } from '../../models/webapi/request/update-key-request.model';
import { CategoryCreateModalComponent } from '../category-create-modal/category-create-modal.component';
import { CategoryEditModalComponent } from '../category-edit-modal/category-edit-modal.component';
import { KeyEditModalComponent } from '../key-edit-modal/key-edit-modal.component';
import { KeyCreateModalComponent } from '../key-create-modal/key-create-modal.component';
import { KeyRequestModel } from '../../models/webapi/request/key-request.model';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  isSubmittingKey: boolean;
  private maxItems = Constants.DefaultMaxItems;
  searchKeywords: string;
  isSearching: boolean;
  hasSearched: boolean;
  isLoading: boolean;
  hasLoaded: boolean;
  noMoreResults: boolean;
  loadMoreSubscription: any;
  fetchingMore: boolean;
  alert: Alert;

  // Programs
  isLoadingPrograms: boolean;
  programs: ProgramModel[];
  selectedProgram: ProgramModel;

  // The currently shown modals
  createCaterogyModalRef: BsModalRef;
  editCategoryModalRef: BsModalRef;
  createKeyModalRef: BsModalRef;
  editKeyModalRef: BsModalRef;

  // Category related
  categories: CategoryModel[] = [];
  categoryKeys: Map<string, KeyModel[]> = new Map<string, KeyModel[]>();
  isLoadingKeys: Map<string, boolean> = new Map<string, boolean>();
  hadLoadedKeys: Map<string, boolean> = new Map<string, boolean>();

  continuationToken: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private programApi: ProgramApiService,
    private cookieService: CookieService,
    private categoryApi: CategoryApiService,
    private modalService: BsModalService
  ) {
    this.isLoadingPrograms = true;
    this.isLoading = false;
  }

  ngOnInit() {
    this.programApi.getPrograms().subscribe((programResult: ProgramResultsModel) => {
      this.isLoadingPrograms = false;
      this.programs = programResult.programs;
      if (this.programs.length === 1) {
        // auto select the only one
        this.selectedProgram = this.programs[0];
        this.onProgramChange();
      } else if (this.cookieService.get(Constants.HeaderProgramKey)) {
        const programKey = this.cookieService.get(Constants.HeaderProgramKey);
        this.selectedProgram = this.programs.filter(p => p.programKey === programKey)[0];
        this.onProgramChange();
      }
    });
  }

  getRecentlyUpdatedCategories() {
    this.isLoading = true;
    this.hasLoaded = false;
    this.noMoreResults = false;

    this.categoryApi
      .getRecentlyUpdatedCategories(this.maxItems)
      .subscribe((response: ContinuationTokenResponseModel<CategoryResultsModel>) => {
        this.continuationToken = response.continuationToken;
        this.isLoading = false;
        this.hasLoaded = true;
        this.categories.length = 0;

        if (response.model && response.model.results.length > 0) {
          this.categories.push(...response.model.results);
        }
      });
  }

  loadMore() {
    this.fetchingMore = true;
    if (!this.hasSearched) {
      this.loadMoreSubscription = this.categoryApi
        .getRecentlyUpdatedCategoriesContinuation(this.continuationToken)
        .subscribe((response: ContinuationTokenResponseModel<CategoryResultsModel>) => {
          this.continuationToken = response.continuationToken;
          if (response.model && response.model.results.length > 0) {
            this.categories.push(...response.model.results);
          } else {
            this.noMoreResults = true;
          }
          this.fetchingMore = false;
          this.loadMoreSubscription.unsubscribe();
        });
    } else {
      this.loadMoreSubscription = this.categoryApi
        .searchCategoryContinuation(this.continuationToken)
        .subscribe((response: ContinuationTokenResponseModel<CategoryResultsModel>) => {
          this.continuationToken = response.continuationToken;
          if (response.model && response.model.results.length > 0) {
            this.categories.push(...response.model.results);
          } else {
            this.noMoreResults = true;
          }
          this.fetchingMore = false;
          this.loadMoreSubscription.unsubscribe();
        });
    }
  }

  searchCategories() {
    this.hasSearched = false;
    this.isSearching = true;
    this.noMoreResults = false;

    this.categoryApi
      .searchCategories(this.searchKeywords || '', this.maxItems)
      .subscribe((response: ContinuationTokenResponseModel<CategoryResultsModel>) => {
        this.continuationToken = response.continuationToken;
        this.hasSearched = true;
        this.isSearching = false;
        this.categories.length = 0;

        if (response.model && response.model.results.length > 0) {
          this.categories.push(...response.model.results);
        }
      });
  }

  clearResults() {
    this.hasSearched = false;
    this.isSearching = false;
    this.noMoreResults = false;
    this.searchKeywords = '';
    this.categories.length = 0;
    this.getRecentlyUpdatedCategories();
  }

  openCreateCategoryModal() {
    const config = {
      keyboard: true
    };
    this.createCaterogyModalRef = this.modalService.show(CategoryCreateModalComponent, config );
    this.createCaterogyModalRef.content.categoryCreated.subscribe(category => {
      this.clearResults();
    });
  }

  openEditCategoryModal(categoryKey: string) {
    this.categoryApi.getCategory(categoryKey).subscribe(
      (category: CategoryModel) => {
        const config = {
          keyboard: true,
          initialState: { category }
        };
        this.editCategoryModalRef = this.modalService.show(CategoryEditModalComponent, config);
        this.editCategoryModalRef.content.categoryUpdated.subscribe(result => {
          this.clearResults();
        });
      },
      error => {
        this.alert = Alert.danger('Error creating category.');
      }
    );
  }

  openCreateKeyModal(categoryKey: string) {
    this.alert = null;
    const createKey = new KeyRequestModel();
    createKey.categoryKey = categoryKey;
    createKey.isActive = true;
    const config = {
      keyboard: true,
      initialState: { createKey }
    };
    this.createKeyModalRef = this.modalService.show(KeyCreateModalComponent, config );
    this.createKeyModalRef.content.keyCreated.subscribe(category => {
      this.getKeysForCategory(categoryKey);
      this.categoryApi.getCategory(categoryKey).subscribe(
        (updatedCategory: CategoryModel) => {
          const parentCategory = this.categories.find(x => x.categoryKey === categoryKey);
          parentCategory.activeKeyCount = updatedCategory.activeKeyCount;
          parentCategory.inactiveKeyCount = updatedCategory.inactiveKeyCount;
        }
      );
    },
    error => {
      this.alert = Alert.danger('Error creating key.');
    });
  }

  openEditKeyModal(categoryKey: string, keyName: string) {
    this.alert = null;
    this.categoryApi.getKey(categoryKey, keyName).subscribe(
      (key: KeyModel) => {
        if (key) {
        const updateKey = new UpdateKeyRequestModel();
        updateKey.categoryKey = key.categoryKey;
        updateKey.name = key.name;
        updateKey.newName = key.name;
        updateKey.isActive = key.isActive;
        const config = {
          keyboard: true,
          initialState: { updateKey }
        };
        this.editKeyModalRef = this.modalService.show(KeyEditModalComponent, config);
        this.editKeyModalRef.content.keyUpdated.subscribe(result => {
          this.getKeysForCategory(categoryKey);
          this.categoryApi.getCategory(categoryKey).subscribe(
            (updatedCategory: CategoryModel) => {
              const parentCategory = this.categories.find(x => x.categoryKey === categoryKey);
              parentCategory.activeKeyCount = updatedCategory.activeKeyCount;
              parentCategory.inactiveKeyCount = updatedCategory.inactiveKeyCount;
            }
          );
        });
      } else {
        this.alert = Alert.danger('Error retrieving key.');
      }
      },
      error => {
        this.alert = Alert.danger('Error retrieving key.');
      }
    );
  }

  getKeysForCategoryStateChange(isOpening: EventTarget, categoryKey: string) {
    if (isOpening) {
      this.getKeysForCategory(categoryKey);
    } else {
      if (this.categoryKeys[categoryKey]) {
        this.categoryKeys[categoryKey].length = 0;
      }
    }
  }

  getKeysForCategory(categoryKey: string) {
    this.alert = null;
    this.isLoadingKeys[categoryKey] = true;
    this.categoryApi.getKeysForCategory(categoryKey).subscribe(
      (response: KeyResultsModel) => {
        if (this.categoryKeys[categoryKey]) {
          this.categoryKeys[categoryKey].length = 0;
        }

        if (response.results && response.results.length > 0) {
          this.categoryKeys[categoryKey] = response.results;
        }

        this.isLoadingKeys[categoryKey] = false;
      },
      error => {
        this.isLoadingKeys[categoryKey] = false;
        this.alert = Alert.danger('Error retreiving keys category.');
      }
    );
  }

  onProgramChange() {
    if (this.selectedProgram) {
      // The ProgramKeyInterceptor will pick this up. This is here until we get a program selection page
      this.cookieService.set(Constants.HeaderProgramKey, this.selectedProgram.programKey);
      this.getRecentlyUpdatedCategories();
    } else {
      this.cookieService.delete(Constants.HeaderProgramKey);
      this.clearResults();
    }
  }
}
