import { Component, OnInit, TemplateRef } from '@angular/core';
import { StoreApiService } from '../../../core/apis/moderation-api/store-api.service';
import { StoreResultsModel } from '../../../shared/models/webapi/response/StoreResultsModel';
import { StoreModel } from '../../../shared/models/webapi/response/StoreModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Alert } from '../../../shared/models/alert.model';
import { StoreAliasModel } from '../../../shared/models/webapi/response/store-alias.model';

@Component({
  selector: 'app-global-stores',
  templateUrl: './global-stores.component.html',
  styleUrls: ['./global-stores.component.scss']
})
export class GlobalStoresComponent implements OnInit {
  public isProcessing = true;
  storeResults: StoreResultsModel;
  hasStores = false;
  alert: Alert;
  public searchTerm = '';
  public filteredStores: Array<StoreModel> = [];
  public createAliasModalRef: BsModalRef;
  public newAliasText = '';
  public deleteAliasModalRef: BsModalRef;
  public createModalRef: BsModalRef;
  public newRetailerText = '';
  public selectedStoreForAlias: StoreModel;
  public selectedAlias: StoreAliasModel;

  constructor(private storeApi: StoreApiService, private bsModalService: BsModalService) {}

  ngOnInit() {
    this.refreshStores();
  }

  private refreshStores(): void {
    this.storeApi.getStores().subscribe((storeResults: StoreResultsModel) => {
      if (storeResults) {
        this.storeResults = storeResults;
        this.hasStores = this.storeResults.stores.length > 0;
        this.filteredStores = this.storeResults.stores;
        this.filterStores(); // sort
        this.isProcessing = false;
      }
    });
  }

  clearTerm() {
    this.searchTerm = '';
    this.filterStores();
  }

  filterStores() {
    if (this.searchTerm.trim() === '') {
      this.filteredStores = this.storeResults.stores.sort(this.storeSort);
      return;
    }

    const searchString = this.searchTerm.trim().toLocaleLowerCase();

    const storeMatch = function(store: StoreModel): boolean {
      return store.name.toLocaleLowerCase().indexOf(searchString) > -1 || store.aliases.filter(a => a.name.toLocaleLowerCase().indexOf(searchString) > -1).length > 0;
    };

    // this.filteredStores = this.storeResults.stores.filter(x => x.name.toLocaleLowerCase().indexOf(this.searchTerm.trim().toLocaleLowerCase()) > -1).sort(this.storeSort);
    this.filteredStores = this.storeResults.stores.filter(storeMatch).sort(this.storeSort);
  }

  private storeSort = function(a: StoreModel, b: StoreModel) {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
  };

  openCreateStores(template: TemplateRef<any>) {
    this.createModalRef = this.bsModalService.show(template);
  }

  openCreateAliases(store: StoreModel, template: TemplateRef<any>) {
    this.selectedStoreForAlias = store;
    this.createAliasModalRef = this.bsModalService.show(template);
  }

  openDeleteAlias(store: StoreModel, alias: StoreAliasModel, template: TemplateRef<any>) {
    this.selectedStoreForAlias = store;
    this.selectedAlias = alias;
    this.deleteAliasModalRef = this.bsModalService.show(template);
  }

  deleteAlias() {
    this.alert = undefined;
    this.isProcessing = true;
    this.deleteAliasModalRef.hide();

    this.storeApi.deleteAliasForStore(this.selectedStoreForAlias, this.selectedAlias).subscribe(
      () => {
        this.refreshStores();
      },
      errorResponse => {
        const error = errorResponse.error;
        this.isProcessing = false;
        console.log(error);
        if (error.errors && error.errors.length > 0) {
          const err = error.errors[0];
          this.alert = Alert.danger(err.message);
        } else {
          this.alert = Alert.danger('Unknown error deleting aliases.');
        }
      }
    );
  }

  createStores() {
    this.alert = undefined;
    this.newRetailerText = this.newRetailerText.trim();

    const newStoreTexts: Array<string> = [];
    this.newRetailerText.split('\n').forEach(text => {
      text = text.trim();
      if (text === '') {
        return;
      }
      if (newStoreTexts.find(x => x.toLowerCase() === text.toLowerCase())) {
        return;
      }
      newStoreTexts.push(text);
    }, this);

    this.createModalRef.hide();

    if (newStoreTexts.length === 0) {
      return;
    }

    this.isProcessing = true;

    this.storeApi.createGlobalStores(newStoreTexts).subscribe(
      () => {
        this.newRetailerText = '';
        this.refreshStores();
      },
      errorResponse => {
        const error = errorResponse.error;
        this.isProcessing = false;
        console.log(error);
        if (error.errors && error.errors.length > 0) {
          const err = error.errors[0];
          this.alert = Alert.danger(err.message);
        } else {
          this.alert = Alert.danger('Unknown error adding Retailers.');
        }
      }
    );
  }

  createAliases() {
    this.alert = undefined;
    this.newAliasText = this.newAliasText.trim();

    const newAliases: Array<string> = [];
    this.newAliasText.split('\n').forEach(text => {
      text = text.trim();
      if (text === '') {
        return;
      }
      if (newAliases.find(x => x.toLowerCase() === text.toLowerCase())) {
        return;
      }
      newAliases.push(text);
    }, this);

    this.createAliasModalRef.hide();
    if (newAliases.length === 0) {
      return;
    }

    this.isProcessing = true;

    this.storeApi.createAliasesForStore(this.selectedStoreForAlias, newAliases).subscribe(
      () => {
        this.newAliasText = '';
        this.refreshStores();
      },
      errorResponse => {
        const error = errorResponse.error;
        this.isProcessing = false;
        console.log(error);
        if (error.errors && error.errors.length > 0) {
          const err = error.errors[0];
          this.alert = Alert.danger(err.message);
        } else {
          this.alert = Alert.danger('Unknown error adding aliases.');
        }
      }
    );
  }
}
