import { OidcSecurityService } from 'angular-auth-oidc-client';
import { OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Constants } from '../../../constants';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(oidc, cookieService) {
        var _this = this;
        this.oidc = oidc;
        this.cookieService = cookieService;
        this.accountName = cookieService.get(Constants.CookieAdminAccountName);
        this.oidc.getIsAuthorized().subscribe(function (isAuthorized) {
            _this.isAuthorized = isAuthorized;
        });
    }
    HomeComponent.prototype.ngOnInit = function () {
    };
    return HomeComponent;
}());
export { HomeComponent };
