import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Constants } from '../../constants';
import { PrizelogicTokenPayloadModel } from '../../shared/models/prizelogic-token-payload-model';

@Injectable()
export class CrossAccountGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private cookieService: CookieService, private oidc: OidcSecurityService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const hasAdminAccountKeyCookie: boolean = this.cookieService.check(Constants.HeaderAdminAccountKey);

    // TODO: Check 'cross_account' in token payload as well
    let isCrossAccount = false;
    const token: string = this.oidc.getToken();
    if (token) {
      const payload: PrizelogicTokenPayloadModel = JSON.parse(this.urlBase64Decode(token.split('.')[1]));
      // Right now, 'cross_account' comes back as a string. In the future it might become a boolean, so let's check
      if (typeof payload.cross_account === 'string') {
        isCrossAccount = (<string>payload.cross_account).match(/true/i).length > 0;
      } else if (typeof payload.cross_account === 'boolean') {
        isCrossAccount = <boolean>payload.cross_account;
      }
    }

    if (!hasAdminAccountKeyCookie && isCrossAccount) {
      const returnUrl = state.url;
      this.router.navigate(['/.cross-account'], { queryParams: { returnUrl } });
    }

    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }

  urlBase64Decode(str: string) {
    if (!str) {
      return str;
    }
    let output = str.replace('-', '+').replace('_', '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw Error('Illegal base64url string!');
    }

    return window.atob(output);
  }
}
