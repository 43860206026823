import { StoresComponent } from './pages/stores/stores.component';
import { Routes } from '@angular/router';
import { StoreComponent } from './store.component';
import { GlobalStoresComponent } from './pages/global-stores/global-stores.component';
var storeRoutes = [
    {
        path: 'stores',
        component: StoreComponent,
        children: [
            { path: '', component: StoresComponent },
            { path: 'globals', component: GlobalStoresComponent }
        ]
    }
];
var StoreRoutingModule = /** @class */ (function () {
    function StoreRoutingModule() {
    }
    return StoreRoutingModule;
}());
export { StoreRoutingModule };
