import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { BaseApi } from '../base-api';
import { Observable } from 'rxjs';
import { VersionModel } from '../../../shared/models/webapi/response/version.model';
import { Injectable } from '@angular/core';

@Injectable()
export class ModerationApiService extends BaseApi {

  constructor(http: HttpClient) {
    super(environment.urls.moderationApi, http);
  }

  getVersion(): Observable<VersionModel> {
    return this.getFor<VersionModel>('/.well-check/version');
  }

}
