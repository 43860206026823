import { environment } from '../../environments/environment';
import { ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProgramKeyInterceptor } from './interceptors/program-key.interceptor';
import { AccountKeyInterceptor } from './interceptors/account-key.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { OidcSecurityService, OpenIdConfiguration, OidcConfigService, ConfigResult } from 'angular-auth-oidc-client';
import { ProgramApiService } from './apis/moderation-api/program-api.service';
import { ProductApiService } from './apis/moderation-api/product-api.service';
import { RuleSetApiService } from './apis/moderation-api/ruleset-api.service';
import { CategoryApiService } from './apis/moderation-api/category-api.service';
import { StoreApiService } from './apis/moderation-api/store-api.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { Router } from '@angular/router';
import { ModerationApiService } from './apis/moderation-api/moderation-api.service';
import { QuickActionsApiService } from './apis/moderation-api/quick-actions-api.service';
export var httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AccountKeyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ProgramKeyInterceptor, multi: true },
];
export var apiServices = [
    ProgramApiService,
    ProductApiService,
    StoreApiService,
    RuleSetApiService,
    QuickActionsApiService,
    CategoryApiService,
    ModerationApiService,
    StoreApiService
];
export function loadConfig(oidcConfigService) {
    return function () { return oidcConfigService.load_using_stsServer(environment.oidc.server); };
}
var CoreModule = /** @class */ (function () {
    function CoreModule(oidcSecurityService, oidcConfigService, router) {
        var _this = this;
        this.oidcSecurityService = oidcSecurityService;
        this.oidcConfigService = oidcConfigService;
        this.oidcConfigService.onConfigurationLoaded.subscribe(function (configResult) {
            var openIDImplicitFlowConfiguration = {};
            openIDImplicitFlowConfiguration.stsServer = environment.oidc.server;
            openIDImplicitFlowConfiguration.redirect_url = environment.oidc.redirectUrl;
            openIDImplicitFlowConfiguration.client_id = environment.oidc.clientId;
            openIDImplicitFlowConfiguration.response_type = 'id_token token';
            openIDImplicitFlowConfiguration.scope = environment.oidc.scopes;
            openIDImplicitFlowConfiguration.post_logout_redirect_uri = environment.oidc.logoutRedirectUrl;
            openIDImplicitFlowConfiguration.start_checksession = false;
            openIDImplicitFlowConfiguration.silent_renew = true;
            openIDImplicitFlowConfiguration.silent_renew_offset_in_seconds = 0;
            openIDImplicitFlowConfiguration.post_login_route = '/home';
            openIDImplicitFlowConfiguration.trigger_authorization_result_event = true; // don't redirect to post_login_route
            openIDImplicitFlowConfiguration.forbidden_route = '/forbidden';
            openIDImplicitFlowConfiguration.unauthorized_route = '/unauthorized';
            openIDImplicitFlowConfiguration.auto_userinfo = true;
            openIDImplicitFlowConfiguration.log_console_warning_active = true;
            openIDImplicitFlowConfiguration.log_console_debug_active = false;
            openIDImplicitFlowConfiguration.max_id_token_iat_offset_allowed_in_seconds = 999999999;
            // Comment out this storage override to use default Session Storage, which is tab specific
            openIDImplicitFlowConfiguration.storage = localStorage;
            // const authWellKnownEndpoints = new AuthWellKnownEndpoints();
            // authWellKnownEndpoints.setWellKnownEndpoints(this.oidcConfigService.wellKnownEndpoints);
            // Force HTTPS on all endpoints regardless of what the STS server returns. This is to help prevent blocked:mixed-content errors.
            // console.log(configResult);
            var r = JSON.stringify(configResult);
            r = r.replace(/http:/gi, 'https:');
            configResult = JSON.parse(r);
            // console.log(configResult);
            // this.oidcSecurityService.setupModule(openIDImplicitFlowConfiguration, authWellKnownEndpoints);
            _this.oidcSecurityService.setupModule(openIDImplicitFlowConfiguration, configResult.authWellknownEndpoints);
        });
    }
    CoreModule.forRoot = function () {
        return {
            ngModule: CoreModule,
            providers: [
                apiServices,
                httpInterceptorProviders,
                CookieService,
                OidcConfigService,
                {
                    provide: APP_INITIALIZER,
                    useFactory: loadConfig,
                    deps: [OidcConfigService],
                    multi: true
                },
            ]
        };
    };
    return CoreModule;
}());
export { CoreModule };
