import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomFormsModule } from 'ngx-custom-validators';
import { NgxPaginationModule } from 'ngx-pagination';

import { SanitizedAutoFillerDirective } from './directives/sanitized-auto-filler/sanitized-auto-filler.directive';
import { UniqueValidatorDirective } from './directives/unique/unique.directive';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { FoldingCubeComponent } from './widgets/folding-cube/folding-cube.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BooleanIndicatorComponent } from './widgets/boolean-indicator/boolean-indicator.component';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { TzPipe } from './pipes/tz.pipe';

@NgModule({
  imports: [
    CommonModule,
    NgxPaginationModule,
    TypeaheadModule.forRoot(),
    CustomFormsModule
  ],
  declarations: [
    // Widget Components
    FoldingCubeComponent,
    BooleanIndicatorComponent,

    // Pipes
    SearchFilterPipe,
    OrdinalPipe,
    TzPipe,

    // Directives
    SanitizedAutoFillerDirective,

    // Directives (Validators)
    UniqueValidatorDirective,
  ],
  exports: [
    // Modules
    NgxPaginationModule,
    CustomFormsModule,
    TypeaheadModule,

    // Widget Components
    FoldingCubeComponent,
    BooleanIndicatorComponent,

    // Pipes
    SearchFilterPipe,
    OrdinalPipe,

    // Directives
    SanitizedAutoFillerDirective,

    // Directives (Validators)
    UniqueValidatorDirective,
  ]
})
export class SharedModule { }
