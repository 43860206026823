<div class="container mb-5">
  <alert *ngIf="alert" [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.message }}</alert>
  <div *ngIf="isLoading">
    <app-folding-cube></app-folding-cube>
  </div>
  <ng-container *ngIf="!isLoading">
    <div class="row">
      <div class="col-6">
        <h1>{{ title }} Rule Set</h1>
      </div>
      <div class="col-6">
        <button type="button" (click)="cancelForm()" class="btn btn-sm btn-outline-primary float-right"><span class="fa fa-chevron-left"></span> Back</button>
      </div>
    </div>
    <form #createForm="ngForm" class="form" id="create-rule-set-form" (ngSubmit)="submitForm(createForm)">
      <div class="form-group">
        <label class="form-label" for="program-name" id="program-label">Program</label>
        <select #programName="ngModel" [(ngModel)]="payload.programKey" (change)="programChange()" required [disabled]="isUpdate" class="form-control" id="program-name" name="program-name">
          <option value="" selected>Please Select Program</option>
          <option *ngFor="let program of allPrograms" [value]="program.programKey">{{ program.name }}</option>
        </select>
      </div>
      <div class="row">
        <div class="col-2">
          <div class="form-group">
            <label class="form-label" for="type-name" id="type-label">Type of Content</label>
            <select #formType="ngModel" [(ngModel)]="payload.ruleSetType" [disabled]="isUpdate || payload.rules.length > 0" required class="form-control" id="rule-set-type" name="rule-set-type">
              <option [ngValue]="ruleSetTypes.UserGenerated">UGC</option>
              <option [ngValue]="ruleSetTypes.Receipt">Receipt</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="form-label" for="rule-set-name" id="rule-set-name-label">Name</label>
            <input
              #formName="ngModel"
              [(ngModel)]="payload.name"
              required
              minlength="3"
              maxlength="200"
              (keyup)="autoKey(64)"
              type="text"
              class="form-control"
              id="rule-set-name"
              name="rule-set-name"
              (blur)="sanitizeName()"
              [disabled]="isUpdate"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="form-label" for="rule-set-key" id="rule-set-key-label">Key</label>
            <input
              #formIdentifier="ngModel"
              [(ngModel)]="payload.ruleSetKey"
              required
              minlength="3"
              maxlength="64"
              (keyup)="setAutoFillable(formIdentifier.pristine)"
              (blur)="sanitizeKey()"
              type="text"
              class="form-control"
              id="rule-set-key"
              name="rule-set-key"
              [disabled]="isUpdate"
            />
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label" for="rule-set-key" id="rule-set-key-label">Rule Set Settings</label>
        <div *ngIf="payload.ruleSetType === ruleSetTypes.Receipt" class="row mt-3">
          <div class="col-2 text-right">
            <label class="form-label">OCR</label>
          </div>
          <div class="col-3">
              <ui-switch [(ngModel)]="payload.isAutomaticReceiptPaidFor" checkedLabel="Send to OCR" uncheckedLabel="Skip OCR" name="isAutomaticReceiptPaidFor"></ui-switch>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-2 text-right">
            <label class="form-label">Auto-Approval</label>
          </div>
          <div class="col-3">
            <ui-switch [(ngModel)]="payload.isEligibleForAutoApprove" checkedLabel="Eligible" uncheckedLabel="Not Eligible" name="isEligibleForAutoApprove"></ui-switch>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <ng-container *ngIf="payload.ruleSetType === ruleSetTypes.UserGenerated">
            <button class="btn btn-outline-info mr-2" type="button" (click)="addRuleBoolean()"><span class="fa fa-plus"></span> Add Yes/No</button>
          </ng-container>
          <ng-container *ngIf="payload.ruleSetType === ruleSetTypes.Receipt">
            <button class="btn btn-outline-info mr-2" type="button" (click)="addRuleReceiptPurchaseDate()"><span class="fa fa-plus"></span> Add Purchase Date Rule</button>
            <button class="btn btn-outline-info mr-2" type="button" (click)="addRuleReceiptStore()"><span class="fa fa-plus"></span> Add Retailer Rule</button>
            <button class="btn btn-outline-info mr-2" type="button" (click)="openAddRuleProductBased(productRuleKindSelector)"><span class="fa fa-plus"></span> Add Product Rule</button>
          </ng-container>
        </div>
      </div>
      <div class="card-columns single-column mt-4">
        <div class="card" *ngFor="let rule of payload.rules; let index = index">
          <div class="card-body">
            <h5 class="card-title mb-4">
              Rule #{{ index + 1 }} - <ng-container *ngTemplateOutlet="ruleTypeName; context: { rule: rule, index: index }"></ng-container><ng-container *ngTemplateOutlet="ruleKeyViewer; context: { rule: rule, index: index }"></ng-container
              ><span *ngIf="!rule.isUsed" class="float-right clickable danger" data-effect="fadeOut"><i class="fa fa-times" (click)="removeRule(index)"></i></span>
            </h5>
            <ng-container [ngSwitch]="rule.kind">
              <ng-container *ngSwitchCase="ruleKinds.Boolean"><ng-container *ngTemplateOutlet="ruleBoolean; context: { rule: rule, index: index }"></ng-container></ng-container>
              <ng-container *ngSwitchCase="ruleKinds.ReceiptPurchaseDate"><ng-container *ngTemplateOutlet="ruleReceiptPurchaseDate; context: { rule: rule, index: index }"></ng-container></ng-container>
              <ng-container *ngSwitchCase="ruleKinds.ReceiptStore"><ng-container *ngTemplateOutlet="ruleReceiptStore; context: { rule: rule, index: index }"></ng-container></ng-container>
              <ng-container *ngSwitchCase="ruleKinds.ReceiptItemProductQuantity"><ng-container *ngTemplateOutlet="ruleReceiptProductBased; context: { rule: rule, index: index }"></ng-container></ng-container>
              <ng-container *ngSwitchCase="ruleKinds.ReceiptItemProductTotal"><ng-container *ngTemplateOutlet="ruleReceiptProductBased; context: { rule: rule, index: index }"></ng-container></ng-container>
              <ng-container *ngSwitchCase="ruleKinds.ReceiptItemProductQuantityAndTotal"><ng-container *ngTemplateOutlet="ruleReceiptProductBased; context: { rule: rule, index: index }"></ng-container></ng-container>
              <ng-container *ngSwitchCase="ruleKinds.ReceiptItemProductMinimumQuantity"><ng-container *ngTemplateOutlet="ruleReceiptProductBased; context: { rule: rule, index: index }"></ng-container></ng-container>
              <ng-container *ngSwitchCase="ruleKinds.ReceiptItemProductMinimumTotal"><ng-container *ngTemplateOutlet="ruleReceiptProductBased; context: { rule: rule, index: index }"></ng-container></ng-container>
              <ng-container *ngSwitchCase="ruleKinds.ReceiptItemProductMinimumQuantityAndTotal"><ng-container *ngTemplateOutlet="ruleReceiptProductBased; context: { rule: rule, index: index }"></ng-container></ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <button type="submit" [disabled]="createForm.invalid || isSubmitting" class="btn btn-success">{{ submitCtaText }} <span *ngIf="isSubmitting" class="fa-spin fa-spinner fa"></span></button>
          <button type="button" class="btn btn-link" (click)="cancelForm()">Cancel</button>
        </div>
      </div>
    </form>
  </ng-container>
</div>

<ng-template #ruleKeyViewer let-rule="rule" let-index="index">
  <div *ngIf="!!rule.ruleKey" class="rule-key-wrap ml-4">
    <i class="fa" [class.fa-eye]="!rule.showKey" [class.fa-eye-slash]="rule.showKey" (click)="rule.showKey = !rule.showKey" title="Toggle Rule Key"></i> <span *ngIf="rule.showKey" class="rule-key">{{ rule.ruleKey }}</span>
  </div>
</ng-template>

<ng-template #ruleTypeName let-rule="rule" let-index="index">
  <ng-container [ngSwitch]="rule.kind">
    <ng-container *ngSwitchCase="ruleKinds.Boolean">Yes / No</ng-container>
    <ng-container *ngSwitchCase="ruleKinds.ReceiptPurchaseDate">Purchase Date</ng-container>
    <ng-container *ngSwitchCase="ruleKinds.ReceiptStore">Retailer</ng-container>
    <ng-container *ngSwitchCase="ruleKinds.ReceiptItemProductQuantity">Product - Quantity</ng-container>
    <ng-container *ngSwitchCase="ruleKinds.ReceiptItemProductTotal">Product - Price</ng-container>
    <ng-container *ngSwitchCase="ruleKinds.ReceiptItemProductQuantityAndTotal">Product - Quantity & Price</ng-container>
    <ng-container *ngSwitchCase="ruleKinds.ReceiptItemProductMinimumQuantity">Product - Minimum Quantity</ng-container>
    <ng-container *ngSwitchCase="ruleKinds.ReceiptItemProductMinimumTotal">Product - Minimum Price</ng-container>
    <ng-container *ngSwitchCase="ruleKinds.ReceiptItemProductMinimumQuantityAndTotal">Product - Minimum Quantity & Price</ng-container>
  </ng-container>
</ng-template>
<ng-template #ruleReceiptProductBased let-rule="rule" let-index="index">
  <div class="row">
    <div class="col" *ngIf="rule.kind === ruleKinds.ReceiptItemProductMinimumQuantity || rule.kind === ruleKinds.ReceiptItemProductMinimumQuantityAndTotal">
      <div class="form-group">
        <label class="form-label">Minimum Quantity Purchase</label>
        <input type="number" class="form-control" step="0.01" [(ngModel)]="rule.minimumQuantity" name="minimumQuantity" />
      </div>
    </div>
    <div class="col" *ngIf="rule.kind === ruleKinds.ReceiptItemProductMinimumTotal || rule.kind === ruleKinds.ReceiptItemProductMinimumQuantityAndTotal">
        <div class="form-group">
          <label class="form-label">Minimum Price Purchase</label>
          <input type="number" class="form-control" step="0.01" [(ngModel)]="rule.minimumTotal" name="minimumTotal" />
        </div>
      </div>
    </div>
  <div class="row">
    <div class="col">
      <h6>Product List</h6>
      <div>
        Showing:
        <ng-container *ngIf="rule.productFilter"
          ><span class="badge badge-info">{{ rule.filteredProducts.length }}</span> of
        </ng-container>
        <span class="badge badge-info">{{ rule.allProducts.length }}</span>
      </div>
      <div>
        Selected: <span class="badge badge-success">{{ rule.products.length }}</span>
      </div>
    </div>
    <div class="col">
      <div class="input-group">
        <input type="text" class="form-control novalidate" [(ngModel)]="rule.productFilter" [ngModelOptions]="{ standalone: true }" (keyup)="filterProducts(rule)" placeholder="Search" />
        <div class="input-group-append" (click)="clearProductFilter(rule)">
          <button type="button" class="btn btn-secondary">Clear</button>
        </div>
      </div>
      <label><input type="checkbox" [(ngModel)]="rule.showSelectedProductsOnly" (change)="filterProducts(rule)" name="showSelectedProductsOnly" /> Show Selected Only</label>
      <button class="btn btn-link" type="button" (click)="selectVisibleProducts(rule, true)">Select Visible</button>
      <button class="btn btn-link" type="button" (click)="selectAllProducts(rule, true)">Select All</button>
      <button class="btn btn-link" type="button" (click)="selectAllProducts(rule, false)">Clear All</button>
    </div>
  </div>
  <hr />
  <div class="card-columns">
    <div class="card borderless" *ngFor="let product of (rule.filteredProducts | paginate: { itemsPerPage: itemsPerPage, currentPage: rule.productPageNumber, id: 'productPagination' })">
      <label><input type="checkbox" [checked]="product.selected" name="product" (change)="toggleProduct(rule, product)" /> {{ product.name }}</label>
    </div>
  </div>
  <pagination-controls id="productPagination" (pageChange)="productPageChange(rule, $event)"></pagination-controls>
</ng-template>

<ng-template #ruleReceiptStore let-rule="rule" let-index="index">
  <div class="row">
    <div class="col">
      <h6>Retailer List</h6>
      <div>
        Showing:
        <ng-container *ngIf="rule.storeFilter"
          ><span class="badge badge-info">{{ rule.filteredStores.length }}</span> of
        </ng-container>
        <span class="badge badge-info">{{ rule.allStores.length }}</span>
      </div>
      <div>
        Selected: <span class="badge badge-success">{{ rule.stores.length }}</span>
      </div>
    </div>
    <div class="col">
      <div class="input-group">
        <input type="text" class="form-control novalidate" [(ngModel)]="rule.storeFilter" [ngModelOptions]="{ standalone: true }" (keyup)="filterStores(rule)" placeholder="Search" />
        <div class="input-group-append" (click)="clearStoreFilter(rule)">
          <button type="button" class="btn btn-secondary">Clear</button>
        </div>
      </div>
      <label><input type="checkbox" [(ngModel)]="rule.showSelectedStoresOnly" (change)="filterStores(rule)" name="showSelectedStoresOnly" /> Show Selected Only</label>
      <button class="btn btn-link" type="button" (click)="selectVisibleStores(rule, true)">Select Visible</button>
      <button class="btn btn-link" type="button" (click)="selectAllStores(rule, true)">Select All</button>
      <button class="btn btn-link" type="button" (click)="selectAllStores(rule, false)">Clear All</button>
    </div>
  </div>
  <hr />
  <div class="card-columns">
    <div class="card borderless" *ngFor="let store of (rule.filteredStores | paginate: { itemsPerPage: itemsPerPage, currentPage: rule.storePageNumber, id: 'storePagination' })">
      <label><input type="checkbox" [checked]="store.selected" name="store" (change)="toggleStore(rule, store)" /> {{ store.name }}</label>
    </div>
  </div>
  <pagination-controls id="storePagination" (pageChange)="storePageChange(rule, $event)"></pagination-controls>
</ng-template>

<ng-template #ruleReceiptPurchaseDate let-rule="rule" let-index="index">
  <div class="row">
    <div class="col">
      <ul class="notes">
        <li><i>Note 1:</i> The Start and End dates & times are inclusive.</li>
        <li><i>Note 2:</i> Time zones do not apply to purchase periods.</li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label class="form-label" for="purchaseOnStart">Start Date</label>
      <div class="input-group">
        <input [owlDateTime]="purchaseOnStart" #purchaseOnStartInput="ngModel" required [(ngModel)]="rule.receiptPurchasedOnStart" type="text" class="form-control" name="purchaseOnStartInput" />
        <owl-date-time #purchaseOnStart pickerMode="dialog" pickerType="both" [hour12Timer]="true"></owl-date-time>
        <div class="input-group-append" [owlDateTimeTrigger]="purchaseOnStart">
          <span class="input-group-text">
            <span class="fa fa-calendar cursor-pointer"></span>
          </span>
        </div>
      </div>
    </div>
    <div class="col">
      <label class="form-label" for="purchaseOnEnd">End Date</label>
      <div class="input-group">
        <input [owlDateTime]="purchaseOnEnd" [min]="rule.receiptPurchasedOnStart" #purchaseOnEndInput="ngModel" required [(ngModel)]="rule.receiptPurchasedOnEnd" type="text" class="form-control" name="purchaseOnEndInput" />
        <owl-date-time #purchaseOnEnd pickerMode="dialog" pickerType="both" [hour12Timer]="true"></owl-date-time>
        <div class="input-group-append" [owlDateTimeTrigger]="purchaseOnEnd">
          <span class="input-group-text">
            <span class="fa fa-calendar cursor-pointer"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ruleBoolean let-rule="rule" let-index="index">
  <div class="row">
    <div class="col-8">
      <div class="form-group">
        <label class="form-label" for="rule-text">Yes/No Question</label>
        <input [(ngModel)]="rule.text" name="ruleText_{{ index }}" required minlength="3" maxlength="200" type="text" class="form-control" />
      </div>
    </div>
    <div class="col-4">
      <div class="form-group ml-4">
        <label>Passing Value</label>
        <div>
          <div class="form-check form-check-inline" [class.passing]="rule.passingBooleanValue">
            <input class="form-check-input novalidate" type="radio" name="passingBooleanValue_{{ index }}" id="passingBooleanValue_{{ index }}_true" [value]="true" [(ngModel)]="rule.passingBooleanValue" />
            <label class="form-check-label" for="passingBooleanValue_{{ index }}_true">Yes</label>
          </div>
          <div class="form-check form-check-inline" [class.passing]="!rule.passingBooleanValue">
            <input class="form-check-input novalidate" type="radio" name="passingBooleanValue_{{ index }}" id="passingBooleanValue_{{ index }}_false" [value]="false" [(ngModel)]="rule.passingBooleanValue" />
            <label class="form-check-label" for="passingBooleanValue_{{ index }}_false">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #productRuleKindSelector>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Select Product Rule Type</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="productRuleKindSelectorModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mb-4">
      <div class="col-6">
        Minimum Purchase Required
      </div>
      <div class="col-6">
        <button (click)="addRuleProductBased(ruleKinds.ReceiptItemProductMinimumQuantity)" class="btn btn-outline-info">Quantity</button>
        <button (click)="addRuleProductBased(ruleKinds.ReceiptItemProductMinimumTotal)" class="btn btn-outline-info">Price</button>
        <button (click)="addRuleProductBased(ruleKinds.ReceiptItemProductMinimumQuantityAndTotal)" class="btn btn-outline-info">Both</button>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-6">
        No Minimum Required
      </div>
      <div class="col-6">
        <button (click)="addRuleProductBased(ruleKinds.ReceiptItemProductQuantity)" class="btn btn-outline-secondary">Quantity</button>
        <button (click)="addRuleProductBased(ruleKinds.ReceiptItemProductTotal)" class="btn btn-outline-secondary">Price</button>
        <button (click)="addRuleProductBased(ruleKinds.ReceiptItemProductQuantityAndTotal)" class="btn btn-outline-secondary">Both</button>
      </div>
    </div>
  </div>
</ng-template>
