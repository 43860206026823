var Alert = /** @class */ (function () {
    function Alert() {
    }
    Alert.success = function (message, timeout) {
        if (timeout === void 0) { timeout = 5000; }
        return Object.assign(new Alert(), {
            type: 'success',
            message: message,
            timeout: timeout
        });
    };
    Alert.warning = function (message, timeout) {
        if (timeout === void 0) { timeout = 5000; }
        return Object.assign(new Alert(), {
            type: 'warning',
            message: message,
            timeout: timeout
        });
    };
    Alert.info = function (message, timeout) {
        if (timeout === void 0) { timeout = 5000; }
        return Object.assign(new Alert(), {
            type: 'info',
            message: message,
            timeout: timeout
        });
    };
    Alert.danger = function (message, timeout) {
        if (timeout === void 0) { timeout = 5000; }
        return Object.assign(new Alert(), {
            type: 'danger',
            message: message,
            timeout: timeout
        });
    };
    return Alert;
}());
export { Alert };
