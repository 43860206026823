import { CookieService } from 'ngx-cookie-service';
import { Constants } from '../../constants';
var ProgramKeyInterceptor = /** @class */ (function () {
    function ProgramKeyInterceptor(cookieService) {
        this.cookieService = cookieService;
    }
    ProgramKeyInterceptor.prototype.intercept = function (req, next) {
        var requestToForward = req;
        var headers = {};
        if (this.cookieService.check(Constants.HeaderProgramKey)) {
            headers[Constants.HeaderProgramKey] = this.cookieService.get(Constants.HeaderProgramKey);
            console.debug("ProgramKeyInterceptor added header: " + Constants.HeaderProgramKey + " (" + headers[Constants.HeaderProgramKey] + ")");
            requestToForward = req.clone({ setHeaders: headers });
        }
        return next.handle(requestToForward);
    };
    return ProgramKeyInterceptor;
}());
export { ProgramKeyInterceptor };
