import { StoreAliasModel } from './store-alias.model';

export class StoreModel {
  storeKey: string;
  name: string;
  shouldAutoApprove: boolean;
  aliases: Array<StoreAliasModel>;

  // Part of ViewModel
  selected: boolean;
  autoApproveUpdating: boolean;
  autoApproveHadError: boolean;
}
