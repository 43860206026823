
import {zip as observableZip,  Observable } from 'rxjs';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Alert } from '../../../shared/models/alert.model';
import { Router, ActivatedRoute } from '@angular/router';
import { RuleSetApiService } from '../../../core/apis/moderation-api/ruleset-api.service';
import { ProgramApiService } from '../../../core/apis/moderation-api/program-api.service';
import { RuleSetType } from '../../../shared/models/rule-set-type.enum';
import { NgForm } from '@angular/forms';
import { ProgramModel } from '../../../shared/models/webapi/response/ProgramModel';
import { RuleKind } from '../../../shared/models/rule-kind.enum';
import { Constants } from '../../../constants';
import { KeyResult } from '../../../shared/models/key-result.model';
import { CreateEditRuleSetModel } from '../../../shared/models/webapi/request/CreateEditRuleSetModel';
import { CreateEditRuleModel } from '../../../shared/models/webapi/request/create-edit-rule.model';
import { toDate } from '../../../../i18n/date_util';
import { StoreModel } from '../../../shared/models/webapi/response/StoreModel';
import { StoreApiService } from '../../../core/apis/moderation-api/store-api.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProductModel } from '../../../shared/models/webapi/response/product.model';
import { ProductApiService } from '../../../core/apis/moderation-api/product-api.service';
import { TzPipe } from '../../../shared/pipes/tz.pipe';
import { cloneJson } from '../../../shared/utils/cloneJson';


@Component({
  selector: 'app-ruleset-wizard',
  templateUrl: './ruleset-wizard.component.html',
  styleUrls: ['./ruleset-wizard.component.scss']
})
export class RulesetWizardComponent implements OnInit {
  title: string;
  submitCtaText = 'Create';
  isUpdate = false;
  allPrograms: Array<ProgramModel>;

  itemsPerPage = 9;

  isSubmitting: boolean;
  isIdentifierAutoFillable = true;
  isLoading: boolean;
  alert: Alert;
  payload: CreateEditRuleSetModel;
  ruleSetTypes: typeof RuleSetType;
  ruleKinds: typeof RuleKind;

  masterListOfStores: Array<StoreModel>;
  masterListOfProducts: Array<ProductModel>;

  protected productRuleKindSelectorModalRef: BsModalRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ruleSetApi: RuleSetApiService,
    private bsModalService: BsModalService,
    private storeApi: StoreApiService,
    private productApi: ProductApiService,
    private tzPipe: TzPipe,
    private programApi: ProgramApiService
  ) {
    if (route.snapshot.data.isEdit) {
      this.title = 'Edit';
      this.submitCtaText = 'Update';
      this.isUpdate = true;
    } else {
      this.payload = new CreateEditRuleSetModel();
      this.payload.ruleSetType = RuleSetType.Receipt;
      this.title = 'Create';
      this.isUpdate = false;
    }

    this.ruleSetTypes = RuleSetType;
    this.ruleKinds = RuleKind;
  }

  ngOnInit() {
    this.isLoading = true;

    const lookups = observableZip(this.programApi.getPrograms(), this.storeApi.getStores(), this.productApi.getProducts());

    lookups.subscribe(datum => {
      const programs = datum[0];
      const stores = datum[1];
      const products = datum[2];

      this.allPrograms = programs.programs;
      if (this.allPrograms.length === 0) {
        this.alert = Alert.danger('No programs available for this account', 30000);
      } else if (this.allPrograms.length === 1 && this.payload != null) {
        this.payload.programKey = this.allPrograms[0].programKey;
      }

      this.masterListOfStores = stores.stores;
      this.masterListOfProducts = products.products;

      if (this.route.snapshot.data.isEdit) {
        this.loadRuleSet(this.route.snapshot.paramMap.get('programKey'), this.route.snapshot.paramMap.get('ruleSetKey'));
      } else {
        this.isLoading = false;
      }
    });
  }

  private loadRuleSet(programKey: string, ruleSetKey: string): void {
    this.ruleSetApi.getRuleSetByKey(programKey, ruleSetKey).subscribe(ruleset => {
      this.payload = ruleset;

      this.payload.rules.forEach(rule => {
        switch (rule.kind) {
          case RuleKind.ReceiptPurchaseDate:
            /* Since the purchase date is not timezone aware (it is a DateTime representing the date/time that is printed on the receipt),
                  we want to treat it as UTC.
                  Therefore, force the incoming value to be UTC by adding a Z at the end
            */
            if (rule.receiptPurchasedOnStart && !rule.receiptPurchasedOnStart.toString().endsWith('Z')) {
              rule.receiptPurchasedOnStart = rule.receiptPurchasedOnStart + 'Z';
            }
            if (rule.receiptPurchasedOnEnd && !rule.receiptPurchasedOnEnd.toString().endsWith('Z')) {
              rule.receiptPurchasedOnEnd = rule.receiptPurchasedOnEnd + 'Z';
            }

            rule.receiptPurchasedOnStart = this.toUtcMoment(rule.receiptPurchasedOnStart);
            rule.receiptPurchasedOnEnd = this.toUtcMoment(rule.receiptPurchasedOnEnd);
            break;
          case RuleKind.ReceiptStore:
            rule.allStores = cloneJson(this.masterListOfStores);
            rule.stores.forEach(store => (rule.allStores.find(all => all.storeKey === store.storeKey).selected = true));
            rule.filteredStores = [];
            rule.storeFilter = '';
            this.filterStores(rule);
            break;
          case RuleKind.ReceiptItemProductQuantity:
          case RuleKind.ReceiptItemProductQuantityAndTotal:
          case RuleKind.ReceiptItemProductTotal:
          case RuleKind.ReceiptItemProductMinimumQuantity:
          case RuleKind.ReceiptItemProductMinimumQuantityAndTotal:
          case RuleKind.ReceiptItemProductMinimumTotal:
            rule.allProducts = cloneJson(this.masterListOfProducts);
            rule.products.forEach(product => (rule.allProducts.find(all => all.productKey === product.productKey).selected = true));
            rule.filteredProducts = [];
            rule.productFilter = '';
            this.filterProducts(rule);
            break;
        }
      });

      this.isLoading = false;
    });
  }

  private toUtcMoment(value: string | number | Date) {
    const parsedDate = toDate(value);
    return this.tzPipe.transform(parsedDate, 'UTC');
  }

  cancelForm() {
    this.router.navigate(['/rulesets']);
  }

  submitForm(form: NgForm) {
    if (form.valid) {
      this.isSubmitting = true;
      this.alert = null;
      let resultKeyObservable: Observable<KeyResult> = null;
      if (this.isUpdate) {
        resultKeyObservable = this.ruleSetApi.updateRuleSet(this.payload);
      } else {
        resultKeyObservable = this.ruleSetApi.createRuleSet(this.payload);
      }
      resultKeyObservable.subscribe(
        keyResult => {
          this.isSubmitting = false;
          this.router.navigate(['/rulesets']);
        },
        errorResponse => {
          this.isSubmitting = false;
          let message = 'Error creating rule set.';

          if (errorResponse.error && errorResponse.error.errors && errorResponse.error.errors.length > 0) {
            const e = errorResponse.error.errors[0];
            switch (e.errorCode) {
              case Constants.ErrorCodes.DuplicateRuleSetNameOrKey:
                message = 'A rule set with that name or key already exists.';
                break;
              case Constants.ErrorCodes.InvalidRuleSetDefinition:
                message = e.message;
                break;
              default:
                message = e.message;
                break;
            }
          }

          this.alert = Alert.danger(message, 30000);
          window.scrollTo(0, 0);
        }
      );
    }
  }

  autoKey(maxLength: number) {
    if (this.isIdentifierAutoFillable) {
      if (!this.payload.name) {
        this.payload.ruleSetKey = '';
      }

      this.payload.ruleSetKey = this.sanitizeString(this.payload.name).substr(0, maxLength);
    }
  }

  setAutoFillable(value: boolean) {
    this.isIdentifierAutoFillable = value;
  }

  sanitizeString(input: string): string {
    if (!input) {
      return input;
    }
    return input.replace(/[^0-9a-zA-Z]/g, '-').toLocaleLowerCase();
  }

  sanitizeName() {
    this.payload.name = this.sanitizeString(this.payload.name);
  }

  sanitizeKey() {
    this.payload.ruleSetKey = this.sanitizeString(this.payload.ruleSetKey);
  }

  programChange() {
    this.sanitizeKey();
    this.sanitizeName();
  }

  //#region __ Add Rule Types __
  private addRule(kind: RuleKind): CreateEditRuleModel {
    const rule = new CreateEditRuleModel();
    rule.kind = kind;
    this.payload.rules.push(rule);
    return rule;
  }

  addRuleBoolean(): CreateEditRuleModel {
    return this.addRule(RuleKind.Boolean);
  }

  addRuleReceiptPurchaseDate(): CreateEditRuleModel {
    return this.addRule(RuleKind.ReceiptPurchaseDate);
  }

  addRuleReceiptStore(): CreateEditRuleModel {
    const rule = this.addRule(RuleKind.ReceiptStore);

    rule.allStores = cloneJson(this.masterListOfStores);
    rule.allStores.forEach(s => (s.selected = false));
    this.filterStores(rule);

    return rule;
  }

  openAddRuleProductBased(template: TemplateRef<any>): void {
    this.productRuleKindSelectorModalRef = this.bsModalService.show(template);
  }

  addRuleProductBased(kind: RuleKind): CreateEditRuleModel {
    const rule = this.addRule(kind);

    rule.allProducts = cloneJson(this.masterListOfProducts);
    rule.allProducts.forEach(p => (p.selected = false));
    this.filterProducts(rule);

    this.productRuleKindSelectorModalRef.hide();
    return rule;
  }
  //#endregion

  removeRule(index: number): void {
    this.payload.rules.splice(index, 1);
  }

  //#region Stores
  clearStoreFilter(rule: CreateEditRuleModel): void {
    rule.storeFilter = '';
    this.filterStores(rule);
  }

  filterStores(rule: CreateEditRuleModel): void {
    rule.filteredStores.splice(0, rule.filteredStores.length);
    const self = this;

    const nameContains: (store: StoreModel) => boolean = function(s: StoreModel): boolean {
      return self.containsAllWords(s.name, rule.storeFilter);
    };

    const isSelectedIfApplicable: (store: StoreModel) => boolean = function(s: StoreModel): boolean {
      return rule.showSelectedStoresOnly ? s.selected : true;
    };

    rule.allStores
      .filter(s => nameContains(s) && isSelectedIfApplicable(s))
      .forEach(s => {
        rule.filteredStores.push(s);
      });
  }

  selectVisibleStores(rule: CreateEditRuleModel, selected: boolean): void {
    rule.filteredStores.slice((rule.storePageNumber - 1) * this.itemsPerPage, rule.storePageNumber * this.itemsPerPage).forEach(s => (s.selected = selected));
    this.updateStoresInPayload(rule);
  }

  selectAllStores(rule: CreateEditRuleModel, selected: boolean): void {
    rule.allStores.forEach(s => (s.selected = selected));
    this.updateStoresInPayload(rule);
  }

  toggleStore(rule: CreateEditRuleModel, store: StoreModel): void {
    store.selected = !store.selected;
    this.updateStoresInPayload(rule);
  }

  updateStoresInPayload(rule: CreateEditRuleModel): void {
    rule.stores.splice(0, rule.stores.length);

    rule.allStores.filter(s => s.selected).forEach(s => rule.stores.push(s));
  }

  storePageChange(rule: CreateEditRuleModel, $event: any): void {
    rule.storePageNumber = $event;
  }
  //#endregion

  //#region Products
  clearProductFilter(rule: CreateEditRuleModel): void {
    rule.productFilter = '';
    this.filterProducts(rule);
  }

  filterProducts(rule: CreateEditRuleModel): void {
    rule.filteredProducts.splice(0, rule.filteredProducts.length);
    const self = this;

    const nameContains: (product: ProductModel) => boolean = function(p: ProductModel): boolean {
      return self.containsAllWords(p.name, rule.productFilter);
    };

    const isSelectedIfApplicable: (product: ProductModel) => boolean = function(s: ProductModel): boolean {
      return rule.showSelectedProductsOnly ? s.selected : true;
    };

    rule.allProducts
      .filter(s => nameContains(s) && isSelectedIfApplicable(s))
      .forEach(s => {
        rule.filteredProducts.push(s);
      });
  }

  selectVisibleProducts(rule: CreateEditRuleModel, selected: boolean): void {
    rule.filteredProducts.slice((rule.productPageNumber - 1) * this.itemsPerPage, rule.productPageNumber * this.itemsPerPage).forEach(s => (s.selected = selected));
    this.updateProductsInPayload(rule);
  }
  selectAllProducts(rule: CreateEditRuleModel, selected: boolean): void {
    rule.allProducts.forEach(s => (s.selected = selected));
    this.updateProductsInPayload(rule);
  }

  toggleProduct(rule: CreateEditRuleModel, product: ProductModel): void {
    product.selected = !product.selected;
    this.updateProductsInPayload(rule);
  }

  updateProductsInPayload(rule: CreateEditRuleModel): void {
    rule.products.splice(0, rule.products.length);

    rule.allProducts.filter(s => s.selected).forEach(s => rule.products.push(s));
  }

  productPageChange(rule: CreateEditRuleModel, $event: any): void {
    rule.productPageNumber = $event;
  }
  //#endregion

  private containsAllWords(text: string, wordsAsString: string): boolean {
    const words: Array<string> = wordsAsString.split(' ');

    for (let i = 0; i < words.length; i++) {
      const matchesWord = text.toLowerCase().indexOf(words[i].toLowerCase()) > -1;
      if (!matchesWord) {
        return false;
      }
    }

    return true;
  }
}
