import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { Alert } from '../../../shared/models/alert.model';
import { ActivatedRoute, CanActivateChild, Router } from '@angular/router';
import { ProgramApiService } from '../../../core/apis/moderation-api/program-api.service';
import { StoreApiService } from '../../../core/apis/moderation-api/store-api.service';
import { DatePipe } from '@angular/common';
import { StoreModel } from '../../../shared/models/webapi/response/StoreModel';
import { StoreResultsModel } from '../../../shared/models/webapi/response/StoreResultsModel';
import { ProgramModel } from '../../../shared/models/webapi/response/ProgramModel';
import { ProgramResultsModel } from '../../../shared/models/webapi/response/ProgramResultsModel';
import { Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { CreateEditStoreModel } from '../../../shared/models/webapi/request/CreateEditStoreModel';
import { KeyResult } from '../../../shared/models/key-result.model';
import { Constants } from '../../../constants';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoresComponent implements OnInit, AfterViewInit {
  public alert: Alert;
  public isLoadingPrograms: boolean;

  // program related
  public selectedProgram: ProgramModel;
  public programs: Array<ProgramModel>;

  // store related
  public programStores: StoreResultsModel = new StoreResultsModel();
  public hasStores = false;
  public selectedStores: Array<StoreModel> = [];

  // filtering related
  public searchTerm = '';
  public filteredStores: Array<StoreModel> = [];

  // page directives
  public isProcessing = true;
  public allSelected = false;

  // create
  public createModalRef: BsModalRef;
  public newRetailerText = '';

  // edit
  public editModalRef: BsModalRef;
  public editStore: StoreModel;

  // delete
  public deleteModalRef: BsModalRef;

  constructor(
    private route: ActivatedRoute,
    private programApi: ProgramApiService,
    private storeApi: StoreApiService,
    private router: Router,
    private datePipe: DatePipe,
    private bsModalService: BsModalService,
    private cookieService: CookieService
  ) {
    this.programStores.stores = new Array<StoreModel>();
  }

  ngOnInit() {
    this.isLoadingPrograms = true;
    this.programApi.getPrograms().subscribe((model: ProgramResultsModel) => {
      this.programs = model.programs;
      this.isLoadingPrograms = false;
      if (this.programs.length === 0) {
        this.alert = Alert.danger('No programs available for this account', 30000);
      } else if (this.programs.length === 1) {
        this.selectedProgram = this.programs[0];
        this.onSelectProgram();
      } else if (this.cookieService.get(Constants.HeaderProgramKey)) {
        const programKey = this.cookieService.get(Constants.HeaderProgramKey);
        this.selectedProgram = this.programs.filter(p => p.programKey === programKey)[0];
        this.onSelectProgram();
      }
    });
  }

  onOpenCreate(template: TemplateRef<any>) {
    this.createModalRef = this.bsModalService.show(template);
  }

  onOpenEdit(template: TemplateRef<any>) {
    // open the edit modal
    this.editStore = { ...this.selectedStores[0] };
    this.editModalRef = this.bsModalService.show(template);
  }

  onOpenDelete(template: TemplateRef<any>) {
    this.deleteModalRef = this.bsModalService.show(template);
  }

  onDeleteConfirmed() {
    this.deleteModalRef.hide();
    this.selectedStores.forEach(store => {
      this.storeApi.deleteStore(store, this.selectedProgram.programKey).subscribe(() => {
        this.selectedStores.splice(this.selectedStores.indexOf(store), 1);
        this.programStores.stores.splice(this.programStores.stores.indexOf(store), 1);
        this.filterStores();
      });
    }, this);
  }

  onEditStore() {
    // update the model
    this.editStore.name = this.editStore.name.trim();
    this.editModalRef.hide();
    if (this.editStore.name === '') {
      return;
    }
    this.isProcessing = true;
    this.storeApi.updateStore(this.editStore, this.selectedProgram.programKey).subscribe((storeKey: KeyResult) => {
      this.isProcessing = false;
      if (this.editStore.storeKey === storeKey.key) {
        const existingStore = this.programStores.stores.find(x => x.storeKey === storeKey.key);
        existingStore.name = this.editStore.name;
        this.filterStores(); // update sorting
        return;
      }
      const removedStore = this.programStores.stores.find(x => x.storeKey === this.editStore.storeKey);
      this.programStores.stores.splice(this.programStores.stores.indexOf(removedStore), 1);
      this.selectedStores = new Array<StoreModel>();
      if (!this.programStores.stores.find(x => x.storeKey === storeKey.key)) {
        this.editStore.storeKey = storeKey.key;
        this.programStores.stores.push(this.editStore);
      } else {
        const match = this.programStores.stores.find(x => x.storeKey === storeKey.key);
        match.selected = true;
        this.selectedStores.push(match);
      }
      this.filterStores();
    });
  }

  onSelectProgram() {
    if (this.selectedProgram) {
      this.cookieService.set(Constants.HeaderProgramKey, this.selectedProgram.programKey);
      this.isProcessing = true;
      this.storeApi.getStoresForProgram(this.selectedProgram.programKey).subscribe((storeResults: StoreResultsModel) => {
        if (storeResults) {
          this.programStores = storeResults;
          this.hasStores = this.programStores.stores.length > 0;
          this.filteredStores = this.programStores.stores;
          this.filterStores(); // sort
          this.isProcessing = false;
        } else {
          // TODO: Send them somewhere important
          this.router.navigateByUrl('/unauthorized');
        }
      });
    } else {
      this.cookieService.delete(Constants.HeaderProgramKey);
      this.clearResults();
    }
  }

  onSelectStore(store: StoreModel) {
    if (store.selected) {
      this.selectedStores.push(store);
      if (this.selectedStores.length === this.programStores.stores.length) {
        this.allSelected = true;
      }
    } else {
      this.selectedStores.splice(this.selectedStores.indexOf(store), 1);
      if (this.selectedStores.length === 0) {
        this.allSelected = false;
      }
    }
  }

  onSelectAllStores() {
    this.programStores.stores.filter(x => x.selected !== this.allSelected).forEach(store => {
      store.selected = this.allSelected;
      this.onSelectStore(store);
    }, this);
  }

  ngAfterViewInit() {}

  clearResults() {
    this.programStores = new StoreResultsModel();
    this.programStores.stores = new Array<StoreModel>();
    this.selectedStores = new Array<StoreModel>();
    this.isProcessing = false;
    this.searchTerm = '';
    this.filteredStores = new Array<StoreModel>();
  }

  filterStores() {
    if (this.searchTerm.trim() === '') {
      this.filteredStores = this.programStores.stores.sort(this.storeSort);
      return;
    }
    this.filteredStores = this.programStores.stores
      .filter(x => x.name.toLocaleLowerCase().indexOf(this.searchTerm.trim().toLocaleLowerCase()) > -1)
      .sort(this.storeSort);
  }

  storeSort = function(a: StoreModel, b: StoreModel) {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
  };

  clearTerm() {
    this.searchTerm = '';
    this.filterStores();
  }

  onCreateStores() {
    this.newRetailerText = this.newRetailerText.trim();

    const newRetailerTexts: Array<string> = [];
    this.newRetailerText.split('\n').forEach(text => {
      text = text.trim();
      if (text === '') {
        return;
      }
      if (newRetailerTexts.find(x => x.toLowerCase() === text.toLowerCase())) {
        return;
      }
      newRetailerTexts.push(text);
    }, this);

    this.createModalRef.hide();
    if (newRetailerTexts.length === 0) {
      return;
    }
    this.isProcessing = true; // Display the loader gif

    newRetailerTexts.forEach(text => {
      const existingStore: StoreModel = this.programStores.stores.find(x => x.name.toLocaleLowerCase() === text.toLocaleLowerCase());
      if (existingStore) {
        // the store name already exists, but possibly cased differently
        if (text === existingStore.name) {
          return;
        } // the names are exactly the same
        existingStore.name = text; // the name is only cased differently, and will not be updated in data
        return;
      }

      const createStore: CreateEditStoreModel = new CreateEditStoreModel();
      createStore.programKey = this.selectedProgram.programKey;
      createStore.name = text;

      this.storeApi.createStore(createStore, this.selectedProgram.programKey).subscribe((keyResult: KeyResult) => {
        const newStore: StoreModel = new StoreModel();
        newStore.name = text;
        newStore.selected = false;
        newStore.storeKey = keyResult.key;
        this.programStores.stores.push(newStore);
        this.filterStores();
      });
    }, this);

    this.isProcessing = false; // Hide the loader gif,   would be nice to track the threads and turn this off after all threads complete.
  }

  public toggleShouldAutoApprove(store: StoreModel): void {
    store.autoApproveUpdating = true;
    store.autoApproveHadError = false;
    this.storeApi.setStoreAutoApprove(this.selectedProgram.programKey, store.storeKey, !store.shouldAutoApprove).subscribe(_ => {
      store.autoApproveUpdating = false;
    }, (_: any) => {
      store.autoApproveUpdating = false;
      store.autoApproveHadError = true;
      store.shouldAutoApprove = !store.shouldAutoApprove;
    });
  }
}
