<div class="container">
  <div class="row">
    <div class="col-10">
      <h1>Retailers</h1>
    </div>
  </div>
  <div class="row" *ngIf="!isLoadingPrograms">
    <div class="col-10">
      <div class="form-group">
        <label class="form-label" for="program-name" id="program-label">Program</label>
        <select #programName="ngModel" [(ngModel)]="selectedProgram" (change)="onSelectProgram()" required class="form-control" id="program-name" name="program-name">
          <option [ngValue]="undefined">Please Select...</option>
          <option *ngFor="let program of programs" [ngValue]="program">{{program.name}}</option>
        </select>
      </div>
    </div>
  </div>
  <div *ngIf="selectedProgram && !isProcessing">
    <div class="pull-left">
      <div class="input-group">
        <input type="text" class="form-control" id="txtSearchTerm" [(ngModel)]="searchTerm" (keyup)="filterStores()" placeholder="Search Retailers">
        <div class="input-group-append cursor-pointer" (click)="clearTerm()">
          <span class="input-group-text">
            <span class="fa fa-times cursor-pointer"></span>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="isProcessing" class="center-stage">
      <app-folding-cube></app-folding-cube>
    </div>
    <div *ngIf="!isProcessing && !hasStores">
      <h5>No retailers created. <a (click)="onOpenCreate(createTemplate)">>Create one</a> now.</h5>
    </div>

    <div class="pull-right">
      <input id="chkSelectAll" type="checkbox" (change)="onSelectAllStores()" [(ngModel)]="allSelected"><label for="chkSelectAll">Select All</label>
      <a class="btn btn-outline-info ml-2 text-info" (click)="onOpenCreate(createTemplate)"><span class="fa fa-plus"></span> Add Retailer</a>
    </div>
    <br />
    <hr />
    <div class="pull-right">
      <input *ngIf="selectedStores.length > 0" (click)="onOpenDelete(deleteConfirmation)" type="button" value="Delete" class="btn btn-danger">
      <input *ngIf="selectedStores.length === 1" (click)="onOpenEdit(editTemplate)" type="button" value="Edit" class="btn">
    </div>
    <br />
    <hr />
    <div class="row">
      <div class="card-columns">
        <div class="card" *ngFor="let store of filteredStores; let i=index">
          <div class="card-body">
            <h5 class="card-title"><label for="check{{i}}"><input id="check{{i}}" type="checkbox" name="selectedStores" (change)="onSelectStore(store,$event)" value="{{store.storeKey}}" [(ngModel)]="store.selected"> {{store.name}}</label></h5>
            <p class="card-text">
              <label>Auto-Approve</label>
              <ui-switch [(ngModel)]="store.shouldAutoApprove" (change)="toggleShouldAutoApprove(store)" checkedLabel="Enabled" uncheckedLabel="Disabled" name="shouldAutoApprove"></ui-switch>
              <span *ngIf="store.autoApproveUpdating" class="fa-spin fa-spinner fa"></span>
              <span *ngIf="store.autoApproveHadError" class="fa fa-times error" title="Error updating auto-approve flag."></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <br />
    <hr />
    <div class="pull-right">
      <input *ngIf="selectedStores.length > 0" (click)="onOpenDelete(deleteConfirmation)" type="button" value="Delete" class="btn btn-danger">
      <input *ngIf="selectedStores.length === 1" (click)="onOpenEdit(editTemplate)" type="button" value="Edit" class="btn">
    </div>
    <br />
    <hr />

  </div>
</div>

<ng-template #createTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Create Retailers</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="createModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Names (1 per line): </label>
      <textarea class="form-group" [(ngModel)]="newRetailerText" rows="10" cols="50"> </textarea>
    </div>
    <button (click)="onCreateStores()" class="btn btn-success">Add</button>
  </div>
</ng-template>

<ng-template #editTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Edit Retailer</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="editModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Name: </label>
      <input type="text" class="form-control" [(ngModel)]="editStore.name">
    </div>
    <button (click)="onEditStore()" class="btn btn-success">Edit</button>
  </div>
</ng-template>

<ng-template #deleteConfirmation>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Remove Retailer(s)</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="deleteModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span>Are you sure you want to remove {{ selectedStores.length.toString() }} store(s) from {{ selectedProgram.name }}?</span>
    <input type="button" (click)="onDeleteConfirmed()" class="btn btn-danger" value="YES">
    <input type="button" (click)="deleteModalRef.hide()" class="btn btn-success" value="NO">
  </div>
</ng-template>
