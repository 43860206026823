import { Component, OnInit } from '@angular/core';
import { OidcSecurityService, AuthorizationResult, AuthorizationState } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signin-oidc',
  templateUrl: './signin-oidc.component.html',
  styleUrls: ['./signin-oidc.component.scss']
})
export class SigninOidcComponent implements OnInit {
  constructor(private oidc: OidcSecurityService, private router: Router) {
    if (this.oidc.moduleSetup) {
      this.doCallbackLogicIfRequired();
    } else {
      this.oidc.onModuleSetup.subscribe(() => {
        this.doCallbackLogicIfRequired();
      });
    }

    this.oidc.onAuthorizationResult.subscribe((result: AuthorizationResult) => {
      // console.log(result);
      if (result.authorizationState === AuthorizationState.authorized) {
        // TODO: Take them to where they were attempting to go
        this.router.navigateByUrl('/home');
      } else if (result.authorizationState === AuthorizationState.unauthorized) {
        this.router.navigateByUrl('/unauthorized');
      }
    });
  }

  doCallbackLogicIfRequired(): void {
    if (window.location.hash) {
      this.oidc.authorizedImplicitFlowCallback();
    }
  }

  ngOnInit() {}
}
