import { RuleSetsComponent } from './pages/rulesets/rulesets.component';
import { Routes } from '@angular/router';
import { RuleSetComponent } from './ruleset.component';
import { RulesetWizardComponent } from './pages/ruleset-wizard/ruleset-wizard.component';
var ɵ0 = { isEdit: true }, ɵ1 = { isEdit: true };
var ruleSetRoutes = [
    {
        path: 'rulesets',
        component: RuleSetComponent,
        children: [
            // Retain old paths for compatibility, but use new component
            { path: '', component: RuleSetsComponent },
            { path: 'create', component: RulesetWizardComponent },
            { path: 'create/wizard', component: RulesetWizardComponent },
            { path: ':programKey/:ruleSetKey/edit', component: RulesetWizardComponent, data: ɵ0 },
            { path: ':programKey/:ruleSetKey/wizard', component: RulesetWizardComponent, data: ɵ1 },
        ]
    }
];
var RuleSetRoutingModule = /** @class */ (function () {
    function RuleSetRoutingModule() {
    }
    return RuleSetRoutingModule;
}());
export { RuleSetRoutingModule };
export { ɵ0, ɵ1 };
