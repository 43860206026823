import { Component, OnInit, OnDestroy } from '@angular/core';
import { OidcSecurityService, AuthorizationResult, AuthorizationState } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Constants } from '../../../constants';
import { PrizelogicTokenPayloadModel } from '../../../shared/models/prizelogic-token-payload-model';
import { CrossAccountService, AccountChangedModel } from '../../pages/cross-account/cross-account.service';
import { Subscription } from 'rxjs';
import { VersionModel } from '../../../shared/models/webapi/response/version.model';
import { ModerationApiService } from '../../apis/moderation-api/moderation-api.service';
import { ApplicationVersion } from '../../../../version';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit, OnDestroy {
  isAuthorized: boolean;
  userData: any;
  accountName: string;
  showAccountSelector = false;
  isAccountSelectorCollapsed = true;
  isGlobalsSelectorCollapsed = true;
  private accountChangedSubscription: Subscription;
  public version: string;
  public apiVersion = '...'; // retrieved later
  public environmentIndicator = 'prd';

  constructor(
    private oidc: OidcSecurityService,
    private router: Router,
    private cookieService: CookieService,
    private crossAccountService: CrossAccountService,
    private moderationApi: ModerationApiService
  ) {
    this.accountName = cookieService.get(Constants.CookieAdminAccountName);
    this.version = ApplicationVersion.version;

    this.oidc.onAuthorizationResult.subscribe((result: AuthorizationResult) => {
      this.isAuthorized = result.authorizationState === AuthorizationState.authorized;
    });
    this.environmentIndicator = this.getEnvironmentIndicator();
  }

  private getEnvironmentIndicator(): string {
    // HACK: Using an existing piece of data that is already in the environment files so they do not have to be updated when upgrading to this version
    const url = environment.oidc.redirectUrl;

    if (url.match(/localhost/i)) {
      return 'loc';
    } else if (url.match(/\.dev\./i)) {
      return 'dev';
    } else if (url.match(/\.tst\./i)) {
      return 'tst';
    } else if (url.match(/\.staging\./i)) {
      return 'stg';
    }

    return 'prd';
  }

  urlBase64Decode(str: string) {
    if (!str) {
      return str;
    }
    let output = str.replace('-', '+').replace('_', '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw Error('Illegal base64url string!');
    }

    return window.atob(output);
  }

  ngOnInit() {
    this.oidc.getIsAuthorized().subscribe((isAuthorized: boolean) => {
      this.isAuthorized = isAuthorized;

      this.moderationApi.getVersion().subscribe((data: VersionModel) => {
        this.apiVersion = data.version;
      });

      if (this.isAuthorized) {
        const token: string = this.oidc.getToken();
        // const payload: PrizelogicTokenPayloadModel = JSON.parse(this.urlBase64Decode(token.split('.')[1]));
        const isCrossAccount = true;
        // if (typeof payload.cross_account === 'string') {
        //   isCrossAccount = (<string>payload.cross_account).match(/true/i).length > 0;
        // } else if (typeof payload.cross_account === 'boolean') {
        //   isCrossAccount = <boolean>payload.cross_account;
        // }

        this.showAccountSelector = isCrossAccount;
      }
    });

    this.oidc.getUserData().subscribe((userData: any) => {
      this.userData = userData;
    });

    this.accountChangedSubscription = this.crossAccountService.accountChanged.subscribe((account: AccountChangedModel) => {
      if (account) {
        this.accountName = account.accountName;
      } else {
        this.accountName = '';
      }
    });
  }

  ngOnDestroy() {
    if (this.accountChangedSubscription) {
      this.accountChangedSubscription.unsubscribe();
    }
  }

  logon() {
    this.router.navigateByUrl('/logon');
  }

  logoff() {
    this.oidc.logoff();
  }

  switchAccount() {
    this.isAccountSelectorCollapsed = true;

    this.accountName = '';
    this.cookieService.delete(Constants.HeaderAdminAccountKey);
    this.cookieService.delete(Constants.CookieAdminAccountName);

    this.router.navigate(['/.cross-account'], { queryParams: { returnUrl: '/' } });
  }
}
