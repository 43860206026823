import { OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
var LogonComponent = /** @class */ (function () {
    function LogonComponent(oidc) {
        this.oidc = oidc;
    }
    LogonComponent.prototype.ngOnInit = function () {
        this.oidc.authorize();
    };
    return LogonComponent;
}());
export { LogonComponent };
