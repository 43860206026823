import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

export class BaseApi {
  constructor(private urlRoot: string, protected http: HttpClient) {
    this.urlRoot = urlRoot;
    while (this.urlRoot.endsWith('/')) {
      this.urlRoot = this.urlRoot.substr(0, this.urlRoot.length - 1);
    }
  }

  protected urlFor(path: string): string {
    return `${this.urlRoot}${path}`;
  }
  protected getFor<T>(path: string): Observable<T> {
    return this.http.get<T>(this.urlFor(path));
  }

  protected getWithResponseFor<T>(path: string): Observable<HttpResponse<T>> {
    return this.http.get<T>(this.urlFor(path), { observe: 'response' });
  }

  protected getWithParamsFor<T>(
    path: string,
    params: HttpParams | { [param: string]: string | string[]; })
    : Observable<T> {
    return this.http.get<T>(this.urlFor(path), { params: params });
  }

  protected getWithParamsAndResponseFor<T>(
    path: string,
    params: HttpParams | { [param: string]: string | string[]; })
    : Observable<HttpResponse<T>> {
      return this.http.get<T>(this.urlFor(path), { observe: 'response', params: params });
    }

  protected postFor<TRequest, TResponse>(path: string, request: TRequest): Observable<TResponse> {
    return this.http.post<TResponse>(this.urlFor(path), request);
  }

  protected postWithParamsFor<TRequest, TResponse>(
    path: string,
    request: TRequest,
    params: HttpParams | { [param: string]: string | string[]; }
  ): Observable<TResponse> {
    return this.http.post<TResponse>(this.urlFor(path), request, { params: params });
  }

  protected putFor<TRequest, TResponse>(path: string, request: TRequest): Observable<TResponse> {
    return this.http.put<TResponse>(this.urlFor(path), request);
  }

  protected deleteFor(path: string): Observable<any> {
    return this.http.delete(this.urlFor(path));
  }

  protected patchFor(path: string, patch: any): Observable<any> {
    return this.http.patch(this.urlFor(path), patch);
  }

}
