/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./global-stores.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/widgets/folding-cube/folding-cube.component.ngfactory";
import * as i3 from "../../../shared/widgets/folding-cube/folding-cube.component";
import * as i4 from "../../../../../node_modules/ngx-bootstrap/alert/ngx-bootstrap-alert.ngfactory";
import * as i5 from "ngx-bootstrap/alert";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "./global-stores.component";
import * as i9 from "../../../core/apis/moderation-api/store-api.service";
import * as i10 from "ngx-bootstrap/modal";
var styles_GlobalStoresComponent = [i0.styles];
var RenderType_GlobalStoresComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GlobalStoresComponent, data: {} });
export { RenderType_GlobalStoresComponent as RenderType_GlobalStoresComponent };
function View_GlobalStoresComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "center-stage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-folding-cube", [], null, null, null, i2.View_FoldingCubeComponent_0, i2.RenderType_FoldingCubeComponent)), i1.ɵdid(2, 114688, null, 0, i3.FoldingCubeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_GlobalStoresComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "alert", [], null, null, null, i4.View_AlertComponent_0, i4.RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i5.AlertComponent, [i5.AlertConfig, i1.ChangeDetectorRef], { type: [0, "type"], dismissOnTimeout: [1, "dismissOnTimeout"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alert.type; var currVal_1 = _co.alert.timeout; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.alert.message; _ck(_v, 2, 0, currVal_2); }); }
function View_GlobalStoresComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDeleteAlias(_v.parent.context.$implicit, _v.context.$implicit, i1.ɵnov(_v.parent.parent.parent, 15)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_GlobalStoresComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "ul", [["class", "aliases"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "li", [["class", "mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-plus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCreateAliases(_v.context.$implicit, i1.ɵnov(_v.parent.parent, 14)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalStoresComponent_5)), i1.ɵdid(8, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.aliases; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_GlobalStoresComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 30, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalStoresComponent_3)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 15, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 14, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "input", [["class", "form-control"], ["id", "txtSearchTerm"], ["placeholder", "Search Retailers"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keyup"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.searchTerm = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup" === en)) {
        var pd_5 = (_co.filterStores() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(10, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(12, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "input-group-append cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearTerm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "input-group-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "span", [["class", "fa fa-times cursor-pointer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "a", [["class", "btn btn-outline-info ml-2 text-info pull-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCreateStores(i1.ɵnov(_v.parent, 13)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "span", [["class", "fa fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add Retailer"])), (_l()(), i1.ɵeld(19, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 10, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 9, "table", [["class", "table table-sm mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 5, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(26, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Aliases"])), (_l()(), i1.ɵeld(28, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalStoresComponent_4)), i1.ɵdid(30, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alert; _ck(_v, 2, 0, currVal_0); var currVal_8 = _co.searchTerm; _ck(_v, 10, 0, currVal_8); var currVal_9 = _co.filteredStores; _ck(_v, 30, 0, currVal_9); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 12).ngClassValid; var currVal_6 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_GlobalStoresComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create Retailers"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 12, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Names (1 per line): "])), (_l()(), i1.ɵeld(10, 0, null, null, 6, "textarea", [["class", "form-group"], ["cols", "50"], ["rows", "10"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 11)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 11)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.newRetailerText = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(13, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(15, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, [" "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createStores() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.newRetailerText; _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 15).ngClassValid; var currVal_5 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 10, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_GlobalStoresComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Create Aliases for ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createAliasModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 12, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Names (1 per line): "])), (_l()(), i1.ɵeld(10, 0, null, null, 6, "textarea", [["class", "form-group"], ["cols", "50"], ["rows", "10"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 11)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 11)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.newAliasText = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(13, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(15, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, [" "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createAliases() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.newAliasText; _ck(_v, 13, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedStoreForAlias.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 15).ngClassValid; var currVal_6 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_GlobalStoresComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Remove Alias"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteAliasModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["Are you sure you want to remove ", " from ", "?"])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteAlias() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteAliasModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedAlias.name; var currVal_1 = _co.selectedStoreForAlias.name; _ck(_v, 8, 0, currVal_0, currVal_1); }); }
export function View_GlobalStoresComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "col-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Global Retailers"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Changes here will affect all Programs."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Retailer names and Aliases are not case-sensitive."])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalStoresComponent_1)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalStoresComponent_2)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["createTemplate", 2]], null, 0, null, View_GlobalStoresComponent_6)), (_l()(), i1.ɵand(0, [["createAliasTemplate", 2]], null, 0, null, View_GlobalStoresComponent_7)), (_l()(), i1.ɵand(0, [["deleteAliasConfirmation", 2]], null, 0, null, View_GlobalStoresComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isProcessing; _ck(_v, 10, 0, currVal_0); var currVal_1 = !_co.isProcessing; _ck(_v, 12, 0, currVal_1); }, null); }
export function View_GlobalStoresComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-global-stores", [], null, null, null, View_GlobalStoresComponent_0, RenderType_GlobalStoresComponent)), i1.ɵdid(1, 114688, null, 0, i8.GlobalStoresComponent, [i9.StoreApiService, i10.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GlobalStoresComponentNgFactory = i1.ɵccf("app-global-stores", i8.GlobalStoresComponent, View_GlobalStoresComponent_Host_0, {}, {}, []);
export { GlobalStoresComponentNgFactory as GlobalStoresComponentNgFactory };
