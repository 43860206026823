import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { Constants } from '../../constants';

@Injectable()
export class ProgramKeyInterceptor implements HttpInterceptor {

  constructor(
    private cookieService: CookieService
  ) {  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;

    const headers = {};
    if (this.cookieService.check(Constants.HeaderProgramKey)) {
      headers[Constants.HeaderProgramKey] = this.cookieService.get(Constants.HeaderProgramKey);
      console.debug(`ProgramKeyInterceptor added header: ${Constants.HeaderProgramKey} (${headers[Constants.HeaderProgramKey]})`);
      requestToForward = req.clone({ setHeaders: headers });
    }

    return next.handle(requestToForward);
  }
}
