import { CrossAccountResolver } from './pages/cross-account/cross-account-resolver.service';
import { LogonComponent } from './pages/logon/logon.component';
import { CrossAccountComponent } from './pages/cross-account/cross-account.component';
import { SessionExpiredComponent } from './pages/session-expired/session-expired.component';
import { Routes, Router, Route, CanActivate } from '@angular/router';
import { Type } from '@angular/core';
import { AuthGuard } from './services/auth-guard.service';
import { CrossAccountGuard } from './services/cross-account-guard.service';
import { SigninOidcComponent } from './pages/signin-oidc/signin-oidc.component';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { isArray } from 'util';
var ɵ0 = { skipCanActivateGuards: [CrossAccountGuard] }, ɵ1 = { skipCanActivateGuards: [AuthGuard, CrossAccountGuard] }, ɵ2 = { skipCanActivateGuards: [AuthGuard, CrossAccountGuard] }, ɵ3 = { skipCanActivateGuards: [AuthGuard, CrossAccountGuard] }, ɵ4 = { skipCanActivateGuards: [AuthGuard, CrossAccountGuard] }, ɵ5 = { skipCanActivateGuards: [AuthGuard, CrossAccountGuard] }, ɵ6 = { skipCanActivateGuards: [AuthGuard, CrossAccountGuard] };
// Global routes
var appRoutes = [
    // Children modules
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'rulesets', loadChildren: 'app/ruleset/ruleset.module#RuleSetModule' },
    { path: 'products', loadChildren: 'app/product/product.module#ProductModule' },
    { path: 'boarding', loadChildren: 'app/boarding/boarding.module#BoardingModule' },
    { path: 'actions', loadChildren: 'app/quick-actions/quick-actions.module#QuickActionsModule' },
    { path: 'stores', loadChildren: 'app/store/store.module#StoreModule' },
    { path: 'categories', loadChildren: 'app/category/category.module#CategoryModule' },
    {
        path: '.cross-account',
        component: CrossAccountComponent,
        resolve: { crossAccount: CrossAccountResolver },
        data: ɵ0
    },
    // Non-authorized pages
    { path: 'home', component: HomeComponent, data: ɵ1 },
    { path: 'logon', component: LogonComponent, data: ɵ2 },
    { path: 'signin-oidc', component: SigninOidcComponent, data: ɵ3 },
    { path: 'unauthorized', component: UnauthorizedComponent, data: ɵ4 },
    { path: 'session-expired', component: SessionExpiredComponent, data: ɵ5 },
    { path: '**', component: PageNotFoundComponent, data: ɵ6 }
];
// Global CanActivate Guards
var canActivateGuards = [
    CrossAccountGuard,
    AuthGuard,
];
var CoreRoutingModule = /** @class */ (function () {
    function CoreRoutingModule(router) {
        var _this = this;
        // Add the AuthGuard to all paths except those that explicitly skip
        router.config.forEach(function (route) {
            for (var _i = 0, canActivateGuards_1 = canActivateGuards; _i < canActivateGuards_1.length; _i++) {
                var canActivateGuard = canActivateGuards_1[_i];
                if (route.data && route.data.skipCanActivateGuards && isArray(route.data.skipCanActivateGuards)) {
                    if (route.data.skipCanActivateGuards.indexOf(canActivateGuard) > -1) {
                        continue;
                    }
                }
                _this.addCanActivateGuard(route, canActivateGuard);
            }
        });
    }
    /**
     * Adds a CanActivate guard to the specified route.
     * @param route The route to add the guard to.
     * @param guardType The Type of the guard to add to the array of CanActivate guards
     */
    CoreRoutingModule.prototype.addCanActivateGuard = function (route, guardType) {
        if (guardType != null) {
            route.canActivate = route.canActivate === undefined ? [guardType] : [guardType].concat(route.canActivate);
            route.canActivateChild = route.canActivateChild === undefined ? [guardType] : [guardType].concat(route.canActivateChild);
        }
    };
    return CoreRoutingModule;
}());
export { CoreRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
