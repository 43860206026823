import { zip as observableZip } from 'rxjs';
import { OnInit, TemplateRef } from '@angular/core';
import { Alert } from '../../../shared/models/alert.model';
import { Router, ActivatedRoute } from '@angular/router';
import { RuleSetApiService } from '../../../core/apis/moderation-api/ruleset-api.service';
import { ProgramApiService } from '../../../core/apis/moderation-api/program-api.service';
import { RuleSetType } from '../../../shared/models/rule-set-type.enum';
import { RuleKind } from '../../../shared/models/rule-kind.enum';
import { Constants } from '../../../constants';
import { CreateEditRuleSetModel } from '../../../shared/models/webapi/request/CreateEditRuleSetModel';
import { CreateEditRuleModel } from '../../../shared/models/webapi/request/create-edit-rule.model';
import { toDate } from '../../../../i18n/date_util';
import { StoreApiService } from '../../../core/apis/moderation-api/store-api.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProductApiService } from '../../../core/apis/moderation-api/product-api.service';
import { TzPipe } from '../../../shared/pipes/tz.pipe';
import { cloneJson } from '../../../shared/utils/cloneJson';
var RulesetWizardComponent = /** @class */ (function () {
    function RulesetWizardComponent(router, route, ruleSetApi, bsModalService, storeApi, productApi, tzPipe, programApi) {
        this.router = router;
        this.route = route;
        this.ruleSetApi = ruleSetApi;
        this.bsModalService = bsModalService;
        this.storeApi = storeApi;
        this.productApi = productApi;
        this.tzPipe = tzPipe;
        this.programApi = programApi;
        this.submitCtaText = 'Create';
        this.isUpdate = false;
        this.itemsPerPage = 9;
        this.isIdentifierAutoFillable = true;
        if (route.snapshot.data.isEdit) {
            this.title = 'Edit';
            this.submitCtaText = 'Update';
            this.isUpdate = true;
        }
        else {
            this.payload = new CreateEditRuleSetModel();
            this.payload.ruleSetType = RuleSetType.Receipt;
            this.title = 'Create';
            this.isUpdate = false;
        }
        this.ruleSetTypes = RuleSetType;
        this.ruleKinds = RuleKind;
    }
    RulesetWizardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = true;
        var lookups = observableZip(this.programApi.getPrograms(), this.storeApi.getStores(), this.productApi.getProducts());
        lookups.subscribe(function (datum) {
            var programs = datum[0];
            var stores = datum[1];
            var products = datum[2];
            _this.allPrograms = programs.programs;
            if (_this.allPrograms.length === 0) {
                _this.alert = Alert.danger('No programs available for this account', 30000);
            }
            else if (_this.allPrograms.length === 1 && _this.payload != null) {
                _this.payload.programKey = _this.allPrograms[0].programKey;
            }
            _this.masterListOfStores = stores.stores;
            _this.masterListOfProducts = products.products;
            if (_this.route.snapshot.data.isEdit) {
                _this.loadRuleSet(_this.route.snapshot.paramMap.get('programKey'), _this.route.snapshot.paramMap.get('ruleSetKey'));
            }
            else {
                _this.isLoading = false;
            }
        });
    };
    RulesetWizardComponent.prototype.loadRuleSet = function (programKey, ruleSetKey) {
        var _this = this;
        this.ruleSetApi.getRuleSetByKey(programKey, ruleSetKey).subscribe(function (ruleset) {
            _this.payload = ruleset;
            _this.payload.rules.forEach(function (rule) {
                switch (rule.kind) {
                    case RuleKind.ReceiptPurchaseDate:
                        /* Since the purchase date is not timezone aware (it is a DateTime representing the date/time that is printed on the receipt),
                              we want to treat it as UTC.
                              Therefore, force the incoming value to be UTC by adding a Z at the end
                        */
                        if (rule.receiptPurchasedOnStart && !rule.receiptPurchasedOnStart.toString().endsWith('Z')) {
                            rule.receiptPurchasedOnStart = rule.receiptPurchasedOnStart + 'Z';
                        }
                        if (rule.receiptPurchasedOnEnd && !rule.receiptPurchasedOnEnd.toString().endsWith('Z')) {
                            rule.receiptPurchasedOnEnd = rule.receiptPurchasedOnEnd + 'Z';
                        }
                        rule.receiptPurchasedOnStart = _this.toUtcMoment(rule.receiptPurchasedOnStart);
                        rule.receiptPurchasedOnEnd = _this.toUtcMoment(rule.receiptPurchasedOnEnd);
                        break;
                    case RuleKind.ReceiptStore:
                        rule.allStores = cloneJson(_this.masterListOfStores);
                        rule.stores.forEach(function (store) { return (rule.allStores.find(function (all) { return all.storeKey === store.storeKey; }).selected = true); });
                        rule.filteredStores = [];
                        rule.storeFilter = '';
                        _this.filterStores(rule);
                        break;
                    case RuleKind.ReceiptItemProductQuantity:
                    case RuleKind.ReceiptItemProductQuantityAndTotal:
                    case RuleKind.ReceiptItemProductTotal:
                    case RuleKind.ReceiptItemProductMinimumQuantity:
                    case RuleKind.ReceiptItemProductMinimumQuantityAndTotal:
                    case RuleKind.ReceiptItemProductMinimumTotal:
                        rule.allProducts = cloneJson(_this.masterListOfProducts);
                        rule.products.forEach(function (product) { return (rule.allProducts.find(function (all) { return all.productKey === product.productKey; }).selected = true); });
                        rule.filteredProducts = [];
                        rule.productFilter = '';
                        _this.filterProducts(rule);
                        break;
                }
            });
            _this.isLoading = false;
        });
    };
    RulesetWizardComponent.prototype.toUtcMoment = function (value) {
        var parsedDate = toDate(value);
        return this.tzPipe.transform(parsedDate, 'UTC');
    };
    RulesetWizardComponent.prototype.cancelForm = function () {
        this.router.navigate(['/rulesets']);
    };
    RulesetWizardComponent.prototype.submitForm = function (form) {
        var _this = this;
        if (form.valid) {
            this.isSubmitting = true;
            this.alert = null;
            var resultKeyObservable = null;
            if (this.isUpdate) {
                resultKeyObservable = this.ruleSetApi.updateRuleSet(this.payload);
            }
            else {
                resultKeyObservable = this.ruleSetApi.createRuleSet(this.payload);
            }
            resultKeyObservable.subscribe(function (keyResult) {
                _this.isSubmitting = false;
                _this.router.navigate(['/rulesets']);
            }, function (errorResponse) {
                _this.isSubmitting = false;
                var message = 'Error creating rule set.';
                if (errorResponse.error && errorResponse.error.errors && errorResponse.error.errors.length > 0) {
                    var e = errorResponse.error.errors[0];
                    switch (e.errorCode) {
                        case Constants.ErrorCodes.DuplicateRuleSetNameOrKey:
                            message = 'A rule set with that name or key already exists.';
                            break;
                        case Constants.ErrorCodes.InvalidRuleSetDefinition:
                            message = e.message;
                            break;
                        default:
                            message = e.message;
                            break;
                    }
                }
                _this.alert = Alert.danger(message, 30000);
                window.scrollTo(0, 0);
            });
        }
    };
    RulesetWizardComponent.prototype.autoKey = function (maxLength) {
        if (this.isIdentifierAutoFillable) {
            if (!this.payload.name) {
                this.payload.ruleSetKey = '';
            }
            this.payload.ruleSetKey = this.sanitizeString(this.payload.name).substr(0, maxLength);
        }
    };
    RulesetWizardComponent.prototype.setAutoFillable = function (value) {
        this.isIdentifierAutoFillable = value;
    };
    RulesetWizardComponent.prototype.sanitizeString = function (input) {
        if (!input) {
            return input;
        }
        return input.replace(/[^0-9a-zA-Z]/g, '-').toLocaleLowerCase();
    };
    RulesetWizardComponent.prototype.sanitizeName = function () {
        this.payload.name = this.sanitizeString(this.payload.name);
    };
    RulesetWizardComponent.prototype.sanitizeKey = function () {
        this.payload.ruleSetKey = this.sanitizeString(this.payload.ruleSetKey);
    };
    RulesetWizardComponent.prototype.programChange = function () {
        this.sanitizeKey();
        this.sanitizeName();
    };
    //#region __ Add Rule Types __
    RulesetWizardComponent.prototype.addRule = function (kind) {
        var rule = new CreateEditRuleModel();
        rule.kind = kind;
        this.payload.rules.push(rule);
        return rule;
    };
    RulesetWizardComponent.prototype.addRuleBoolean = function () {
        return this.addRule(RuleKind.Boolean);
    };
    RulesetWizardComponent.prototype.addRuleReceiptPurchaseDate = function () {
        return this.addRule(RuleKind.ReceiptPurchaseDate);
    };
    RulesetWizardComponent.prototype.addRuleReceiptStore = function () {
        var rule = this.addRule(RuleKind.ReceiptStore);
        rule.allStores = cloneJson(this.masterListOfStores);
        rule.allStores.forEach(function (s) { return (s.selected = false); });
        this.filterStores(rule);
        return rule;
    };
    RulesetWizardComponent.prototype.openAddRuleProductBased = function (template) {
        this.productRuleKindSelectorModalRef = this.bsModalService.show(template);
    };
    RulesetWizardComponent.prototype.addRuleProductBased = function (kind) {
        var rule = this.addRule(kind);
        rule.allProducts = cloneJson(this.masterListOfProducts);
        rule.allProducts.forEach(function (p) { return (p.selected = false); });
        this.filterProducts(rule);
        this.productRuleKindSelectorModalRef.hide();
        return rule;
    };
    //#endregion
    RulesetWizardComponent.prototype.removeRule = function (index) {
        this.payload.rules.splice(index, 1);
    };
    //#region Stores
    RulesetWizardComponent.prototype.clearStoreFilter = function (rule) {
        rule.storeFilter = '';
        this.filterStores(rule);
    };
    RulesetWizardComponent.prototype.filterStores = function (rule) {
        rule.filteredStores.splice(0, rule.filteredStores.length);
        var self = this;
        var nameContains = function (s) {
            return self.containsAllWords(s.name, rule.storeFilter);
        };
        var isSelectedIfApplicable = function (s) {
            return rule.showSelectedStoresOnly ? s.selected : true;
        };
        rule.allStores
            .filter(function (s) { return nameContains(s) && isSelectedIfApplicable(s); })
            .forEach(function (s) {
            rule.filteredStores.push(s);
        });
    };
    RulesetWizardComponent.prototype.selectVisibleStores = function (rule, selected) {
        rule.filteredStores.slice((rule.storePageNumber - 1) * this.itemsPerPage, rule.storePageNumber * this.itemsPerPage).forEach(function (s) { return (s.selected = selected); });
        this.updateStoresInPayload(rule);
    };
    RulesetWizardComponent.prototype.selectAllStores = function (rule, selected) {
        rule.allStores.forEach(function (s) { return (s.selected = selected); });
        this.updateStoresInPayload(rule);
    };
    RulesetWizardComponent.prototype.toggleStore = function (rule, store) {
        store.selected = !store.selected;
        this.updateStoresInPayload(rule);
    };
    RulesetWizardComponent.prototype.updateStoresInPayload = function (rule) {
        rule.stores.splice(0, rule.stores.length);
        rule.allStores.filter(function (s) { return s.selected; }).forEach(function (s) { return rule.stores.push(s); });
    };
    RulesetWizardComponent.prototype.storePageChange = function (rule, $event) {
        rule.storePageNumber = $event;
    };
    //#endregion
    //#region Products
    RulesetWizardComponent.prototype.clearProductFilter = function (rule) {
        rule.productFilter = '';
        this.filterProducts(rule);
    };
    RulesetWizardComponent.prototype.filterProducts = function (rule) {
        rule.filteredProducts.splice(0, rule.filteredProducts.length);
        var self = this;
        var nameContains = function (p) {
            return self.containsAllWords(p.name, rule.productFilter);
        };
        var isSelectedIfApplicable = function (s) {
            return rule.showSelectedProductsOnly ? s.selected : true;
        };
        rule.allProducts
            .filter(function (s) { return nameContains(s) && isSelectedIfApplicable(s); })
            .forEach(function (s) {
            rule.filteredProducts.push(s);
        });
    };
    RulesetWizardComponent.prototype.selectVisibleProducts = function (rule, selected) {
        rule.filteredProducts.slice((rule.productPageNumber - 1) * this.itemsPerPage, rule.productPageNumber * this.itemsPerPage).forEach(function (s) { return (s.selected = selected); });
        this.updateProductsInPayload(rule);
    };
    RulesetWizardComponent.prototype.selectAllProducts = function (rule, selected) {
        rule.allProducts.forEach(function (s) { return (s.selected = selected); });
        this.updateProductsInPayload(rule);
    };
    RulesetWizardComponent.prototype.toggleProduct = function (rule, product) {
        product.selected = !product.selected;
        this.updateProductsInPayload(rule);
    };
    RulesetWizardComponent.prototype.updateProductsInPayload = function (rule) {
        rule.products.splice(0, rule.products.length);
        rule.allProducts.filter(function (s) { return s.selected; }).forEach(function (s) { return rule.products.push(s); });
    };
    RulesetWizardComponent.prototype.productPageChange = function (rule, $event) {
        rule.productPageNumber = $event;
    };
    //#endregion
    RulesetWizardComponent.prototype.containsAllWords = function (text, wordsAsString) {
        var words = wordsAsString.split(' ');
        for (var i = 0; i < words.length; i++) {
            var matchesWord = text.toLowerCase().indexOf(words[i].toLowerCase()) > -1;
            if (!matchesWord) {
                return false;
            }
        }
        return true;
    };
    return RulesetWizardComponent;
}());
export { RulesetWizardComponent };
