import { environment } from '../../environments/environment';
import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProgramKeyInterceptor } from './interceptors/program-key.interceptor';
import { AccountKeyInterceptor } from './interceptors/account-key.interceptor';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import {
  AuthModule,
  OidcSecurityService,
  OpenIdConfiguration,
  OidcConfigService,
  ConfigResult
} from 'angular-auth-oidc-client';
import { TopNavComponent } from './nav/top-nav/top-nav.component';
import { HomeComponent } from './pages/home/home.component';
import { SessionExpiredComponent } from './pages/session-expired/session-expired.component';
import { CrossAccountComponent } from './pages/cross-account/cross-account.component';
import { LogonComponent } from './pages/logon/logon.component';
import { SigninOidcComponent } from './pages/signin-oidc/signin-oidc.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { ProgramApiService } from './apis/moderation-api/program-api.service';
import { ProductApiService } from './apis/moderation-api/product-api.service';
import { RuleSetApiService } from './apis/moderation-api/ruleset-api.service';
import { CategoryApiService } from './apis/moderation-api/category-api.service';
import { StoreApiService } from './apis/moderation-api/store-api.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { CoreRoutingModule } from './core-routing.module';
import { RouterModule, Router } from '@angular/router';
import { RuleSetModule } from '../ruleset/ruleset.module';
import { StoreModule } from '../store/store.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CategoryModule } from '../category/category.module';
import { ModerationApiService } from './apis/moderation-api/moderation-api.service';
import { QuickActionsApiService } from './apis/moderation-api/quick-actions-api.service';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AccountKeyInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ProgramKeyInterceptor, multi: true },
];

export const apiServices = [
  ProgramApiService,
  ProductApiService,
  StoreApiService,
  RuleSetApiService,
  QuickActionsApiService,
  CategoryApiService,
  ModerationApiService,
  StoreApiService
];

export function loadConfig(oidcConfigService: OidcConfigService) {
  return () => oidcConfigService.load_using_stsServer(environment.oidc.server);
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    AuthModule.forRoot(),
    SharedModule,
    RuleSetModule,
    StoreModule,
    CommonModule,
    CoreRoutingModule,
    CategoryModule,
  ],
  declarations: [
    TopNavComponent,
    SessionExpiredComponent,
    CrossAccountComponent,
    LogonComponent,
    SigninOidcComponent,
    PageNotFoundComponent,
    UnauthorizedComponent,
    HomeComponent,
  ],
  exports: [
    RouterModule,
    TopNavComponent,
    FormsModule
  ]
})
export class CoreModule {
  constructor(public oidcSecurityService: OidcSecurityService, public oidcConfigService: OidcConfigService, router: Router) {
    this.oidcConfigService.onConfigurationLoaded.subscribe((configResult: ConfigResult) => {
      const openIDImplicitFlowConfiguration: OpenIdConfiguration = {};
      openIDImplicitFlowConfiguration.stsServer = environment.oidc.server;
      openIDImplicitFlowConfiguration.redirect_url = environment.oidc.redirectUrl;
      openIDImplicitFlowConfiguration.client_id = environment.oidc.clientId;
      openIDImplicitFlowConfiguration.response_type = 'id_token token';
      openIDImplicitFlowConfiguration.scope = environment.oidc.scopes;
      openIDImplicitFlowConfiguration.post_logout_redirect_uri = environment.oidc.logoutRedirectUrl;
      openIDImplicitFlowConfiguration.start_checksession = false;
      openIDImplicitFlowConfiguration.silent_renew = true;
      openIDImplicitFlowConfiguration.silent_renew_offset_in_seconds = 0;
      openIDImplicitFlowConfiguration.post_login_route = '/home';
      openIDImplicitFlowConfiguration.trigger_authorization_result_event = true; // don't redirect to post_login_route
      openIDImplicitFlowConfiguration.forbidden_route = '/forbidden';
      openIDImplicitFlowConfiguration.unauthorized_route = '/unauthorized';
      openIDImplicitFlowConfiguration.auto_userinfo = true;
      openIDImplicitFlowConfiguration.log_console_warning_active = true;
      openIDImplicitFlowConfiguration.log_console_debug_active = false;
      openIDImplicitFlowConfiguration.max_id_token_iat_offset_allowed_in_seconds = 999999999;
      // Comment out this storage override to use default Session Storage, which is tab specific
      openIDImplicitFlowConfiguration.storage = localStorage;

      // const authWellKnownEndpoints = new AuthWellKnownEndpoints();
      // authWellKnownEndpoints.setWellKnownEndpoints(this.oidcConfigService.wellKnownEndpoints);

      // Force HTTPS on all endpoints regardless of what the STS server returns. This is to help prevent blocked:mixed-content errors.
      // console.log(configResult);
      let r = JSON.stringify(configResult);
      r = r.replace(/http:/gi, 'https:');
      configResult = JSON.parse(r);
      // console.log(configResult);

      // this.oidcSecurityService.setupModule(openIDImplicitFlowConfiguration, authWellKnownEndpoints);
      this.oidcSecurityService.setupModule(openIDImplicitFlowConfiguration, configResult.authWellknownEndpoints);
    });
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        apiServices,
        httpInterceptorProviders,
        CookieService,
        OidcConfigService,
        {
          provide: APP_INITIALIZER,
          useFactory: loadConfig,
          deps: [OidcConfigService],
          multi: true
        },
      ]
    };
  }
}
