import { RuleKind } from '../../rule-kind.enum';
import { StoreModel } from '../response/StoreModel';
import { ProductModel } from '../response/product.model';

export class CreateEditRuleModel {
  ruleKey: string;
  text: string;
  kind: RuleKind;
  passingBooleanValue: boolean;
  receiptPurchasedOnStart: Date | string | null;
  receiptPurchasedOnEnd: Date | string | null;
  minimumQuantity: number | null;
  minimumTotal: number | null;
  stores: Array<StoreModel> = new Array<StoreModel>();
  products: Array<ProductModel> = new Array<ProductModel>();
  isUsed: boolean;

  // View model fields
  allStores: Array<StoreModel> = new Array<StoreModel>();
  filteredStores: Array<StoreModel> = new Array<StoreModel>();
  showSelectedStoresOnly: boolean;
  storeFilter: string;
  storePageNumber = 1;

  allProducts: Array<ProductModel>;
  filteredProducts: Array<ProductModel> = new Array<ProductModel>();
  productFilter = '';
  productPageNumber = 1;
  showSelectedProductsOnly = false;


  constructor() {
    this.kind = RuleKind.Boolean;
    this.ruleKey = '';
    this.text = '';
    this.passingBooleanValue = true;
    this.showSelectedStoresOnly = false;
    this.showSelectedProductsOnly = false;
    this.storeFilter = '';
    this.productFilter = '';
    this.storePageNumber = 1;
    this.productPageNumber = 1;
  }
}
