import { OnInit, TemplateRef } from '@angular/core';
import { StoreApiService } from '../../../core/apis/moderation-api/store-api.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Alert } from '../../../shared/models/alert.model';
var GlobalStoresComponent = /** @class */ (function () {
    function GlobalStoresComponent(storeApi, bsModalService) {
        this.storeApi = storeApi;
        this.bsModalService = bsModalService;
        this.isProcessing = true;
        this.hasStores = false;
        this.searchTerm = '';
        this.filteredStores = [];
        this.newAliasText = '';
        this.newRetailerText = '';
        this.storeSort = function (a, b) {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
        };
    }
    GlobalStoresComponent.prototype.ngOnInit = function () {
        this.refreshStores();
    };
    GlobalStoresComponent.prototype.refreshStores = function () {
        var _this = this;
        this.storeApi.getStores().subscribe(function (storeResults) {
            if (storeResults) {
                _this.storeResults = storeResults;
                _this.hasStores = _this.storeResults.stores.length > 0;
                _this.filteredStores = _this.storeResults.stores;
                _this.filterStores(); // sort
                _this.isProcessing = false;
            }
        });
    };
    GlobalStoresComponent.prototype.clearTerm = function () {
        this.searchTerm = '';
        this.filterStores();
    };
    GlobalStoresComponent.prototype.filterStores = function () {
        if (this.searchTerm.trim() === '') {
            this.filteredStores = this.storeResults.stores.sort(this.storeSort);
            return;
        }
        var searchString = this.searchTerm.trim().toLocaleLowerCase();
        var storeMatch = function (store) {
            return store.name.toLocaleLowerCase().indexOf(searchString) > -1 || store.aliases.filter(function (a) { return a.name.toLocaleLowerCase().indexOf(searchString) > -1; }).length > 0;
        };
        // this.filteredStores = this.storeResults.stores.filter(x => x.name.toLocaleLowerCase().indexOf(this.searchTerm.trim().toLocaleLowerCase()) > -1).sort(this.storeSort);
        this.filteredStores = this.storeResults.stores.filter(storeMatch).sort(this.storeSort);
    };
    GlobalStoresComponent.prototype.openCreateStores = function (template) {
        this.createModalRef = this.bsModalService.show(template);
    };
    GlobalStoresComponent.prototype.openCreateAliases = function (store, template) {
        this.selectedStoreForAlias = store;
        this.createAliasModalRef = this.bsModalService.show(template);
    };
    GlobalStoresComponent.prototype.openDeleteAlias = function (store, alias, template) {
        this.selectedStoreForAlias = store;
        this.selectedAlias = alias;
        this.deleteAliasModalRef = this.bsModalService.show(template);
    };
    GlobalStoresComponent.prototype.deleteAlias = function () {
        var _this = this;
        this.alert = undefined;
        this.isProcessing = true;
        this.deleteAliasModalRef.hide();
        this.storeApi.deleteAliasForStore(this.selectedStoreForAlias, this.selectedAlias).subscribe(function () {
            _this.refreshStores();
        }, function (errorResponse) {
            var error = errorResponse.error;
            _this.isProcessing = false;
            console.log(error);
            if (error.errors && error.errors.length > 0) {
                var err = error.errors[0];
                _this.alert = Alert.danger(err.message);
            }
            else {
                _this.alert = Alert.danger('Unknown error deleting aliases.');
            }
        });
    };
    GlobalStoresComponent.prototype.createStores = function () {
        var _this = this;
        this.alert = undefined;
        this.newRetailerText = this.newRetailerText.trim();
        var newStoreTexts = [];
        this.newRetailerText.split('\n').forEach(function (text) {
            text = text.trim();
            if (text === '') {
                return;
            }
            if (newStoreTexts.find(function (x) { return x.toLowerCase() === text.toLowerCase(); })) {
                return;
            }
            newStoreTexts.push(text);
        }, this);
        this.createModalRef.hide();
        if (newStoreTexts.length === 0) {
            return;
        }
        this.isProcessing = true;
        this.storeApi.createGlobalStores(newStoreTexts).subscribe(function () {
            _this.newRetailerText = '';
            _this.refreshStores();
        }, function (errorResponse) {
            var error = errorResponse.error;
            _this.isProcessing = false;
            console.log(error);
            if (error.errors && error.errors.length > 0) {
                var err = error.errors[0];
                _this.alert = Alert.danger(err.message);
            }
            else {
                _this.alert = Alert.danger('Unknown error adding Retailers.');
            }
        });
    };
    GlobalStoresComponent.prototype.createAliases = function () {
        var _this = this;
        this.alert = undefined;
        this.newAliasText = this.newAliasText.trim();
        var newAliases = [];
        this.newAliasText.split('\n').forEach(function (text) {
            text = text.trim();
            if (text === '') {
                return;
            }
            if (newAliases.find(function (x) { return x.toLowerCase() === text.toLowerCase(); })) {
                return;
            }
            newAliases.push(text);
        }, this);
        this.createAliasModalRef.hide();
        if (newAliases.length === 0) {
            return;
        }
        this.isProcessing = true;
        this.storeApi.createAliasesForStore(this.selectedStoreForAlias, newAliases).subscribe(function () {
            _this.newAliasText = '';
            _this.refreshStores();
        }, function (errorResponse) {
            var error = errorResponse.error;
            _this.isProcessing = false;
            console.log(error);
            if (error.errors && error.errors.length > 0) {
                var err = error.errors[0];
                _this.alert = Alert.danger(err.message);
            }
            else {
                _this.alert = Alert.danger('Unknown error adding aliases.');
            }
        });
    };
    return GlobalStoresComponent;
}());
export { GlobalStoresComponent };
