import { map, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ProgramApiService } from '../../apis/moderation-api/program-api.service';
import { AccountResultsModel } from '../../../shared/models/webapi/response/account-results.model';
import { AccountModel } from '../../../shared/models/webapi/response/account.model';

@Injectable()
export class CrossAccountResolver implements Resolve<AccountModel[]> {
  constructor(private programApi: ProgramApiService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AccountModel[]> {
    return this.programApi.getAccounts().pipe(
      take(1),
      map((accountResult: AccountResultsModel) => {
        if (accountResult && accountResult.accounts) {
          return accountResult.accounts.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          // TODO: Send to an error page or something
          this.router.navigateByUrl('/unauthorized');
          return null;
        }
      })
    );
  }
}
