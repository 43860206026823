<div class="center-stage">
  <h1>
    <span class="fa fa-check-square-o"></span>
  </h1>
  <h1>Moderation Admin</h1>
  <p *ngIf="!isAuthorized">
    Welcome to the Moderation Admin!
    <a routerLink="/logon">Log on</a> to view and manage rules for your program.
  </p>
  <ng-container *ngIf="isAuthorized">
    <ng-container *ngIf="accountName">
      <h2>{{ accountName }}</h2>
    </ng-container>
  </ng-container>
</div>
