/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/widgets/folding-cube/folding-cube.component.ngfactory";
import * as i3 from "../../../shared/widgets/folding-cube/folding-cube.component";
import * as i4 from "./logon.component";
import * as i5 from "angular-auth-oidc-client";
var styles_LogonComponent = [i0.styles];
var RenderType_LogonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogonComponent, data: {} });
export { RenderType_LogonComponent as RenderType_LogonComponent };
export function View_LogonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "center-stage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-folding-cube", [], null, null, null, i2.View_FoldingCubeComponent_0, i2.RenderType_FoldingCubeComponent)), i1.ɵdid(2, 114688, null, 0, i3.FoldingCubeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_LogonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logon", [], null, null, null, View_LogonComponent_0, RenderType_LogonComponent)), i1.ɵdid(1, 114688, null, 0, i4.LogonComponent, [i5.OidcSecurityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogonComponentNgFactory = i1.ɵccf("app-logon", i4.LogonComponent, View_LogonComponent_Host_0, {}, {}, []);
export { LogonComponentNgFactory as LogonComponentNgFactory };
