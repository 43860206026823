import { CrossAccountResolver } from './pages/cross-account/cross-account-resolver.service';
import { LogonComponent } from './pages/logon/logon.component';
import { CrossAccountComponent } from './pages/cross-account/cross-account.component';
import { SessionExpiredComponent } from './pages/session-expired/session-expired.component';
import { Routes, RouterModule, Router, Route, CanActivate } from '@angular/router';
import { NgModule, Type } from '@angular/core';
import { AuthGuard } from './services/auth-guard.service';
import { CrossAccountGuard } from './services/cross-account-guard.service';
import { SigninOidcComponent } from './pages/signin-oidc/signin-oidc.component';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { isArray } from 'util';
import { CrossAccountService } from './pages/cross-account/cross-account.service';

// Global routes
const appRoutes: Routes = [
  // Children modules
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'rulesets', loadChildren: 'app/ruleset/ruleset.module#RuleSetModule' },
  { path: 'products', loadChildren: 'app/product/product.module#ProductModule' },
  { path: 'boarding', loadChildren: 'app/boarding/boarding.module#BoardingModule' },
  { path: 'actions', loadChildren: 'app/quick-actions/quick-actions.module#QuickActionsModule' },
  { path: 'stores', loadChildren: 'app/store/store.module#StoreModule' },
  { path: 'categories', loadChildren: 'app/category/category.module#CategoryModule' },

  {
    path: '.cross-account',
    component: CrossAccountComponent,
    resolve: { crossAccount: CrossAccountResolver },
    data: { skipCanActivateGuards: [CrossAccountGuard] }
  },
  // Non-authorized pages
  { path: 'home', component: HomeComponent, data: { skipCanActivateGuards: [AuthGuard, CrossAccountGuard] } },
  { path: 'logon', component: LogonComponent, data: { skipCanActivateGuards: [AuthGuard, CrossAccountGuard] } },
  { path: 'signin-oidc', component: SigninOidcComponent, data: { skipCanActivateGuards: [AuthGuard, CrossAccountGuard] } },
  { path: 'unauthorized', component: UnauthorizedComponent, data: { skipCanActivateGuards: [AuthGuard, CrossAccountGuard] } },
  { path: 'session-expired', component: SessionExpiredComponent, data: { skipCanActivateGuards: [AuthGuard, CrossAccountGuard] } },
  { path: '**', component: PageNotFoundComponent, data: { skipCanActivateGuards: [AuthGuard, CrossAccountGuard] } }
];

// Global CanActivate Guards
const canActivateGuards: Type<CanActivate>[]  = [
  CrossAccountGuard,
  AuthGuard,
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthGuard,
    CrossAccountGuard,
    CrossAccountResolver,
    CrossAccountService
  ]
})
export class CoreRoutingModule {
  constructor(router: Router) {
    // Add the AuthGuard to all paths except those that explicitly skip
    router.config.forEach((route: Route) => {
      for (const canActivateGuard of canActivateGuards) {
        if (route.data && route.data.skipCanActivateGuards && isArray(route.data.skipCanActivateGuards)) {
          if (route.data.skipCanActivateGuards.indexOf(canActivateGuard) > -1) {
            continue;
          }
        }
        this.addCanActivateGuard(route, canActivateGuard);
      }

    });
  }

  /**
   * Adds a CanActivate guard to the specified route.
   * @param route The route to add the guard to.
   * @param guardType The Type of the guard to add to the array of CanActivate guards
   */
  private addCanActivateGuard(route: Route, guardType: Type<CanActivate>): void {
    if (guardType != null ) {
      route.canActivate = route.canActivate === undefined ? [guardType] : [guardType].concat(route.canActivate);
      route.canActivateChild = route.canActivateChild === undefined ? [guardType] : [guardType].concat(route.canActivateChild);
    }
  }
 }
