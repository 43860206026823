import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { Constants } from '../../constants';
var CrossAccountGuard = /** @class */ (function () {
    function CrossAccountGuard(router, cookieService, oidc) {
        this.router = router;
        this.cookieService = cookieService;
        this.oidc = oidc;
    }
    CrossAccountGuard.prototype.canActivate = function (route, state) {
        var hasAdminAccountKeyCookie = this.cookieService.check(Constants.HeaderAdminAccountKey);
        // TODO: Check 'cross_account' in token payload as well
        var isCrossAccount = false;
        var token = this.oidc.getToken();
        if (token) {
            var payload = JSON.parse(this.urlBase64Decode(token.split('.')[1]));
            // Right now, 'cross_account' comes back as a string. In the future it might become a boolean, so let's check
            if (typeof payload.cross_account === 'string') {
                isCrossAccount = payload.cross_account.match(/true/i).length > 0;
            }
            else if (typeof payload.cross_account === 'boolean') {
                isCrossAccount = payload.cross_account;
            }
        }
        if (!hasAdminAccountKeyCookie && isCrossAccount) {
            var returnUrl = state.url;
            this.router.navigate(['/.cross-account'], { queryParams: { returnUrl: returnUrl } });
        }
        return true;
    };
    CrossAccountGuard.prototype.canActivateChild = function (childRoute, state) {
        return this.canActivate(childRoute, state);
    };
    CrossAccountGuard.prototype.urlBase64Decode = function (str) {
        if (!str) {
            return str;
        }
        var output = str.replace('-', '+').replace('_', '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                throw Error('Illegal base64url string!');
        }
        return window.atob(output);
    };
    return CrossAccountGuard;
}());
export { CrossAccountGuard };
