import { Routes } from '@angular/router';
import { CategoryComponent } from './category.component';
import { CategoriesComponent } from './pages/categories/categories.component';
var routes = [
    {
        path: '',
        component: CategoryComponent,
        children: [
            { path: '', component: CategoriesComponent }
        ]
    }
];
var CategoryRoutingModule = /** @class */ (function () {
    function CategoryRoutingModule() {
    }
    return CategoryRoutingModule;
}());
export { CategoryRoutingModule };
