var Constants = /** @class */ (function () {
    function Constants() {
    }
    Constants.HeaderAdminAccountKey = 'x-pl-admin-accountKey';
    // This member is named "Cookie" because we are only storing it as a cookie for display purposes and will not be using it as a Header like HeaderAdminAccountKey is used for both
    Constants.CookieAdminAccountName = 'x-pl-admin-accountName';
    Constants.HeaderProgramKey = 'x-pl-program';
    Constants.HeaderCategoryKey = 'x-pl-category';
    Constants.HeaderContinuationToken = 'x-pl-continuation';
    Constants.DefaultMaxItems = 20;
    Constants.ErrorCodes = {
        InvalidRuleSetDefinition: 20002,
        DuplicateRuleSetNameOrKey: 20003
    };
    return Constants;
}());
export { Constants };
