import { AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { RuleSetResultsModel } from '../../../shared/models/webapi/response/RuleSetResultsModel';
import { RuleSetApiService } from './../../../core/apis/moderation-api/ruleset-api.service';
import { Constants } from '../../../constants';
import { ProgramApiService } from '../../../core/apis/moderation-api/program-api.service';
import { CookieService } from 'ngx-cookie-service';
import { RuleSetType } from '../../../shared/models/rule-set-type.enum';
var RuleSetsComponent = /** @class */ (function () {
    function RuleSetsComponent(cookieService, programApi, ruleSetApi, router) {
        this.cookieService = cookieService;
        this.programApi = programApi;
        this.ruleSetApi = ruleSetApi;
        this.router = router;
        this.appliedStatusFilters = [];
        this.maxRows = Constants.DefaultMaxItems;
        // Programs
        this.isLoadingPrograms = true;
        // This allows the DataTable object to be set up during page load before a Program is selected and RuleSets are loaded
        this.ruleSets = new RuleSetResultsModel();
        this.ruleSets.items = new Array();
        this.isSearching = false;
        this.hasSearched = false;
    }
    RuleSetsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.programApi.getPrograms().subscribe(function (programResult) {
            _this.isLoadingPrograms = false;
            _this.programs = programResult.programs;
            if (_this.programs.length === 1) {
                // auto select the only one
                _this.selectedProgram = _this.programs[0];
                _this.onProgramChange();
            }
            else if (_this.cookieService.get(Constants.HeaderProgramKey)) {
                var programKey_1 = _this.cookieService.get(Constants.HeaderProgramKey);
                _this.selectedProgram = _this.programs.filter(function (p) { return p.programKey === programKey_1; })[0];
                _this.onProgramChange();
            }
        });
        var _self = this;
        $.fn.dataTable.ext.errMode = 'throw';
        this.dtOptions = {
            paging: false,
            info: false,
            data: this.ruleSets.items,
            searching: true,
            ordering: true,
            order: [[0, 'desc']],
            dom: 'lrtip',
            columns: [
                { title: 'Type', data: 'ruleSetType', createdCell: this.onTypeCellCreate, orderable: true },
                {
                    title: 'Name',
                    data: 'name',
                    createdCell: this.onNameCellCreate,
                    orderable: true
                },
                { title: 'Key', data: 'ruleSetKey', orderable: false },
                { title: 'Rules', data: 'ruleCount', orderable: true }
            ],
            rowCallback: function (row, data, index) {
                // We've got to bind here because the html we inject isn't recognized by Angular
                $('.ruleset-edit-link', row).off('click');
                $('.ruleset-edit-link', row).on('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    _self.router.navigate(['/rulesets', data.programKey, data.ruleSetKey, 'wizard']);
                });
            }
        };
    };
    /**
     * Called for each cell created in the 'Name' column, used to inject links to manage page
     * @param cell The cell being created
     * @param cellData The data inside the cell
     * @param rowData The data for the whole row
     * @param row The number of the row
     * @param col The number of the column
     */
    RuleSetsComponent.prototype.onNameCellCreate = function (cell, cellData, rowData, row, col) {
        var html = "<a\n        class=\"ruleset-edit-link\"\n        href=\"/rulesets/" + rowData.programKey + "/" + rowData.ruleSetKey + "/wizard\">" + cellData + "</a>";
        cell.innerHTML = html;
    };
    RuleSetsComponent.prototype.onTypeCellCreate = function (cell, cellData, rowData, row, col) {
        cell.innerHTML = rowData.ruleSetType === RuleSetType.UserGenerated ? 'UGC' : 'Receipt';
    };
    /**
     * Called for each cell created in the 'Status' column, used to inject icons
     * @param cell The cell being created
     * @param cellData The data inside the cell
     * @param rowData The data for the whole row
     * @param row The number of the row
     * @param col The number of the column
     */
    RuleSetsComponent.prototype.onStatusCreate = function (cell, cellData, rowData, row, col) {
        var faClass = '';
        switch (cellData) {
            case 'Unpublished':
                faClass = 'fa-calendar-o';
                break;
            case 'Live':
                faClass = 'fa-calendar-check-o';
                break;
            case 'Future':
                faClass = 'fa-calendar-minus-o';
                break;
            case 'Old':
                faClass = 'fa-calendar-times-o';
                break;
        }
        var statusClass = cellData.toLowerCase();
        var html = "<span class=\"fa " + faClass + " " + statusClass + "\"></span> " + cellData;
        cell.innerHTML = html;
    };
    RuleSetsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.dataTableDirective.dtInstance.then(function (api) {
            _this.dataTable = api;
        });
    };
    RuleSetsComponent.prototype.getAllRuleSets = function () {
        var _this = this;
        this.hasSearched = false;
        this.isSearching = true;
        this.ruleSetApi.getAllRuleSets().subscribe(function (httpResponse) {
            _this.ruleSets = httpResponse.body;
            _this.searchContinuationToken = httpResponse.headers.get(Constants.HeaderContinuationToken);
            if (_this.ruleSets.items.length < _this.maxRows) {
                _this.noMoreSearchResults = true;
            }
            else {
                _this.noMoreSearchResults = false;
            }
            _this.clearAndRefreshDataTable(_this.ruleSets);
            _this.isSearching = false;
        });
    };
    RuleSetsComponent.prototype.searchRuleSets = function () {
        var _this = this;
        this.hasSearched = false;
        this.isSearching = true;
        this.ruleSetApi.searchRuleSets(this.searchKeywords).subscribe(function (httpResponse) {
            _this.ruleSets = httpResponse.body;
            _this.searchContinuationToken = httpResponse.headers.get(Constants.HeaderContinuationToken);
            _this.hasSearched = true;
            _this.isSearching = false;
            if (_this.ruleSets.items.length < _this.maxRows) {
                _this.noMoreSearchResults = true;
            }
            else {
                _this.noMoreSearchResults = false;
            }
            _this.clearAndRefreshDataTable(_this.ruleSets);
        });
    };
    RuleSetsComponent.prototype.loadMoreRuleSets = function () {
        var _this = this;
        this.isLoadingMore = true;
        this.ruleSetApi.loadMoreRuleSets(this.searchContinuationToken).subscribe(function (httpResponse) {
            var results = httpResponse.body;
            _this.searchContinuationToken = httpResponse.headers.get(Constants.HeaderContinuationToken);
            if (results.items.length < 1) {
                _this.noMoreSearchResults = true;
            }
            else {
                _this.ruleSets = results;
                _this.dataTable.rows.add(results.items).draw();
                _this.noMoreSearchResults = false;
            }
            _this.isLoadingMore = false;
        });
    };
    RuleSetsComponent.prototype.resetResults = function () {
        this.clearResults();
        this.getAllRuleSets();
    };
    RuleSetsComponent.prototype.clearResults = function () {
        this.hasSearched = false;
        this.isSearching = false;
        this.searchKeywords = '';
    };
    RuleSetsComponent.prototype.clearAndRefreshDataTable = function (newData) {
        this.dataTable.clear();
        this.dataTable.rows.add(newData.items).draw();
    };
    RuleSetsComponent.prototype.onProgramChange = function () {
        if (this.selectedProgram) {
            // The ProgramKeyInterceptor will pick this up. This is here until we get a program selection page
            this.cookieService.set(Constants.HeaderProgramKey, this.selectedProgram.programKey);
            this.resetResults();
        }
        else {
            this.cookieService.delete(Constants.HeaderProgramKey);
            this.clearResults();
        }
    };
    return RuleSetsComponent;
}());
export { RuleSetsComponent };
