import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProgramResultsModel } from '../../../shared/models/webapi/response/ProgramResultsModel';
import { ModerationApiService } from './moderation-api.service';
import { AccountResultsModel } from '../../../shared/models/webapi/response/account-results.model';
import { AccountModel } from '../../../shared/models/webapi/response/account.model';
import { ProgramModel } from '../../../shared/models/webapi/response/ProgramModel';

@Injectable()
export class ProgramApiService extends ModerationApiService {
  constructor(http: HttpClient) {
    super(http);
  }

  getAccounts(): Observable<AccountResultsModel> {
    return this.getFor<AccountResultsModel>('/admin/lookup/accounts');
  }

  getUnboardedAccounts(): Observable<AccountResultsModel> {
    return this.getFor<AccountResultsModel>('/admin/lookup/accounts/unboarded');
  }

  boardAccount(account: AccountModel) {
    return this.postFor('/admin/lookup/accounts/board', account);
  }

  getPrograms(accountKey?: string): Observable<ProgramResultsModel> {
    accountKey = accountKey || '';
    return this.getFor<ProgramResultsModel>(`/admin/lookup/programs?forAccountKey=${accountKey}`);
  }

  getUnboardedPrograms(accountKey: string): Observable<ProgramResultsModel> {
    return this.getFor<ProgramResultsModel>(`/admin/lookup/account/${accountKey}/programs/unboarded?clientKey=`);
  }

  boardProgram(accountKey: string, programKey: string, name: string) {
    const data: any = { accountKey, programKey, name};
    return this.postFor(`/admin/lookup/account/${accountKey}/programs/board`, data);
  }

  getProgram(accountKey: string, programKey: string): Observable<ProgramModel> {
    return this.getFor<ProgramModel>(`/admin/lookup/account/${accountKey}/program/${programKey}`);
  }

  updateProgram(accountKey: string, programKey: string, program: ProgramModel) {
    return this.putFor(`/admin/lookup/account/${accountKey}/program/${programKey}`, program);
  }
}
