import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import * as HttpStatus from 'http-status-codes';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private oidcSecurityService: OidcSecurityService;

  constructor(
    private injector: Injector,
    private router: Router,
    private cookieService: CookieService
  ) {  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;

    if (this.oidcSecurityService === undefined) {
      this.oidcSecurityService = this.injector.get(OidcSecurityService);
    }

    if (this.oidcSecurityService !== undefined) {
      const token = this.oidcSecurityService.getToken();
      if (token !== '') {
        const tokenValue = 'Bearer ' + token;

        const headers = { 'Authorization': tokenValue };
        requestToForward = req.clone({ setHeaders: headers });
      }
    } else {
      console.debug('OidcSecurityService undefined: NO auth header!');
    }

    return next.handle(requestToForward)
      .pipe(
        tap(
          () => {}, // Do nothing on success
          error => {
            if (error instanceof HttpErrorResponse && error.status === HttpStatus.UNAUTHORIZED) {
              console.debug('Response UNAUTHORIZED, redirecting to session expired page.');
              this.router.navigateByUrl('/sessionexpired');
            }
          }
        )
      );
  }
}
