import { OnInit } from '@angular/core';
var PageNotFoundComponent = /** @class */ (function () {
    function PageNotFoundComponent() {
    }
    // TODO: Give this some style!
    PageNotFoundComponent.prototype.ngOnInit = function () {
    };
    return PageNotFoundComponent;
}());
export { PageNotFoundComponent };
