import * as tslib_1 from "tslib";
import { KeyRequestModel } from './key-request.model';
var UpdateKeyRequestModel = /** @class */ (function (_super) {
    tslib_1.__extends(UpdateKeyRequestModel, _super);
    function UpdateKeyRequestModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UpdateKeyRequestModel;
}(KeyRequestModel));
export { UpdateKeyRequestModel };
