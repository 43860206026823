import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ModerationApiService } from './moderation-api.service';
var StoreApiService = /** @class */ (function (_super) {
    tslib_1.__extends(StoreApiService, _super);
    function StoreApiService(http) {
        return _super.call(this, http) || this;
    }
    StoreApiService.prototype.getStores = function () {
        return this.getFor('/admin/lookup/stores');
    };
    StoreApiService.prototype.searchStores = function (keyword, continuationToken, culture) {
        var params = {
            keyword: keyword,
            continuation: continuationToken,
            culture: culture
        };
        return this.getWithParamsFor('/store/search', params);
    };
    StoreApiService.prototype.getStoresForProgram = function (programKey) {
        return this.getFor('/admin/stores/' + programKey);
    };
    StoreApiService.prototype.createAliasesForStore = function (store, newAliases) {
        var payload = { names: newAliases };
        return this.postFor("/admin/stores/global/" + store.storeKey + "/aliases", payload);
    };
    StoreApiService.prototype.createGlobalStores = function (newRetailers) {
        var payload = { names: newRetailers };
        return this.postFor("/admin/stores/global", payload);
    };
    StoreApiService.prototype.createStore = function (payload, programKey) {
        return this.postFor('/admin/stores/' + programKey, payload);
    };
    StoreApiService.prototype.updateStore = function (payload, programKey) {
        return this.putFor('/admin/stores/' + programKey, payload);
    };
    StoreApiService.prototype.deleteStore = function (payload, programKey) {
        return this.deleteFor('/admin/stores/' + programKey + '/' + payload.storeKey);
    };
    StoreApiService.prototype.deleteAliasForStore = function (store, alias) {
        return this.deleteFor("/admin/stores/global/" + store.storeKey + "/aliases/" + alias.name);
    };
    StoreApiService.prototype.setStoreAutoApprove = function (programKey, storeKey, autoApprove) {
        return this.postFor("/admin/stores/" + programKey + "/" + storeKey + "/autoapprove/" + autoApprove, null);
    };
    return StoreApiService;
}(ModerationApiService));
export { StoreApiService };
