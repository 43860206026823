import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { RuleSetModel } from '../../../shared/models/webapi/response/RuleSetModel';
import { RuleSetResultsModel } from '../../../shared/models/webapi/response/RuleSetResultsModel';
import { RuleSetApiService } from './../../../core/apis/moderation-api/ruleset-api.service';
import { Constants } from '../../../constants';
import { ProgramModel } from '../../../shared/models/webapi/response/ProgramModel';
import { ProgramResultsModel } from '../../../shared/models/webapi/response/ProgramResultsModel';
import { ProgramApiService } from '../../../core/apis/moderation-api/program-api.service';
import { CookieService } from 'ngx-cookie-service';
import { RuleSetType } from '../../../shared/models/rule-set-type.enum';

@Component({
  selector: 'app-rulesets',
  templateUrl: './rulesets.component.html',
  styleUrls: ['./rulesets.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RuleSetsComponent implements OnInit, AfterViewInit {
  // DataTable related
  @ViewChild(DataTableDirective)
  private dataTableDirective: DataTableDirective;
  private dataTable: DataTables.Api;
  public dtOptions: DataTables.Settings;

  // Searching/filtering related
  public searchKeywords: string;
  public isSearching: boolean;
  public hasSearched: boolean;
  public appliedStatusFilters: string[] = [];
  private searchContinuationToken: string;
  isLoadingMore: boolean;
  noMoreSearchResults: boolean;

  public ruleSets: RuleSetResultsModel;
  maxRows = Constants.DefaultMaxItems;

  // Programs
  isLoadingPrograms = true;
  programs: ProgramModel[];
  selectedProgram: ProgramModel;

  constructor(private cookieService: CookieService, private programApi: ProgramApiService, private ruleSetApi: RuleSetApiService, private router: Router) {
    // This allows the DataTable object to be set up during page load before a Program is selected and RuleSets are loaded
    this.ruleSets = new RuleSetResultsModel();
    this.ruleSets.items = new Array<RuleSetModel>();

    this.isSearching = false;
    this.hasSearched = false;
  }

  ngOnInit() {
    this.programApi.getPrograms().subscribe((programResult: ProgramResultsModel) => {
      this.isLoadingPrograms = false;
      this.programs = programResult.programs;
      if (this.programs.length === 1) {
        // auto select the only one
        this.selectedProgram = this.programs[0];
        this.onProgramChange();
      } else if (this.cookieService.get(Constants.HeaderProgramKey)) {
        const programKey = this.cookieService.get(Constants.HeaderProgramKey);
        this.selectedProgram = this.programs.filter(p => p.programKey === programKey)[0];
        this.onProgramChange();
      }
    });

    const _self = this;
    $.fn.dataTable.ext.errMode = 'throw';
    this.dtOptions = {
      paging: false,
      info: false,
      data: this.ruleSets.items,
      searching: true,
      ordering: true,
      order: [[0, 'desc']],
      dom: 'lrtip', // Used to disable the filter box
      columns: [
        { title: 'Type', data: 'ruleSetType', createdCell: this.onTypeCellCreate, orderable: true },
        {
          title: 'Name',
          data: 'name',
          createdCell: this.onNameCellCreate,
          orderable: true
        },
        { title: 'Key', data: 'ruleSetKey', orderable: false },
        { title: 'Rules', data: 'ruleCount', orderable: true }
      ],
      rowCallback: (row: Node, data: any | Object | RuleSetModel, index: number) => {
        // We've got to bind here because the html we inject isn't recognized by Angular
        $('.ruleset-edit-link', row).off('click');
        $('.ruleset-edit-link', row).on('click', e => {
          e.preventDefault();
          e.stopPropagation();
          _self.router.navigate(['/rulesets', data.programKey, data.ruleSetKey, 'wizard']);
        });
      }
    };
  }

  /**
   * Called for each cell created in the 'Name' column, used to inject links to manage page
   * @param cell The cell being created
   * @param cellData The data inside the cell
   * @param rowData The data for the whole row
   * @param row The number of the row
   * @param col The number of the column
   */
  onNameCellCreate(cell: HTMLElement, cellData: any, rowData: any, row: number, col: number): void {
    const html = `<a
        class="ruleset-edit-link"
        href="/rulesets/${rowData.programKey}/${rowData.ruleSetKey}/wizard">${cellData}</a>`;
    cell.innerHTML = html;
  }

  onTypeCellCreate(cell: HTMLElement, cellData: any, rowData: any, row: number, col: number): void {
    cell.innerHTML = rowData.ruleSetType === RuleSetType.UserGenerated ? 'UGC' : 'Receipt';
  }

  /**
   * Called for each cell created in the 'Status' column, used to inject icons
   * @param cell The cell being created
   * @param cellData The data inside the cell
   * @param rowData The data for the whole row
   * @param row The number of the row
   * @param col The number of the column
   */
  onStatusCreate(cell: HTMLElement, cellData: any, rowData: any, row: number, col: number): void {
    let faClass = '';
    switch (cellData) {
      case 'Unpublished':
        faClass = 'fa-calendar-o';
        break;
      case 'Live':
        faClass = 'fa-calendar-check-o';
        break;
      case 'Future':
        faClass = 'fa-calendar-minus-o';
        break;
      case 'Old':
        faClass = 'fa-calendar-times-o';
        break;
    }

    const statusClass = cellData.toLowerCase();
    const html = `<span class="fa ${faClass} ${statusClass}"></span> ${cellData}`;
    cell.innerHTML = html;
  }

  ngAfterViewInit() {
    this.dataTableDirective.dtInstance.then(api => {
      this.dataTable = api;
    });
  }

  getAllRuleSets() {
    this.hasSearched = false;
    this.isSearching = true;

    this.ruleSetApi.getAllRuleSets().subscribe(httpResponse => {
      this.ruleSets = httpResponse.body;
      this.searchContinuationToken = httpResponse.headers.get(Constants.HeaderContinuationToken);
      if (this.ruleSets.items.length < this.maxRows) {
        this.noMoreSearchResults = true;
      } else {
        this.noMoreSearchResults = false;
      }
      this.clearAndRefreshDataTable(this.ruleSets);
      this.isSearching = false;
    });
  }

  searchRuleSets() {
    this.hasSearched = false;
    this.isSearching = true;

    this.ruleSetApi.searchRuleSets(this.searchKeywords).subscribe(httpResponse => {
      this.ruleSets = httpResponse.body;
      this.searchContinuationToken = httpResponse.headers.get(Constants.HeaderContinuationToken);
      this.hasSearched = true;
      this.isSearching = false;
      if (this.ruleSets.items.length < this.maxRows) {
        this.noMoreSearchResults = true;
      } else {
        this.noMoreSearchResults = false;
      }
      this.clearAndRefreshDataTable(this.ruleSets);
    });
  }

  loadMoreRuleSets() {
    this.isLoadingMore = true;

    this.ruleSetApi.loadMoreRuleSets(this.searchContinuationToken).subscribe(httpResponse => {
      const results = httpResponse.body;
      this.searchContinuationToken = httpResponse.headers.get(Constants.HeaderContinuationToken);
      if (results.items.length < 1) {
        this.noMoreSearchResults = true;
      } else {
        this.ruleSets = results;
        this.dataTable.rows.add(results.items).draw();
        this.noMoreSearchResults = false;
      }
      this.isLoadingMore = false;
    });
  }

  resetResults() {
    this.clearResults();
    this.getAllRuleSets();
  }

  clearResults() {
    this.hasSearched = false;
    this.isSearching = false;
    this.searchKeywords = '';
  }

  clearAndRefreshDataTable(newData: RuleSetResultsModel): void {
    this.dataTable.clear();
    this.dataTable.rows.add(newData.items).draw();
  }

  onProgramChange() {
    if (this.selectedProgram) {
      // The ProgramKeyInterceptor will pick this up. This is here until we get a program selection page
      this.cookieService.set(Constants.HeaderProgramKey, this.selectedProgram.programKey);
      this.resetResults();
    } else {
      this.cookieService.delete(Constants.HeaderProgramKey);
      this.clearResults();
    }
  }
}
