import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BaseApi } from '../base-api';
var ModerationApiService = /** @class */ (function (_super) {
    tslib_1.__extends(ModerationApiService, _super);
    function ModerationApiService(http) {
        return _super.call(this, environment.urls.moderationApi, http) || this;
    }
    ModerationApiService.prototype.getVersion = function () {
        return this.getFor('/.well-check/version');
    };
    return ModerationApiService;
}(BaseApi));
export { ModerationApiService };
