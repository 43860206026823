import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Constants } from '../../../constants';
import { CrossAccountService } from './cross-account.service';
import { AccountModel } from '../../../shared/models/webapi/response/account.model';


@Component({
  selector: 'app-cross-account',
  templateUrl: './cross-account.component.html',
  styleUrls: ['./cross-account.component.scss']
})
export class CrossAccountComponent implements OnInit {
  accounts: AccountModel[];
  chosenAccountKey: string;
  chosenAccountName: string;
  redirecting: boolean;
  returnUrl: string;

  // TODO: Implement this so that they're shown this if they don't have the PL Admin Account Key header/cookie!
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private crossAccountService: CrossAccountService
  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    this.route.data.subscribe((data: {crossAccount: AccountModel[] }) => {
      this.accounts = data.crossAccount;
    });

    if (this.cookieService.check(Constants.HeaderAdminAccountKey)) {
      this.chosenAccountKey = this.cookieService.get(Constants.HeaderAdminAccountKey);
      this.chosenAccountName = this.cookieService.get(Constants.CookieAdminAccountName);
    }
  }

  onAccountChoice(accountKey: string, accountName: string) {
    if (accountKey) {
      this.chosenAccountKey = accountKey;
      this.chosenAccountName = accountName;
      this.crossAccountService.selectAccount(accountKey, accountName);
      this.redirecting = true;

      if (this.returnUrl) {
        this.router.navigateByUrl(this.returnUrl);
      } else {
        this.router.navigate(['/']);
      }
    }
  }
}
