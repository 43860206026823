export class Constants {
  public static readonly HeaderAdminAccountKey = 'x-pl-admin-accountKey';
  // This member is named "Cookie" because we are only storing it as a cookie for display purposes and will not be using it as a Header like HeaderAdminAccountKey is used for both
  public static readonly CookieAdminAccountName = 'x-pl-admin-accountName';
  public static readonly HeaderProgramKey = 'x-pl-program';
  public static readonly HeaderCategoryKey = 'x-pl-category';
  public static readonly HeaderContinuationToken = 'x-pl-continuation';
  public static readonly DefaultMaxItems = 20;
  public static readonly ErrorCodes = {
    InvalidRuleSetDefinition: 20002,
    DuplicateRuleSetNameOrKey: 20003
  };
}
