import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ModerationApiService } from './moderation-api.service';
var ProgramApiService = /** @class */ (function (_super) {
    tslib_1.__extends(ProgramApiService, _super);
    function ProgramApiService(http) {
        return _super.call(this, http) || this;
    }
    ProgramApiService.prototype.getAccounts = function () {
        return this.getFor('/admin/lookup/accounts');
    };
    ProgramApiService.prototype.getUnboardedAccounts = function () {
        return this.getFor('/admin/lookup/accounts/unboarded');
    };
    ProgramApiService.prototype.boardAccount = function (account) {
        return this.postFor('/admin/lookup/accounts/board', account);
    };
    ProgramApiService.prototype.getPrograms = function (accountKey) {
        accountKey = accountKey || '';
        return this.getFor("/admin/lookup/programs?forAccountKey=" + accountKey);
    };
    ProgramApiService.prototype.getUnboardedPrograms = function (accountKey) {
        return this.getFor("/admin/lookup/account/" + accountKey + "/programs/unboarded?clientKey=");
    };
    ProgramApiService.prototype.boardProgram = function (accountKey, programKey, name) {
        var data = { accountKey: accountKey, programKey: programKey, name: name };
        return this.postFor("/admin/lookup/account/" + accountKey + "/programs/board", data);
    };
    ProgramApiService.prototype.getProgram = function (accountKey, programKey) {
        return this.getFor("/admin/lookup/account/" + accountKey + "/program/" + programKey);
    };
    ProgramApiService.prototype.updateProgram = function (accountKey, programKey, program) {
        return this.putFor("/admin/lookup/account/" + accountKey + "/program/" + programKey, program);
    };
    return ProgramApiService;
}(ModerationApiService));
export { ProgramApiService };
