import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { KeyResult } from '../../../shared/models/key-result.model';
import { CreateEditRuleSetModel } from '../../../shared/models/webapi/request/CreateEditRuleSetModel';
import { RuleSetResultsModel } from '../../../shared/models/webapi/response/RuleSetResultsModel';
import { Constants } from './../../../constants';
import { ModerationApiService } from './moderation-api.service';
import { cloneJson } from '../../../shared/utils/cloneJson';

@Injectable()
export class RuleSetApiService extends ModerationApiService {
  constructor(http: HttpClient) {
    super(http);
  }

  getRuleSetByKey(programKey: string, ruleSetKey: string): Observable<CreateEditRuleSetModel> {
    return this.getFor<CreateEditRuleSetModel>('/admin/rulesets/' + programKey + '/' + ruleSetKey);
  }

  getAllRuleSets(): Observable<HttpResponse<RuleSetResultsModel>> {
    return this.getWithResponseFor<RuleSetResultsModel>('/admin/rulesets');
  }

  searchRuleSets(keyword: string): Observable<HttpResponse<RuleSetResultsModel>> {
    return this.getWithResponseFor<RuleSetResultsModel>('/admin/rulesets/search/' + keyword);
  }

  loadMoreRuleSets(token: string): Observable<HttpResponse<RuleSetResultsModel>> {
    const options: { headers: HttpHeaders; observe: 'response' } = {
      headers: new HttpHeaders().set(Constants.HeaderContinuationToken, token),
      observe: 'response'
    };
    return this.http.get<RuleSetResultsModel>(super.urlFor('/admin/rulesets/search/continuation'), options);
  }

  isAvailableRuleSetKey(programKey: string, ruleSetKey: string) {
    return this.http.head(super.urlFor('/admin/rulesets/is-available-key/' + programKey + '/' + ruleSetKey));
  }
  isAvailableRuleSetName(programKey: string, ruleSetName: string) {
    return this.http.head(super.urlFor('/admin/rulesets/is-available-name/' + programKey + '/' + ruleSetName));
  }

  createRuleSet(payload: CreateEditRuleSetModel): Observable<KeyResult> {
    const cleanedPayload = this.cleanCreateEditPayload(payload);
    return this.postFor<CreateEditRuleSetModel, KeyResult>('/admin/rulesets/' + cleanedPayload.programKey, cleanedPayload);
  }

  updateRuleSet(payload: CreateEditRuleSetModel): Observable<KeyResult> {
    const cleanedPayload = this.cleanCreateEditPayload(payload);
    return this.putFor<CreateEditRuleSetModel, KeyResult>('/admin/rulesets/' + cleanedPayload.programKey, cleanedPayload);
  }

  private cleanCreateEditPayload(payload: CreateEditRuleSetModel): CreateEditRuleSetModel {
    const cleaned: CreateEditRuleSetModel = cloneJson(payload);

    // Remove all the view-model fields
    // And some unneeded fields to lighten the payload
    cleaned.rules.forEach(rule => {
      delete rule.allProducts;
      delete rule.filteredProducts;
      delete rule.productFilter;
      delete rule.productPageNumber;
      delete rule.showSelectedProductsOnly;
      rule.products.forEach(product => {
        delete product.name;
        delete product.selected;
      });

      delete rule.allStores;
      delete rule.filteredStores;
      delete rule.storeFilter;
      delete rule.storePageNumber;
      delete rule.showSelectedStoresOnly;
      rule.stores.forEach(store => {
        delete store.aliases;
        delete store.name;
        delete store.selected;
        delete store.shouldAutoApprove;
      });
    });

    return cleaned;
  }
}
