import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CategoryCreateRequestModel } from '../../../category/models/webapi/request/category-create-request.model';
import { CategoryRequestModel } from '../../../category/models/webapi/request/category-request.model';
import { CategoryResultsModel } from '../../../shared/models/webapi/response/category-results.model';
import { CategoryModel } from '../../../shared/models/webapi/response/category.model';
import { Constants } from '../../../constants';
import { ContinuationTokenResponseModel } from '../../../shared/models/continuation-token-response.model';
import { KeyResult } from '../../../shared/models/key-result.model';
import { ModerationApiService } from './moderation-api.service';
import { KeyResultsModel } from '../../../shared/models/webapi/response/key-results.model';
import { UpdateKeyRequestModel } from '../../../category/models/webapi/request/update-key-request.model';
import { KeyModel } from '../../../shared/models/webapi/response/key.model';
import { KeyRequestModel } from '../../../category/models/webapi/request/key-request.model';

@Injectable()
export class CategoryApiService extends ModerationApiService {

  private continuationTokenHeader = Constants.HeaderContinuationToken;
  private defaultMaxItems = Constants.DefaultMaxItems;

  constructor(http: HttpClient) {
    super(http);
  }

  public getCategories(programKey: string) {
    return this.getFor<CategoryResultsModel>(`/admin/lookup/categories?programKey=${programKey}`);
  }

  public createCategory(category: CategoryCreateRequestModel) {
    return this.postFor<CategoryCreateRequestModel, KeyResult>('/admin/category', category);
  }

  public getCategory(categoryKey: string): Observable<CategoryModel> {
    return this.getFor<CategoryModel>(`/admin/category/${categoryKey}`);
  }

  public updateCategory(category: CategoryRequestModel) {
    return this.putFor<CategoryRequestModel, KeyResult>(`/admin/category/${category.categoryKey}`, category);
  }

  public getKey(categoryKey: string, keyName): Observable<KeyModel> {
    return this.getFor<KeyModel>(`/admin/category/${categoryKey}/${keyName}`);
  }

  public createKey(createKey: KeyRequestModel) {
    return this.postFor<KeyRequestModel, KeyResult>('/admin/category/key', createKey);
  }

  public updateKey(updateKey: UpdateKeyRequestModel) {
    return this.putFor<UpdateKeyRequestModel, KeyResult>(`/admin/category/key`, updateKey);
  }

  public getKeysForCategory(
    categoryKey: string
  ): Observable<KeyResultsModel> {
    return this.http.get<KeyResultsModel>(
        this.urlFor(`/admin/category/keys/${categoryKey}`), {
          observe: 'response'
    }).pipe(
      map(response => {
        return Object.assign(
          new KeyResultsModel(),
          {
            results: response.body.results
          });
      })
    );
  }

  public getRecentlyUpdatedCategories(
    maxItems: number = this.defaultMaxItems
  ): Observable<ContinuationTokenResponseModel<CategoryResultsModel>> {
    const params = new HttpParams().set('maxItems', `${maxItems}`);

    return this.http.get<CategoryResultsModel>(
        this.urlFor('/admin/category/recently/updated'), {
          observe: 'response',
          params: params
    }).pipe(
      map(response => {
        return Object.assign(
          new ContinuationTokenResponseModel<CategoryResultsModel>(),
          {
            continuationToken: response.headers.get(this.continuationTokenHeader),
            model: response.body
          });
      })
    );
  }

  public searchCategories(
    keyword: string,
    maxItems: number = this.defaultMaxItems
  ): Observable<ContinuationTokenResponseModel<CategoryResultsModel>> {
    const params = new HttpParams().set('maxItems', `${maxItems}`);

    return this.http.get<CategoryResultsModel>(
      this.urlFor(`/admin/category/search/${keyword}`), {
        observe: 'response',
        params: params
    }).pipe(
        map(response => {
          return Object.assign(
            new ContinuationTokenResponseModel<CategoryResultsModel>(),
            {
              continuationToken: response.headers.get(this.continuationTokenHeader) || '',
              model: response.body
            });
        })
    );
  }

  public searchCategoryContinuation(
    continuationToken: string
  ): Observable<ContinuationTokenResponseModel<CategoryResultsModel>> {
    const headers = new HttpHeaders().set(Constants.HeaderContinuationToken, continuationToken);

    return this.http.get<CategoryResultsModel>(
      this.urlFor(`/admin/category/search/continuation`), {
        headers: headers,
        observe: 'response'
        })
      .pipe(
        map(response => {
          return Object.assign(
            new ContinuationTokenResponseModel<CategoryResultsModel>(),
            {
              continuationToken: response.headers.get(this.continuationTokenHeader),
              model: response.body
            });
        })
    );
  }

  public getRecentlyUpdatedCategoriesContinuation(
    continuationToken: string
  ): Observable<ContinuationTokenResponseModel<CategoryResultsModel>> {
    const headers = new HttpHeaders().set(Constants.HeaderContinuationToken, continuationToken);

    return this.http.get<CategoryResultsModel>(
      this.urlFor('/admin/category/recently/updated/continuation'), {
        headers,
        observe: 'response'
        })
        .pipe(
        map(response => {
          return Object.assign(
            new ContinuationTokenResponseModel<CategoryResultsModel>(),
            {
              continuationToken: response.headers.get(this.continuationTokenHeader),
              model: response.body
            });
        })
    );
  }
}
