import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from '../../../../../node_modules/ngx-cookie-service';
import { Constants } from '../../../constants';

export class AccountChangedModel {
  public accountKey: string;
  public accountName: string;

  constructor(key: string, name: string) {
    this.accountKey = key;
    this.accountName = name;
  }
}

@Injectable()
export class CrossAccountService {
  public account: AccountChangedModel;
  private accountChangedSource = new BehaviorSubject<AccountChangedModel>(null);
  public accountChanged = this.accountChangedSource.asObservable();

  constructor(private cookieService: CookieService) {
    if (cookieService.check(Constants.HeaderAdminAccountKey)) {
      this.selectAccount(cookieService.get(Constants.HeaderAdminAccountKey), cookieService.get(Constants.CookieAdminAccountName));
    }
  }

  public selectAccount(key: string, name: string): void {
    this.account = new AccountChangedModel(key, name);

    this.cookieService.delete(Constants.HeaderAdminAccountKey);
    this.cookieService.delete(Constants.CookieAdminAccountName);
    this.cookieService.set(Constants.HeaderAdminAccountKey, key);
    this.cookieService.set(Constants.CookieAdminAccountName, name);

    this.accountChangedSource.next(this.account);
  }

  public clearAccount(): void {
    this.selectAccount('', '');
  }
}
