import { BehaviorSubject } from 'rxjs';
import { CookieService } from '../../../../../node_modules/ngx-cookie-service';
import { Constants } from '../../../constants';
var AccountChangedModel = /** @class */ (function () {
    function AccountChangedModel(key, name) {
        this.accountKey = key;
        this.accountName = name;
    }
    return AccountChangedModel;
}());
export { AccountChangedModel };
var CrossAccountService = /** @class */ (function () {
    function CrossAccountService(cookieService) {
        this.cookieService = cookieService;
        this.accountChangedSource = new BehaviorSubject(null);
        this.accountChanged = this.accountChangedSource.asObservable();
        if (cookieService.check(Constants.HeaderAdminAccountKey)) {
            this.selectAccount(cookieService.get(Constants.HeaderAdminAccountKey), cookieService.get(Constants.CookieAdminAccountName));
        }
    }
    CrossAccountService.prototype.selectAccount = function (key, name) {
        this.account = new AccountChangedModel(key, name);
        this.cookieService.delete(Constants.HeaderAdminAccountKey);
        this.cookieService.delete(Constants.CookieAdminAccountName);
        this.cookieService.set(Constants.HeaderAdminAccountKey, key);
        this.cookieService.set(Constants.CookieAdminAccountName, name);
        this.accountChangedSource.next(this.account);
    };
    CrossAccountService.prototype.clearAccount = function () {
        this.selectAccount('', '');
    };
    return CrossAccountService;
}());
export { CrossAccountService };
