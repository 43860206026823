import { StoreRoutingModule } from './store-routing.module';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { StoresComponent } from './pages/stores/stores.component';
import { CommonModule, DatePipe } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { StoreComponent } from './store.component';
import { SharedModule } from '../shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DataTablesModule } from 'angular-datatables';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { UiSwitchModule } from 'ngx-ui-switch';
import { GlobalStoresComponent } from './pages/global-stores/global-stores.component';

@NgModule({
  imports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    UiSwitchModule,
    TooltipModule,
    CollapseModule,
    AccordionModule,
    AlertModule,
    TabsModule,
    ModalModule.forRoot(),
    CommonModule,
    FormsModule,
    SharedModule,
    StoreRoutingModule,
    DataTablesModule
  ],
  declarations: [
    StoreComponent,
    StoresComponent,
    GlobalStoresComponent
  ],
  exports: [
    StoreRoutingModule,
    FormsModule
  ],
  providers: [
    // TODO: Move this to core if we need it elsewhere
    DatePipe
  ]
})
export class StoreModule {}
