import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Constants } from '../../../constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public isAuthorized: boolean;
  public accountName: string;

  constructor(private oidc: OidcSecurityService, private cookieService: CookieService) {
    this.accountName = cookieService.get(Constants.CookieAdminAccountName);

    this.oidc.getIsAuthorized().subscribe((isAuthorized: boolean) => {
      this.isAuthorized = isAuthorized;
    });
   }

  ngOnInit() {
  }

}
