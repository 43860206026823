import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Constants } from './../../../constants';
import { ModerationApiService } from './moderation-api.service';
import { cloneJson } from '../../../shared/utils/cloneJson';
var RuleSetApiService = /** @class */ (function (_super) {
    tslib_1.__extends(RuleSetApiService, _super);
    function RuleSetApiService(http) {
        return _super.call(this, http) || this;
    }
    RuleSetApiService.prototype.getRuleSetByKey = function (programKey, ruleSetKey) {
        return this.getFor('/admin/rulesets/' + programKey + '/' + ruleSetKey);
    };
    RuleSetApiService.prototype.getAllRuleSets = function () {
        return this.getWithResponseFor('/admin/rulesets');
    };
    RuleSetApiService.prototype.searchRuleSets = function (keyword) {
        return this.getWithResponseFor('/admin/rulesets/search/' + keyword);
    };
    RuleSetApiService.prototype.loadMoreRuleSets = function (token) {
        var options = {
            headers: new HttpHeaders().set(Constants.HeaderContinuationToken, token),
            observe: 'response'
        };
        return this.http.get(_super.prototype.urlFor.call(this, '/admin/rulesets/search/continuation'), options);
    };
    RuleSetApiService.prototype.isAvailableRuleSetKey = function (programKey, ruleSetKey) {
        return this.http.head(_super.prototype.urlFor.call(this, '/admin/rulesets/is-available-key/' + programKey + '/' + ruleSetKey));
    };
    RuleSetApiService.prototype.isAvailableRuleSetName = function (programKey, ruleSetName) {
        return this.http.head(_super.prototype.urlFor.call(this, '/admin/rulesets/is-available-name/' + programKey + '/' + ruleSetName));
    };
    RuleSetApiService.prototype.createRuleSet = function (payload) {
        var cleanedPayload = this.cleanCreateEditPayload(payload);
        return this.postFor('/admin/rulesets/' + cleanedPayload.programKey, cleanedPayload);
    };
    RuleSetApiService.prototype.updateRuleSet = function (payload) {
        var cleanedPayload = this.cleanCreateEditPayload(payload);
        return this.putFor('/admin/rulesets/' + cleanedPayload.programKey, cleanedPayload);
    };
    RuleSetApiService.prototype.cleanCreateEditPayload = function (payload) {
        var cleaned = cloneJson(payload);
        // Remove all the view-model fields
        // And some unneeded fields to lighten the payload
        cleaned.rules.forEach(function (rule) {
            delete rule.allProducts;
            delete rule.filteredProducts;
            delete rule.productFilter;
            delete rule.productPageNumber;
            delete rule.showSelectedProductsOnly;
            rule.products.forEach(function (product) {
                delete product.name;
                delete product.selected;
            });
            delete rule.allStores;
            delete rule.filteredStores;
            delete rule.storeFilter;
            delete rule.storePageNumber;
            delete rule.showSelectedStoresOnly;
            rule.stores.forEach(function (store) {
                delete store.aliases;
                delete store.name;
                delete store.selected;
                delete store.shouldAutoApprove;
            });
        });
        return cleaned;
    };
    return RuleSetApiService;
}(ModerationApiService));
export { RuleSetApiService };
