<div class="container">
  <div class="row">
    <div class="col-10">
      <h1>Global Retailers</h1>
      <h5>Changes here will affect all Programs.</h5>
      <p>Retailer names and Aliases are not case-sensitive.</p>
    </div>
  </div>
  <div *ngIf="isProcessing" class="center-stage">
    <app-folding-cube></app-folding-cube>
  </div>
  <ng-container *ngIf="!isProcessing">
    <alert *ngIf="alert" [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.message }}</alert>
    <div class="row">
      <div class="col-12">
        <div class="pull-left">
          <div class="input-group">
            <input type="text" class="form-control" id="txtSearchTerm" [(ngModel)]="searchTerm" (keyup)="filterStores()" placeholder="Search Retailers" />
            <div class="input-group-append cursor-pointer" (click)="clearTerm()">
              <span class="input-group-text">
                <span class="fa fa-times cursor-pointer"></span>
              </span>
            </div>
          </div>
        </div>
        <a class="btn btn-outline-info ml-2 text-info pull-right" (click)="openCreateStores(createTemplate)"><span class="fa fa-plus"></span> Add Retailer</a>      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-sm mt-4">
          <thead>
            <tr>
              <th>Name</th>
              <th>Aliases</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let store of filteredStores">
              <td>{{ store.name }}</td>
              <td>
                <ul class="aliases">
                  <li class="mr-3"><i class="fa fa-plus" (click)="openCreateAliases(store, createAliasTemplate)"></i></li>
                  <li class="mr-3" *ngFor="let alias of store.aliases">{{ alias.name }} <i class="fa fa-remove" (click)="openDeleteAlias(store, alias, deleteAliasConfirmation)"></i></li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #createTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Create Retailers</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="createModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Names (1 per line): </label>
      <textarea class="form-group" [(ngModel)]="newRetailerText" rows="10" cols="50"> </textarea>
    </div>
    <button (click)="createStores()" class="btn btn-primary">Save</button>
  </div>
</ng-template>

<ng-template #createAliasTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Create Aliases for {{ selectedStoreForAlias.name }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="createAliasModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Names (1 per line): </label>
      <textarea class="form-group" [(ngModel)]="newAliasText" rows="10" cols="50"> </textarea>
    </div>
    <button (click)="createAliases()" class="btn btn-primary">Save</button>
  </div>
</ng-template>

<ng-template #deleteAliasConfirmation>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Remove Alias</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="deleteAliasModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span>Are you sure you want to remove {{ selectedAlias.name }} from {{ selectedStoreForAlias.name }}?</span>
    <div class="mt-4">
      <button (click)="deleteAlias()" class="btn btn-danger">Delete</button>
      <button (click)="deleteAliasModalRef.hide()" class="btn btn-outline">Cancel</button>
    </div>
  </div>
</ng-template>
