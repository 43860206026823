import { EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CategoryApiService } from '../../../core/apis/moderation-api/category-api.service';
import { Alert } from '../../../shared/models/alert.model';
var KeyEditModalComponent = /** @class */ (function () {
    function KeyEditModalComponent(bsModalRef, categoryApi) {
        this.bsModalRef = bsModalRef;
        this.categoryApi = categoryApi;
        this.keyUpdated = new EventEmitter();
    }
    KeyEditModalComponent.prototype.ngOnInit = function () {
        var test = this.updateKey.isActive;
    };
    KeyEditModalComponent.prototype.saveKey = function () {
        var _this = this;
        this.isSubmitting = true;
        this.alert = null;
        this.categoryApi.updateKey(this.updateKey).subscribe(function (keyResult) {
            _this.keyUpdated.next('complete');
            _this.keyUpdated.complete();
            _this.bsModalRef.hide();
        }, function (error) {
            console.log(error);
            _this.isSubmitting = false;
            _this.alert = Alert.danger("Error updating key: " + _this.updateKey.name + ".");
        });
    };
    return KeyEditModalComponent;
}());
export { KeyEditModalComponent };
