<div class="modal-header">
    <h5 class="modal-title">Update Key</h5>
    <button type="button" class="close" (click)="bsModalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-12">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="isActiveStatus" [value]="true" [(ngModel)]="updateKey.isActive">
          <label class="form-check-label" for="inlineRadio1">Active</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="isActiveStatus" [value]="false" [(ngModel)]="updateKey.isActive">
          <label class="form-check-label" for="inlineRadio2">Deactivated</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span *ngIf="!updateKey.isActive" class="text-danger">A disabled a key will not be able to be referenced.</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="keyName" class="form-label" id="keyName-label">Name</label>
          <input [(ngModel)]="updateKey.newName" type="text" class="form-control" name="keyName" id="keyName" required minlength="1"
            maxlength="64" required />
          <input [(ngModel)]="updateKey.name" type="hidden" name="keyOrginalName" id="keyOrginalName" />
        </div>
      </div>
    </div>
    <alert *ngIf="alert" [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{alert.message}}</alert>
  </div>
  <div class="modal-footer">
    <button type="button" [disabled]="isSubmitting" class="btn btn-success" (click)="saveKey()">
      Save
      <span *ngIf="isSubmitting" class="fa-spin fa-spinner fa"></span>
    </button>
    <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()">Cancel</button>
  </div>
