<nav class="navbar navbar-expand-md bg-dark fixed-top" [ngClass]="environmentIndicator" [class.navbar-dark]="environmentIndicator === 'prd'" [class.navbar-light]="environmentIndicator !== 'prd'">
  <span class="version">v{{ version }} (Api: v{{ apiVersion }})</span>
  <span class="badge environment">{{ environmentIndicator }}</span>
  <a class="navbar-brand" [routerLink]="['/']">
    <span class="fa fa-check-square-o"></span> Moderation Admin</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarsDefault">
    <ul *ngIf="isAuthorized" class="navbar-nav mr-auto">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link link" [routerLink]="['/rulesets']">
          <span class="fa fa-cogs"></span> Rules</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link link" [routerLink]="['/actions']">
          <span class="fa fa-fighter-jet"></span> Quick Actions</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link link" [routerLink]="['/products']">
          <span class="fa fa-shopping-basket"></span> Products</a>
      </li>
      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link link" [routerLink]="['/stores']">
          <span class="fa fa-building"></span> Retailers</a>
      </li>
      <li>
        <a class="nav-link link" [routerLink]="['/categories']">
          <span class="fa fa-cubes"></span> Categories</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" (click)="isGlobalsSelectorCollapsed = !isGlobalsSelectorCollapsed" id="globalsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Universal Settings</a>
        <div class="dropdown-menu" aria-labelledby="globalsDropdown" [collapse]="isGlobalsSelectorCollapsed">
          <a class="dropdown-item" [routerLink]="['/boarding']" (click)="isGlobalsSelectorCollapsed = true"><span class="fa fa-bank"></span> Accounts & Programs</a>
          <a class="dropdown-item" [routerLink]="['/stores/globals']" (click)="isGlobalsSelectorCollapsed = true"><span class="fa fa-building"></span> Retailers</a>
        </div>
      </li>
    </ul>
    <ul *ngIf="isAuthorized" class="navbar-nav ml-auto mr-2">
      <li *ngIf="showAccountSelector" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" (click)="isAccountSelectorCollapsed = !isAccountSelectorCollapsed" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ accountName || "Select Account" }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown" [collapse]="isAccountSelectorCollapsed">
          <a class="dropdown-item" (click)="switchAccount()">Switch Account</a>
        </div>
      </li>
    </ul>
    <span *ngIf="isAuthorized" class="navbar-text">
      <span class="mr-2">{{userData.name}}</span>
      <a class="link" (click)="logoff()">Log Off</a>
    </span>
    <ul *ngIf="!isAuthorized" class="navbar-nav mr-auto"></ul>
    <span *ngIf="!isAuthorized" class="navbar-text">
      <a class="link" (click)="logon()">Log On</a>
    </span>
  </div>
</nav>
