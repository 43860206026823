import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Constants } from '../../../constants';
import { ContinuationTokenResponseModel } from '../../../shared/models/continuation-token-response.model';
import { ModerationApiService } from './moderation-api.service';
import { KeyResultsModel } from '../../../shared/models/webapi/response/key-results.model';
var CategoryApiService = /** @class */ (function (_super) {
    tslib_1.__extends(CategoryApiService, _super);
    function CategoryApiService(http) {
        var _this = _super.call(this, http) || this;
        _this.continuationTokenHeader = Constants.HeaderContinuationToken;
        _this.defaultMaxItems = Constants.DefaultMaxItems;
        return _this;
    }
    CategoryApiService.prototype.getCategories = function (programKey) {
        return this.getFor("/admin/lookup/categories?programKey=" + programKey);
    };
    CategoryApiService.prototype.createCategory = function (category) {
        return this.postFor('/admin/category', category);
    };
    CategoryApiService.prototype.getCategory = function (categoryKey) {
        return this.getFor("/admin/category/" + categoryKey);
    };
    CategoryApiService.prototype.updateCategory = function (category) {
        return this.putFor("/admin/category/" + category.categoryKey, category);
    };
    CategoryApiService.prototype.getKey = function (categoryKey, keyName) {
        return this.getFor("/admin/category/" + categoryKey + "/" + keyName);
    };
    CategoryApiService.prototype.createKey = function (createKey) {
        return this.postFor('/admin/category/key', createKey);
    };
    CategoryApiService.prototype.updateKey = function (updateKey) {
        return this.putFor("/admin/category/key", updateKey);
    };
    CategoryApiService.prototype.getKeysForCategory = function (categoryKey) {
        return this.http.get(this.urlFor("/admin/category/keys/" + categoryKey), {
            observe: 'response'
        }).pipe(map(function (response) {
            return Object.assign(new KeyResultsModel(), {
                results: response.body.results
            });
        }));
    };
    CategoryApiService.prototype.getRecentlyUpdatedCategories = function (maxItems) {
        var _this = this;
        if (maxItems === void 0) { maxItems = this.defaultMaxItems; }
        var params = new HttpParams().set('maxItems', "" + maxItems);
        return this.http.get(this.urlFor('/admin/category/recently/updated'), {
            observe: 'response',
            params: params
        }).pipe(map(function (response) {
            return Object.assign(new ContinuationTokenResponseModel(), {
                continuationToken: response.headers.get(_this.continuationTokenHeader),
                model: response.body
            });
        }));
    };
    CategoryApiService.prototype.searchCategories = function (keyword, maxItems) {
        var _this = this;
        if (maxItems === void 0) { maxItems = this.defaultMaxItems; }
        var params = new HttpParams().set('maxItems', "" + maxItems);
        return this.http.get(this.urlFor("/admin/category/search/" + keyword), {
            observe: 'response',
            params: params
        }).pipe(map(function (response) {
            return Object.assign(new ContinuationTokenResponseModel(), {
                continuationToken: response.headers.get(_this.continuationTokenHeader) || '',
                model: response.body
            });
        }));
    };
    CategoryApiService.prototype.searchCategoryContinuation = function (continuationToken) {
        var _this = this;
        var headers = new HttpHeaders().set(Constants.HeaderContinuationToken, continuationToken);
        return this.http.get(this.urlFor("/admin/category/search/continuation"), {
            headers: headers,
            observe: 'response'
        })
            .pipe(map(function (response) {
            return Object.assign(new ContinuationTokenResponseModel(), {
                continuationToken: response.headers.get(_this.continuationTokenHeader),
                model: response.body
            });
        }));
    };
    CategoryApiService.prototype.getRecentlyUpdatedCategoriesContinuation = function (continuationToken) {
        var _this = this;
        var headers = new HttpHeaders().set(Constants.HeaderContinuationToken, continuationToken);
        return this.http.get(this.urlFor('/admin/category/recently/updated/continuation'), {
            headers: headers,
            observe: 'response'
        })
            .pipe(map(function (response) {
            return Object.assign(new ContinuationTokenResponseModel(), {
                continuationToken: response.headers.get(_this.continuationTokenHeader),
                model: response.body
            });
        }));
    };
    return CategoryApiService;
}(ModerationApiService));
export { CategoryApiService };
