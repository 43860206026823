import { EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CategoryApiService } from '../../../core/apis/moderation-api/category-api.service';
import { Alert } from '../../../shared/models/alert.model';
import { CategoryCreateRequestModel } from '../../models/webapi/request/category-create-request.model';
var CategoryCreateModalComponent = /** @class */ (function () {
    function CategoryCreateModalComponent(bsModalRef, categoryApi) {
        this.bsModalRef = bsModalRef;
        this.categoryApi = categoryApi;
        this.category = new CategoryCreateRequestModel();
        this.categoryCreated = new EventEmitter();
    }
    CategoryCreateModalComponent.prototype.ngOnInit = function () { };
    CategoryCreateModalComponent.prototype.saveCategory = function () {
        var _this = this;
        this.isSubmitting = true;
        this.alert = null;
        this.categoryApi.createCategory(this.category).subscribe(function (keyResult) {
            _this.categoryCreated.next(keyResult.key);
            _this.categoryCreated.complete();
            _this.bsModalRef.hide();
        }, function (error) {
            _this.isSubmitting = false;
            _this.alert = Alert.danger('Error creating category.');
        });
    };
    CategoryCreateModalComponent.prototype.setKeys = function (values) {
        var cleanedValues = new Array();
        // Split string, trim spaces, remove empty, remove dupes.
        for (var _i = 0, _a = values // .split(',')
            .map(function (el) { return el.trim(); })
            .filter(function (v) { return v.trim() !== ''; })
            .filter(function (v, i, a) { return a.indexOf(v) === i; }); _i < _a.length; _i++) {
            var value = _a[_i];
            cleanedValues.push(value);
        }
        this.category.keys = cleanedValues;
    };
    CategoryCreateModalComponent.prototype.keyValuesChanged = function (value) {
        var values = value.split(',');
        this.setKeys(values);
    };
    return CategoryCreateModalComponent;
}());
export { CategoryCreateModalComponent };
