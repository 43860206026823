import { KeyResult } from './../../../shared/models/key-result.model';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProductResultsModel } from '../../../shared/models/webapi/response/product-results.model';
import { ProductFullModel } from '../../../shared/models/webapi/response/product-full.model';
import { Constants } from './../../../constants';
import { ModerationApiService } from './moderation-api.service';
import { CreateProductModel } from '../../../shared/models/webapi/request/create-product.model';
import { ProductRequestModel } from '../../../shared/models/webapi/request/product-request.model';
import { ProductKeywordUpdate } from '../../../shared/models/webapi/request/ProductKeywordUpdate.model';
import { ProductAttributeUpdate } from '../../../shared/models/webapi/request/ProductAttributeUpdate.model';
import { cloneJson } from '../../../shared/utils/cloneJson';
import { ProductAttributeModel } from '../../../shared/models/webapi/response/productAttributeModel';
import { ProductAttributesResultsModel } from '../../../shared/models/webapi/response/product-attributes-results.model';

@Injectable()
export class ProductApiService extends ModerationApiService {
  constructor(http: HttpClient) {
    super(http);
  }


  getProducts(): Observable<ProductResultsModel> {
    return this.getFor<ProductResultsModel>('/admin/lookup/products');
  }

  getProductsByCategoryKey(categoryKey: string): Observable<ProductResultsModel> {
    const options = { headers: new HttpHeaders().set(Constants.HeaderCategoryKey, categoryKey) };
    // Using http directly for headers
    return this.http.get<ProductResultsModel>(this.urlFor('/admin/products'), options);
  }

  getFullProductsByCategoryKey(categoryKey: string): Observable<ProductResultsModel> {
    const options = { headers: new HttpHeaders().set(Constants.HeaderCategoryKey, categoryKey) };

    return this.http.get<ProductResultsModel>(this.urlFor('/admin/products/full'), options);
  }

  getProductFull(productKey: string): Observable<ProductFullModel> {
    return this.http.get<ProductFullModel>(this.urlFor('/admin/products/' + productKey + '/full'));
  }

  getProductAttributes(productKey: string, programKey: string): Observable<ProductAttributesResultsModel> {
    return this.http.get<ProductAttributesResultsModel>(this.urlFor(`/admin/products/${productKey}/attributes/${programKey}`));
  }

  searchProductsInCategory(categoryKey: string, searchWords: string): Observable<HttpResponse<ProductResultsModel>> {
    const options: {headers: HttpHeaders, observe: 'response', params: HttpParams } = {
      headers: new HttpHeaders().set(Constants.HeaderCategoryKey, categoryKey),
      observe: 'response',
      params: new HttpParams().set('searchWords', searchWords)
    };

    return this.http.get<ProductResultsModel>(this.urlFor('/admin/products/search'), options);
  }

  searchProductsInCategoryContinuation(token: string): Observable<HttpResponse<ProductResultsModel>> {
    const options: {headers: HttpHeaders, observe: 'response' } = {
      headers: new HttpHeaders().set(Constants.HeaderContinuationToken, token),
      observe: 'response'
    };

    return this.http.get<ProductResultsModel>(this.urlFor('/admin/products/search/continuation'), options);
  }

  createProduct(request: CreateProductModel): Observable<KeyResult> {
    return this.postFor<CreateProductModel, KeyResult>('/admin/products', request);
  }

  public updateProduct(request: ProductRequestModel): Observable<KeyResult> {
    return this.putFor<ProductRequestModel, KeyResult>('/admin/products', request);
  }

  public upsertKeyword(request: ProductKeywordUpdate) {
    const data = cloneJson(request);
    delete data.isGlobal; // Delete this viewmodel-only property so it doesn't confuse when looking at the raw payload over the wire
    return this.putFor<ProductKeywordUpdate, null>('/admin/products/keyword', data);
  }

  public updateAttributeList(request: ProductAttributeUpdate) {
    return this.putFor<ProductAttributeUpdate, null>('/admin/products/attribute', request);
  }

}
