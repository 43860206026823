import { RuleSetRoutingModule } from './ruleset-routing.module';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RuleSetsComponent } from './pages/rulesets/rulesets.component';
import { CommonModule, DatePipe } from '@angular/common';
import { RuleSetComponent } from './ruleset.component';
import { SharedModule } from '../shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { DataTablesModule } from 'angular-datatables';
import { DateTimeAdapter, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { UiSwitchModule } from 'ngx-ui-switch';
import { RulesetWizardComponent } from './pages/ruleset-wizard/ruleset-wizard.component';
import { UtcDateTimeAdapter } from '../core/adapters/UtcDateTimeAdapter';
import { MomentModule, DateFormatPipe } from 'ngx-moment';
import { TzPipe } from '../shared/pipes/tz.pipe';

@NgModule({
  imports: [
    MomentModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TooltipModule,
    CollapseModule,
    UiSwitchModule,
    AccordionModule,
    AlertModule,
    CommonModule,
    FormsModule,
    SharedModule,
    RuleSetRoutingModule,
    DataTablesModule
  ],
  declarations: [
    RuleSetComponent,
    RuleSetsComponent,
    RulesetWizardComponent
  ],
  exports: [
    RuleSetRoutingModule,
    FormsModule
  ],
  providers: [
    DatePipe,
    TzPipe,
    DateFormatPipe,
    { provide: DateTimeAdapter, useClass: UtcDateTimeAdapter },
  ]
})
export class RuleSetModule {}
