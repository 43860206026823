<div *ngIf="!redirecting" class="container">
  <p>
    Please choose an account to continue:
  </p>
  <section class="account-choice-wrapper">
    <div *ngFor="let account of accounts" [ngClass]="{'chosen': account.accountKey === chosenAccountKey}" class="account-choice" (click)="onAccountChoice(account.accountKey, account.name)">
      <div *ngIf="account.accountKey !== chosenAccountKey" class="account-name">{{account.name}}</div>
      <div *ngIf="account.accountKey === chosenAccountKey" class="account-name">{{account.name}}</div>
    </div>
  </section>
</div>
<div *ngIf="redirecting" class="center-stage">
  <app-folding-cube></app-folding-cube>
</div>
