import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ModerationApiService } from './moderation-api.service';
var QuickActionsApiService = /** @class */ (function (_super) {
    tslib_1.__extends(QuickActionsApiService, _super);
    function QuickActionsApiService(http) {
        return _super.call(this, http) || this;
    }
    QuickActionsApiService.prototype.getRuleSets = function (programKey) {
        return this.getFor("/admin/actions/" + programKey + "/rulesets");
    };
    QuickActionsApiService.prototype.getQuickActions = function (programKey) {
        return this.getFor("/admin/actions/" + programKey);
    };
    QuickActionsApiService.prototype.getQuickAction = function (quickActionKey) {
        return this.getFor("/admin/actions/action/" + quickActionKey);
    };
    QuickActionsApiService.prototype.create = function (payload) {
        return this.postFor('/admin/actions', payload);
    };
    QuickActionsApiService.prototype.update = function (quickActionKey, payload) {
        return this.putFor("/admin/actions/action/" + quickActionKey, payload);
    };
    return QuickActionsApiService;
}(ModerationApiService));
export { QuickActionsApiService };
