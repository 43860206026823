import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map } from 'rxjs/operators';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(oidc, router) {
        this.oidc = oidc;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.oidc.getIsAuthorized().pipe(map(function (isAuthorized) {
            if (!isAuthorized) {
                _this.router.navigateByUrl('/logon');
            }
            return isAuthorized;
        }));
    };
    AuthGuard.prototype.canActivateChild = function (childRoute, state) {
        return this.canActivate(childRoute, state);
    };
    return AuthGuard;
}());
export { AuthGuard };
