import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { CategoryApiService } from '../../../core/apis/moderation-api/category-api.service';
import { Alert } from '../../../shared/models/alert.model';
import { KeyResult } from '../../../shared/models/key-result.model';
import { CategoryCreateRequestModel } from '../../models/webapi/request/category-create-request.model';

@Component({
  selector: 'app-category-create-modal',
  templateUrl: './category-create-modal.component.html',
  styleUrls: ['./category-create-modal.component.scss']
})
export class CategoryCreateModalComponent implements OnInit {
  category: CategoryCreateRequestModel = new CategoryCreateRequestModel();
  alert: Alert;

  isSubmitting: boolean;

  categoryCreated: EventEmitter<string> = new EventEmitter<string>();

  constructor(public bsModalRef: BsModalRef, private categoryApi: CategoryApiService) {}

  ngOnInit() {}

  saveCategory() {
    this.isSubmitting = true;
    this.alert = null;
    this.categoryApi.createCategory(this.category).subscribe(
      (keyResult: KeyResult) => {
        this.categoryCreated.next(keyResult.key);
        this.categoryCreated.complete();
        this.bsModalRef.hide();
      },
      error => {
        this.isSubmitting = false;
        this.alert = Alert.danger('Error creating category.');
      }
    );
  }

  setKeys(values: string[]) {
    const cleanedValues = new Array<string>();

    // Split string, trim spaces, remove empty, remove dupes.
    for (const value of values // .split(',')
      .map(el => el.trim())
      .filter(v => v.trim() !== '')
      .filter((v, i, a) => a.indexOf(v) === i)) {
      cleanedValues.push(value);
    }

    this.category.keys = cleanedValues;
  }

  keyValuesChanged(value: string) {
    const values: string[] = value.split(',');
    this.setKeys(values);
  }
}
