import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { KeyResult } from '../../../shared/models/key-result.model';
import { CreateEditRuleSetModel } from '../../../shared/models/webapi/request/CreateEditRuleSetModel';
import { RuleSetResultsModel } from '../../../shared/models/webapi/response/RuleSetResultsModel';
import { Constants } from './../../../constants';
import { ModerationApiService } from './moderation-api.service';
import { QuickActionResultsModel } from '../../../shared/models/webapi/response/quick-action-results.model';
import { QuickActionModel } from '../../../shared/models/webapi/response/quick-action.model';

@Injectable()
export class QuickActionsApiService extends ModerationApiService {
  constructor(http: HttpClient) {
    super(http);
  }

  getRuleSets(programKey: string): Observable<RuleSetResultsModel> {
    return this.getFor<RuleSetResultsModel>(`/admin/actions/${programKey}/rulesets`);
  }

  getQuickActions(programKey: string): Observable<QuickActionResultsModel> {
    return this.getFor<QuickActionResultsModel>(`/admin/actions/${programKey}`);
  }

  getQuickAction(quickActionKey: string): Observable<QuickActionModel> {
    return this.getFor<QuickActionModel>(`/admin/actions/action/${quickActionKey}`);
  }

  create(payload: QuickActionModel): Observable<KeyResult> {
    return this.postFor<QuickActionModel, KeyResult>('/admin/actions', payload);
  }

  update(quickActionKey: string, payload: QuickActionModel): Observable<KeyResult> {
    return this.putFor<QuickActionModel, KeyResult>(`/admin/actions/action/${quickActionKey}`, payload);
  }
}
