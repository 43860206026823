/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./core/nav/top-nav/top-nav.component.ngfactory";
import * as i3 from "./core/nav/top-nav/top-nav.component";
import * as i4 from "angular-auth-oidc-client";
import * as i5 from "@angular/router";
import * as i6 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i7 from "./core/pages/cross-account/cross-account.service";
import * as i8 from "./core/apis/moderation-api/moderation-api.service";
import * as i9 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-top-nav", [], null, null, null, i2.View_TopNavComponent_0, i2.RenderType_TopNavComponent)), i1.ɵdid(1, 245760, null, 0, i3.TopNavComponent, [i4.OidcSecurityService, i5.Router, i6.CookieService, i7.CrossAccountService, i8.ModerationApiService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "main", [["role", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i9.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
