var BaseApi = /** @class */ (function () {
    function BaseApi(urlRoot, http) {
        this.urlRoot = urlRoot;
        this.http = http;
        this.urlRoot = urlRoot;
        while (this.urlRoot.endsWith('/')) {
            this.urlRoot = this.urlRoot.substr(0, this.urlRoot.length - 1);
        }
    }
    BaseApi.prototype.urlFor = function (path) {
        return "" + this.urlRoot + path;
    };
    BaseApi.prototype.getFor = function (path) {
        return this.http.get(this.urlFor(path));
    };
    BaseApi.prototype.getWithResponseFor = function (path) {
        return this.http.get(this.urlFor(path), { observe: 'response' });
    };
    BaseApi.prototype.getWithParamsFor = function (path, params) {
        return this.http.get(this.urlFor(path), { params: params });
    };
    BaseApi.prototype.getWithParamsAndResponseFor = function (path, params) {
        return this.http.get(this.urlFor(path), { observe: 'response', params: params });
    };
    BaseApi.prototype.postFor = function (path, request) {
        return this.http.post(this.urlFor(path), request);
    };
    BaseApi.prototype.postWithParamsFor = function (path, request, params) {
        return this.http.post(this.urlFor(path), request, { params: params });
    };
    BaseApi.prototype.putFor = function (path, request) {
        return this.http.put(this.urlFor(path), request);
    };
    BaseApi.prototype.deleteFor = function (path) {
        return this.http.delete(this.urlFor(path));
    };
    BaseApi.prototype.patchFor = function (path, patch) {
        return this.http.patch(this.urlFor(path), patch);
    };
    return BaseApi;
}());
export { BaseApi };
