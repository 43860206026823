export enum RuleKind {
  Boolean = 1,
  ReceiptPurchaseDate,
  ReceiptItemProductQuantity,
  ReceiptStore,
  ReceiptItemProductTotal,
  ReceiptItemProductQuantityAndTotal,
  ReceiptItemProductMinimumQuantity,
  ReceiptItemProductMinimumTotal,
  ReceiptItemProductMinimumQuantityAndTotal,
}
