import { RuleSetType } from '../../rule-set-type.enum';
var CreateEditRuleSetModel = /** @class */ (function () {
    function CreateEditRuleSetModel() {
        this.ruleSetType = RuleSetType.Receipt;
        this.name = '';
        this.ruleSetKey = '';
        this.isAutomaticReceiptPaidFor = true;
        this.isEligibleForAutoApprove = true;
        this.programKey = '';
        this.rules = new Array();
    }
    return CreateEditRuleSetModel;
}());
export { CreateEditRuleSetModel };
