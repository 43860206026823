import { StoresComponent } from './pages/stores/stores.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { StoreComponent } from './store.component';
import { GlobalStoresComponent } from './pages/global-stores/global-stores.component';

const storeRoutes: Routes = [
  {
    path: 'stores',
    component: StoreComponent,
    children: [
      { path: '', component: StoresComponent },
      { path: 'globals', component: GlobalStoresComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(storeRoutes)],
  exports: [RouterModule],
  providers: [
  ]
})
export class StoreRoutingModule { }
