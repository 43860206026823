import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Constants } from './../../../constants';
import { ModerationApiService } from './moderation-api.service';
import { cloneJson } from '../../../shared/utils/cloneJson';
var ProductApiService = /** @class */ (function (_super) {
    tslib_1.__extends(ProductApiService, _super);
    function ProductApiService(http) {
        return _super.call(this, http) || this;
    }
    ProductApiService.prototype.getProducts = function () {
        return this.getFor('/admin/lookup/products');
    };
    ProductApiService.prototype.getProductsByCategoryKey = function (categoryKey) {
        var options = { headers: new HttpHeaders().set(Constants.HeaderCategoryKey, categoryKey) };
        // Using http directly for headers
        return this.http.get(this.urlFor('/admin/products'), options);
    };
    ProductApiService.prototype.getFullProductsByCategoryKey = function (categoryKey) {
        var options = { headers: new HttpHeaders().set(Constants.HeaderCategoryKey, categoryKey) };
        return this.http.get(this.urlFor('/admin/products/full'), options);
    };
    ProductApiService.prototype.getProductFull = function (productKey) {
        return this.http.get(this.urlFor('/admin/products/' + productKey + '/full'));
    };
    ProductApiService.prototype.getProductAttributes = function (productKey, programKey) {
        return this.http.get(this.urlFor("/admin/products/" + productKey + "/attributes/" + programKey));
    };
    ProductApiService.prototype.searchProductsInCategory = function (categoryKey, searchWords) {
        var options = {
            headers: new HttpHeaders().set(Constants.HeaderCategoryKey, categoryKey),
            observe: 'response',
            params: new HttpParams().set('searchWords', searchWords)
        };
        return this.http.get(this.urlFor('/admin/products/search'), options);
    };
    ProductApiService.prototype.searchProductsInCategoryContinuation = function (token) {
        var options = {
            headers: new HttpHeaders().set(Constants.HeaderContinuationToken, token),
            observe: 'response'
        };
        return this.http.get(this.urlFor('/admin/products/search/continuation'), options);
    };
    ProductApiService.prototype.createProduct = function (request) {
        return this.postFor('/admin/products', request);
    };
    ProductApiService.prototype.updateProduct = function (request) {
        return this.putFor('/admin/products', request);
    };
    ProductApiService.prototype.upsertKeyword = function (request) {
        var data = cloneJson(request);
        delete data.isGlobal; // Delete this viewmodel-only property so it doesn't confuse when looking at the raw payload over the wire
        return this.putFor('/admin/products/keyword', data);
    };
    ProductApiService.prototype.updateAttributeList = function (request) {
        return this.putFor('/admin/products/attribute', request);
    };
    return ProductApiService;
}(ModerationApiService));
export { ProductApiService };
