import { StoreModel } from '../response/StoreModel';
import { ProductModel } from '../response/product.model';
import { RuleSetType } from '../../rule-set-type.enum';
import { CreateEditRuleModel } from './create-edit-rule.model';

export class CreateEditRuleSetModel {
  ruleSetType: RuleSetType = RuleSetType.Receipt;
  name = '';
  ruleSetKey = '';
  isAutomaticReceiptPaidFor = true;
  isEligibleForAutoApprove = true;
  programKey = '';
  rules: Array<CreateEditRuleModel> = new Array<CreateEditRuleModel>();
}
