/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./store.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./store.component";
var styles_StoreComponent = [i0.styles];
var RenderType_StoreComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StoreComponent, data: {} });
export { RenderType_StoreComponent as RenderType_StoreComponent };
export function View_StoreComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_StoreComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-store", [], null, null, null, View_StoreComponent_0, RenderType_StoreComponent)), i1.ɵdid(1, 114688, null, 0, i3.StoreComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StoreComponentNgFactory = i1.ɵccf("app-store", i3.StoreComponent, View_StoreComponent_Host_0, {}, {}, []);
export { StoreComponentNgFactory as StoreComponentNgFactory };
