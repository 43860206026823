/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cross-account.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/widgets/folding-cube/folding-cube.component.ngfactory";
import * as i4 from "../../../shared/widgets/folding-cube/folding-cube.component";
import * as i5 from "./cross-account.component";
import * as i6 from "@angular/router";
import * as i7 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i8 from "./cross-account.service";
var styles_CrossAccountComponent = [i0.styles];
var RenderType_CrossAccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CrossAccountComponent, data: {} });
export { RenderType_CrossAccountComponent as RenderType_CrossAccountComponent };
function View_CrossAccountComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "account-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_CrossAccountComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "account-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_CrossAccountComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "account-choice"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAccountChoice(_v.context.$implicit.accountKey, _v.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "chosen": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CrossAccountComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CrossAccountComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "account-choice"; var currVal_1 = _ck(_v, 2, 0, (_v.context.$implicit.accountKey === _co.chosenAccountKey)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.$implicit.accountKey !== _co.chosenAccountKey); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_v.context.$implicit.accountKey === _co.chosenAccountKey); _ck(_v, 6, 0, currVal_3); }, null); }
function View_CrossAccountComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please choose an account to continue: "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "section", [["class", "account-choice-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CrossAccountComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.accounts; _ck(_v, 5, 0, currVal_0); }, null); }
function View_CrossAccountComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "center-stage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-folding-cube", [], null, null, null, i3.View_FoldingCubeComponent_0, i3.RenderType_FoldingCubeComponent)), i1.ɵdid(2, 114688, null, 0, i4.FoldingCubeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_CrossAccountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CrossAccountComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CrossAccountComponent_5)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.redirecting; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.redirecting; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CrossAccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cross-account", [], null, null, null, View_CrossAccountComponent_0, RenderType_CrossAccountComponent)), i1.ɵdid(1, 114688, null, 0, i5.CrossAccountComponent, [i6.ActivatedRoute, i6.Router, i7.CookieService, i8.CrossAccountService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CrossAccountComponentNgFactory = i1.ɵccf("app-cross-account", i5.CrossAccountComponent, View_CrossAccountComponent_Host_0, {}, {}, []);
export { CrossAccountComponentNgFactory as CrossAccountComponentNgFactory };
