import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { Constants } from '../../../constants';
import { CategoryApiService } from '../../../core/apis/moderation-api/category-api.service';
import { ProgramApiService } from '../../../core/apis/moderation-api/program-api.service';
import { Alert } from '../../../shared/models/alert.model';
import { UpdateKeyRequestModel } from '../../models/webapi/request/update-key-request.model';
import { CategoryCreateModalComponent } from '../category-create-modal/category-create-modal.component';
import { CategoryEditModalComponent } from '../category-edit-modal/category-edit-modal.component';
import { KeyEditModalComponent } from '../key-edit-modal/key-edit-modal.component';
import { KeyCreateModalComponent } from '../key-create-modal/key-create-modal.component';
import { KeyRequestModel } from '../../models/webapi/request/key-request.model';
var CategoriesComponent = /** @class */ (function () {
    function CategoriesComponent(route, router, programApi, cookieService, categoryApi, modalService) {
        this.route = route;
        this.router = router;
        this.programApi = programApi;
        this.cookieService = cookieService;
        this.categoryApi = categoryApi;
        this.modalService = modalService;
        this.maxItems = Constants.DefaultMaxItems;
        // Category related
        this.categories = [];
        this.categoryKeys = new Map();
        this.isLoadingKeys = new Map();
        this.hadLoadedKeys = new Map();
        this.isLoadingPrograms = true;
        this.isLoading = false;
    }
    CategoriesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.programApi.getPrograms().subscribe(function (programResult) {
            _this.isLoadingPrograms = false;
            _this.programs = programResult.programs;
            if (_this.programs.length === 1) {
                // auto select the only one
                _this.selectedProgram = _this.programs[0];
                _this.onProgramChange();
            }
            else if (_this.cookieService.get(Constants.HeaderProgramKey)) {
                var programKey_1 = _this.cookieService.get(Constants.HeaderProgramKey);
                _this.selectedProgram = _this.programs.filter(function (p) { return p.programKey === programKey_1; })[0];
                _this.onProgramChange();
            }
        });
    };
    CategoriesComponent.prototype.getRecentlyUpdatedCategories = function () {
        var _this = this;
        this.isLoading = true;
        this.hasLoaded = false;
        this.noMoreResults = false;
        this.categoryApi
            .getRecentlyUpdatedCategories(this.maxItems)
            .subscribe(function (response) {
            var _a;
            _this.continuationToken = response.continuationToken;
            _this.isLoading = false;
            _this.hasLoaded = true;
            _this.categories.length = 0;
            if (response.model && response.model.results.length > 0) {
                (_a = _this.categories).push.apply(_a, response.model.results);
            }
        });
    };
    CategoriesComponent.prototype.loadMore = function () {
        var _this = this;
        this.fetchingMore = true;
        if (!this.hasSearched) {
            this.loadMoreSubscription = this.categoryApi
                .getRecentlyUpdatedCategoriesContinuation(this.continuationToken)
                .subscribe(function (response) {
                var _a;
                _this.continuationToken = response.continuationToken;
                if (response.model && response.model.results.length > 0) {
                    (_a = _this.categories).push.apply(_a, response.model.results);
                }
                else {
                    _this.noMoreResults = true;
                }
                _this.fetchingMore = false;
                _this.loadMoreSubscription.unsubscribe();
            });
        }
        else {
            this.loadMoreSubscription = this.categoryApi
                .searchCategoryContinuation(this.continuationToken)
                .subscribe(function (response) {
                var _a;
                _this.continuationToken = response.continuationToken;
                if (response.model && response.model.results.length > 0) {
                    (_a = _this.categories).push.apply(_a, response.model.results);
                }
                else {
                    _this.noMoreResults = true;
                }
                _this.fetchingMore = false;
                _this.loadMoreSubscription.unsubscribe();
            });
        }
    };
    CategoriesComponent.prototype.searchCategories = function () {
        var _this = this;
        this.hasSearched = false;
        this.isSearching = true;
        this.noMoreResults = false;
        this.categoryApi
            .searchCategories(this.searchKeywords || '', this.maxItems)
            .subscribe(function (response) {
            var _a;
            _this.continuationToken = response.continuationToken;
            _this.hasSearched = true;
            _this.isSearching = false;
            _this.categories.length = 0;
            if (response.model && response.model.results.length > 0) {
                (_a = _this.categories).push.apply(_a, response.model.results);
            }
        });
    };
    CategoriesComponent.prototype.clearResults = function () {
        this.hasSearched = false;
        this.isSearching = false;
        this.noMoreResults = false;
        this.searchKeywords = '';
        this.categories.length = 0;
        this.getRecentlyUpdatedCategories();
    };
    CategoriesComponent.prototype.openCreateCategoryModal = function () {
        var _this = this;
        var config = {
            keyboard: true
        };
        this.createCaterogyModalRef = this.modalService.show(CategoryCreateModalComponent, config);
        this.createCaterogyModalRef.content.categoryCreated.subscribe(function (category) {
            _this.clearResults();
        });
    };
    CategoriesComponent.prototype.openEditCategoryModal = function (categoryKey) {
        var _this = this;
        this.categoryApi.getCategory(categoryKey).subscribe(function (category) {
            var config = {
                keyboard: true,
                initialState: { category: category }
            };
            _this.editCategoryModalRef = _this.modalService.show(CategoryEditModalComponent, config);
            _this.editCategoryModalRef.content.categoryUpdated.subscribe(function (result) {
                _this.clearResults();
            });
        }, function (error) {
            _this.alert = Alert.danger('Error creating category.');
        });
    };
    CategoriesComponent.prototype.openCreateKeyModal = function (categoryKey) {
        var _this = this;
        this.alert = null;
        var createKey = new KeyRequestModel();
        createKey.categoryKey = categoryKey;
        createKey.isActive = true;
        var config = {
            keyboard: true,
            initialState: { createKey: createKey }
        };
        this.createKeyModalRef = this.modalService.show(KeyCreateModalComponent, config);
        this.createKeyModalRef.content.keyCreated.subscribe(function (category) {
            _this.getKeysForCategory(categoryKey);
            _this.categoryApi.getCategory(categoryKey).subscribe(function (updatedCategory) {
                var parentCategory = _this.categories.find(function (x) { return x.categoryKey === categoryKey; });
                parentCategory.activeKeyCount = updatedCategory.activeKeyCount;
                parentCategory.inactiveKeyCount = updatedCategory.inactiveKeyCount;
            });
        }, function (error) {
            _this.alert = Alert.danger('Error creating key.');
        });
    };
    CategoriesComponent.prototype.openEditKeyModal = function (categoryKey, keyName) {
        var _this = this;
        this.alert = null;
        this.categoryApi.getKey(categoryKey, keyName).subscribe(function (key) {
            if (key) {
                var updateKey = new UpdateKeyRequestModel();
                updateKey.categoryKey = key.categoryKey;
                updateKey.name = key.name;
                updateKey.newName = key.name;
                updateKey.isActive = key.isActive;
                var config = {
                    keyboard: true,
                    initialState: { updateKey: updateKey }
                };
                _this.editKeyModalRef = _this.modalService.show(KeyEditModalComponent, config);
                _this.editKeyModalRef.content.keyUpdated.subscribe(function (result) {
                    _this.getKeysForCategory(categoryKey);
                    _this.categoryApi.getCategory(categoryKey).subscribe(function (updatedCategory) {
                        var parentCategory = _this.categories.find(function (x) { return x.categoryKey === categoryKey; });
                        parentCategory.activeKeyCount = updatedCategory.activeKeyCount;
                        parentCategory.inactiveKeyCount = updatedCategory.inactiveKeyCount;
                    });
                });
            }
            else {
                _this.alert = Alert.danger('Error retrieving key.');
            }
        }, function (error) {
            _this.alert = Alert.danger('Error retrieving key.');
        });
    };
    CategoriesComponent.prototype.getKeysForCategoryStateChange = function (isOpening, categoryKey) {
        if (isOpening) {
            this.getKeysForCategory(categoryKey);
        }
        else {
            if (this.categoryKeys[categoryKey]) {
                this.categoryKeys[categoryKey].length = 0;
            }
        }
    };
    CategoriesComponent.prototype.getKeysForCategory = function (categoryKey) {
        var _this = this;
        this.alert = null;
        this.isLoadingKeys[categoryKey] = true;
        this.categoryApi.getKeysForCategory(categoryKey).subscribe(function (response) {
            if (_this.categoryKeys[categoryKey]) {
                _this.categoryKeys[categoryKey].length = 0;
            }
            if (response.results && response.results.length > 0) {
                _this.categoryKeys[categoryKey] = response.results;
            }
            _this.isLoadingKeys[categoryKey] = false;
        }, function (error) {
            _this.isLoadingKeys[categoryKey] = false;
            _this.alert = Alert.danger('Error retreiving keys category.');
        });
    };
    CategoriesComponent.prototype.onProgramChange = function () {
        if (this.selectedProgram) {
            // The ProgramKeyInterceptor will pick this up. This is here until we get a program selection page
            this.cookieService.set(Constants.HeaderProgramKey, this.selectedProgram.programKey);
            this.getRecentlyUpdatedCategories();
        }
        else {
            this.cookieService.delete(Constants.HeaderProgramKey);
            this.clearResults();
        }
    };
    return CategoriesComponent;
}());
export { CategoriesComponent };
