import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../shared/shared.module';
import { CategoryRoutingModule } from './category-routing.module';
import { CategoryComponent } from './category.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { CategoryCreateModalComponent } from './pages/category-create-modal/category-create-modal.component';
import { CategoryEditModalComponent } from './pages/category-edit-modal/category-edit-modal.component';
import { KeyEditModalComponent } from './pages/key-edit-modal/key-edit-modal.component';
import { KeyCreateModalComponent } from './pages/key-create-modal/key-create-modal.component';

@NgModule({
  entryComponents: [
    CategoryCreateModalComponent,
    CategoryEditModalComponent,
    KeyEditModalComponent,
    KeyCreateModalComponent
  ],
  imports: [
    CommonModule,
    CategoryRoutingModule,
    TooltipModule,
    AlertModule,
    // Get around the lazy-loading problem - see https://github.com/angular/angular/issues/14324
    //  and https://github.com/valor-software/ngx-bootstrap/issues/2356
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    ButtonsModule,
    CommonModule,
    FormsModule,
    SharedModule,
    DataTablesModule
  ],
  declarations: [
    CategoryComponent,
    CategoriesComponent,
    CategoryCreateModalComponent,
    CategoryEditModalComponent,
    KeyEditModalComponent,
    KeyCreateModalComponent
  ],
  exports: [
    CategoryRoutingModule,
    FormsModule
  ]
})

export class CategoryModule { }
