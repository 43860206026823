/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signin-oidc.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/widgets/folding-cube/folding-cube.component.ngfactory";
import * as i3 from "../../../shared/widgets/folding-cube/folding-cube.component";
import * as i4 from "./signin-oidc.component";
import * as i5 from "angular-auth-oidc-client";
import * as i6 from "@angular/router";
var styles_SigninOidcComponent = [i0.styles];
var RenderType_SigninOidcComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SigninOidcComponent, data: {} });
export { RenderType_SigninOidcComponent as RenderType_SigninOidcComponent };
export function View_SigninOidcComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "center-stage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-folding-cube", [], null, null, null, i2.View_FoldingCubeComponent_0, i2.RenderType_FoldingCubeComponent)), i1.ɵdid(2, 114688, null, 0, i3.FoldingCubeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_SigninOidcComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signin-oidc", [], null, null, null, View_SigninOidcComponent_0, RenderType_SigninOidcComponent)), i1.ɵdid(1, 114688, null, 0, i4.SigninOidcComponent, [i5.OidcSecurityService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SigninOidcComponentNgFactory = i1.ɵccf("app-signin-oidc", i4.SigninOidcComponent, View_SigninOidcComponent_Host_0, {}, {}, []);
export { SigninOidcComponentNgFactory as SigninOidcComponentNgFactory };
