<div class="container">
  <div class="row">
    <div class="col-7">
      <h1>Rule Sets</h1>
    </div>
    <div class="col-5">
      <a [routerLink]="['create', 'wizard']" class="btn btn-outline-info pull-right mr-2"><span class="fa fa-magic"></span> Add Rule Set</a>
    </div>
  </div>
  <div class="row" *ngIf="!isLoadingPrograms">
    <div class="col-10">
      <div class="form-group">
        <label>Program</label>
        <select class="form-control novalidate" name="selectedProgram" [(ngModel)]="selectedProgram" (change)="onProgramChange()">
          <option [ngValue]="undefined">Please Select...</option>
          <option *ngFor="let program of programs" [ngValue]="program">{{program.name}}</option>
        </select>
      </div>
    </div>
  </div>
  <div [style.visibility]="selectedProgram ? 'visible' : 'hidden'">
    <ng-container>
      <form id="ruleset-search" class="form-inline my-3" #searchForm="ngForm" (submit)="searchRuleSets()" autocomplete="off">
        <label class="sr-only" for="search-keywords">Search Keywords</label>
        <input [(ngModel)]="searchKeywords" class="form-control mr-2" [class.is-valid]="searchForm.valid && !searchForm.pristine" [class.is-invalid]="searchForm.invalid && !searchForm.pristine" required type="text" id="search-keywords" name="search-keywords" placeholder="Keywords..." />
        <button type="submit" class="btn btn-primary">Search</button>
        <button *ngIf="hasSearched && !isSearching" type="button" (click)="resetResults()" class="btn btn-danger btn-sm ml-4">Clear Results</button>
      </form>
    </ng-container>
    <h3 class="my-3">Rule Sets</h3>
    <div *ngIf="isSearching && !hasSearched" class="center-stage">
      <app-folding-cube></app-folding-cube>
    </div>
    <div *ngIf="!hasSearched && !isSearching && ruleSets && ruleSets.items.length < 1">
      <h5>No rule sets created. <a [routerLink]="['/rulesets', 'create']">Create one</a> now.</h5>
    </div>
    <table *ngIf="ruleSets" [class.d-none]="isSearching" datatable [dtOptions]="dtOptions" id="rulesets-table" class="table table-hover table-sm prizelogic-table">
    </table>
    <button *ngIf="!isSearching && !noMoreSearchResults" class="btn btn-sm btn-primary my-3" (click)="loadMoreRuleSets()">Load More <span *ngIf="isLoadingMore" class="fa fa-spin fa-spinner"></span></button>
    <p *ngIf="!isSearching && noMoreSearchResults" class="my-3">No more results.</p>
  </div>
</div>
