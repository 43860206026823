import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { StoreResultsModel } from '../../../shared/models/webapi/response/StoreResultsModel';
import { CreateEditStoreModel } from '../../../shared/models/webapi/request/CreateEditStoreModel';
import { KeyResult } from '../../../shared/models/key-result.model';
import { Injectable } from '@angular/core';
import { ModerationApiService } from './moderation-api.service';
import { StoreModel } from '../../../shared/models/webapi/response/StoreModel';
import { StoreAliasModel } from '../../../shared/models/webapi/response/store-alias.model';

@Injectable()
export class StoreApiService extends ModerationApiService {
  constructor(http: HttpClient) {
    super(http);
  }

  getStores(): Observable<StoreResultsModel> {
    return this.getFor<StoreResultsModel>('/admin/lookup/stores');
  }

  searchStores(keyword: string, continuationToken: string, culture: string) {
    const params = {
      keyword: keyword,
      continuation: continuationToken,
      culture: culture
    };
    return this.getWithParamsFor<StoreResultsModel>('/store/search', params);
  }

  public getStoresForProgram(programKey): Observable<StoreResultsModel> {
    return this.getFor<StoreResultsModel>('/admin/stores/' + programKey);
  }

  createAliasesForStore(store: StoreModel, newAliases: string[]): Observable<KeyResult> {
    const payload = { names: newAliases };
    return this.postFor(`/admin/stores/global/${store.storeKey}/aliases`, payload);
  }

  createGlobalStores(newRetailers: string[]): Observable<any> {
    const payload = { names: newRetailers };
    return this.postFor(`/admin/stores/global`, payload);
  }

  createStore(payload: CreateEditStoreModel, programKey): Observable<KeyResult> {
    return this.postFor<CreateEditStoreModel, KeyResult>('/admin/stores/' + programKey, payload);
  }

  updateStore(payload: StoreModel, programKey): Observable<KeyResult> {
    return this.putFor<StoreModel, KeyResult>('/admin/stores/' + programKey, payload);
  }

  deleteStore(payload: StoreModel, programKey): Observable<any> {
    return this.deleteFor('/admin/stores/' + programKey + '/' + payload.storeKey);
  }

  deleteAliasForStore(store: StoreModel, alias: StoreAliasModel): Observable<any> {
    return this.deleteFor(`/admin/stores/global/${store.storeKey}/aliases/${alias.name}`);
  }


  public setStoreAutoApprove(programKey: string, storeKey: string, autoApprove: boolean): Observable<any> {
    return this.postFor<any, any>(`/admin/stores/${programKey}/${storeKey}/autoapprove/${autoApprove}`, null);
  }
}
