import { EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CategoryApiService } from '../../../core/apis/moderation-api/category-api.service';
import { Alert } from '../../../shared/models/alert.model';
var CategoryEditModalComponent = /** @class */ (function () {
    function CategoryEditModalComponent(bsModalRef, categoryApi) {
        this.bsModalRef = bsModalRef;
        this.categoryApi = categoryApi;
        this.categoryUpdated = new EventEmitter();
    }
    CategoryEditModalComponent.prototype.ngOnInit = function () { };
    CategoryEditModalComponent.prototype.saveCategory = function () {
        var _this = this;
        this.isSubmitting = true;
        this.alert = null;
        this.categoryApi.updateCategory(this.category).subscribe(function (keyResult) {
            _this.categoryUpdated.next('complete');
            _this.categoryUpdated.complete();
            _this.bsModalRef.hide();
        }, function (error) {
            _this.isSubmitting = false;
            _this.alert = Alert.danger('Error updating category.');
        });
    };
    return CategoryEditModalComponent;
}());
export { CategoryEditModalComponent };
