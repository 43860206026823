import { CookieService } from 'ngx-cookie-service';
import { Constants } from '../../constants';
var AccountKeyInterceptor = /** @class */ (function () {
    function AccountKeyInterceptor(cookieService) {
        this.cookieService = cookieService;
    }
    AccountKeyInterceptor.prototype.intercept = function (req, next) {
        var requestToForward = req;
        var headers = {};
        if (this.cookieService.check(Constants.HeaderAdminAccountKey)) {
            headers[Constants.HeaderAdminAccountKey] = this.cookieService.get(Constants.HeaderAdminAccountKey);
            console.debug("AccountKeyInterceptor added header: " + Constants.HeaderAdminAccountKey + " (" + headers[Constants.HeaderAdminAccountKey] + ")");
            requestToForward = req.clone({ setHeaders: headers });
        }
        return next.handle(requestToForward);
    };
    return AccountKeyInterceptor;
}());
export { AccountKeyInterceptor };
