export const environment = {
  production: false,
  urls: {
    moderationApi: 'https://moderation-api.qa.az.ebbo.com/'
  },
  oidc: {
    server: 'https://login.microsoftonline.com/050ea3de-6ab5-4bb6-ae57-0100b14df04d/v2.0',
    redirectUrl: 'https://moderation-admin.qa.az.ebbo.com/signin-oidc',
    clientId: '6cc6a7ce-65d3-475d-8ef3-4a0b8c64c3e2',
    scopes: 'openid profile',
    logoutRedirectUrl: 'https://moderation-admin.qa.az.ebbo.com/',
    issuerUri: 'https://login.microsoftonline.com/050ea3de-6ab5-4bb6-ae57-0100b14df04d/v2.0'
  },
  retryAttempts: 2
};
