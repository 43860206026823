import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { Constants } from '../../constants';

@Injectable()
export class AccountKeyInterceptor implements HttpInterceptor {

  constructor(
    private cookieService: CookieService
  ) {  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;

    const headers = {};
    if (this.cookieService.check(Constants.HeaderAdminAccountKey)) {
      headers[Constants.HeaderAdminAccountKey] = this.cookieService.get(Constants.HeaderAdminAccountKey);
      console.debug(`AccountKeyInterceptor added header: ${Constants.HeaderAdminAccountKey} (${headers[Constants.HeaderAdminAccountKey]})`);
      requestToForward = req.clone({ setHeaders: headers });
    }

    return next.handle(requestToForward);
  }
}
