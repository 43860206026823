import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ProgramApiService } from '../../apis/moderation-api/program-api.service';
var CrossAccountResolver = /** @class */ (function () {
    function CrossAccountResolver(programApi, router) {
        this.programApi = programApi;
        this.router = router;
    }
    CrossAccountResolver.prototype.resolve = function (route, state) {
        var _this = this;
        return this.programApi.getAccounts().pipe(take(1), map(function (accountResult) {
            if (accountResult && accountResult.accounts) {
                return accountResult.accounts.sort(function (a, b) { return a.name.localeCompare(b.name); });
            }
            else {
                // TODO: Send to an error page or something
                _this.router.navigateByUrl('/unauthorized');
                return null;
            }
        }));
    };
    return CrossAccountResolver;
}());
export { CrossAccountResolver };
