<div class="modal-header">
    <h5 class="modal-title">Add Key</h5>
    <button type="button" class="close" (click)="bsModalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="keyName" class="form-label" id="keyName-label">Name</label>
          <input [(ngModel)]="createKey.name" type="text" class="form-control" name="keyName" id="keyName" required minlength="1"
            maxlength="64" required />
          <input [(ngModel)]="createKey.categoryKey" type="hidden" name="categoryKey" id="categoryKey" />
        </div>
      </div>
    </div>
    <alert *ngIf="alert" [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{alert.message}}</alert>
  </div>
  <div class="modal-footer">
    <button type="button" [disabled]="isSubmitting" class="btn btn-success" (click)="saveKey()">
      Save
      <span *ngIf="isSubmitting" class="fa-spin fa-spinner fa"></span>
    </button>
    <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()">Cancel</button>
  </div>
