<div class="container">
  <div class="row">
    <div class="col-10">
      <h1>Categories</h1>
    </div>
    <alert *ngIf="alert" [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{alert.message}}</alert>
    <div class="col-2">
      <a *ngIf="selectedProgram" class="btn btn-outline-info text-info" (click)="openCreateCategoryModal()">
        <span class="fa fa-plus"></span> Add Category</a>
    </div>
  </div>
  <div class="row" *ngIf="!isLoadingPrograms">
    <div class="col-10">
      <div class="form-group">
        <label>Program</label>
        <select class="form-control novalidate" name="selectedProgram" [(ngModel)]="selectedProgram" (change)="onProgramChange()">
          <option [ngValue]="undefined">Please Select...</option>
          <option *ngFor="let program of programs" [ngValue]="program">{{program.name}}</option>
        </select>
      </div>
    </div>
  </div>
  <div *ngIf="!isLoading && selectedProgram">
    <div class="row">
      <div class="col-12">
        <form id="category-search" class="form-inline my-3" #searchForm="ngForm" (submit)="searchCategories()" autocomplete="off">
          <label class="sr-only mr-sm-2" for="search-keywords">Search Keywords</label>
          <input [(ngModel)]="searchKeywords" #keywords="ngModel" class="form-control mr-2" required type="text" id="search-keywords"
            name="search-keywords" placeholder="Keywords..." />
          <button [disabled]="keywords.pristine || keywords.invalid" type="submit" class="btn btn-primary mr-sm-2">Search <span class="fa fa-spin fa-spinner" *ngIf="isSearching"></span></button>
          <button *ngIf="hasSearched && !isSearching" type="button" (click)="clearResults()" class="btn btn-outline-secondary btn-sm">Reset</button>
        </form>
      </div>
    </div>
    <div *ngIf="false" class="row">
      <div class="col-12">
        <h6 class="h6 font-weight-light font-italic">*All times in Eastern</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h3 *ngIf="!hasSearched && !isSearching">Recent Categories</h3>
        <h3 *ngIf="hasSearched || isSearching">Search Results</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3" *ngIf="((hasSearched && !isSearching) || (hasLoaded && !isLoading)) && categories.length < 1">
        <h5>No categories found.</h5>
      </div>
    </div>
  </div>
  <div *ngIf="(isSearching || isLoading || isLoadingPrograms)" class="center-stage">
    <app-folding-cube></app-folding-cube>
  </div>
  <accordion *ngFor="let category of categories">
    <accordion-group (isOpenChange)="getKeysForCategoryStateChange($event, category.categoryKey)">
      <div accordion-heading class="clearfix py-0">
        <div class="row">
          <div class="col-7">
            <h5 class="mb-0">
                <span class="fa accordion-caret align-self-center"></span>
              {{category.name}}
            </h5>
          </div>
          <div nowrap class="col-4">
            Is Active:
            <a *ngIf="category.isActive">
              <span class='fa fa-check-square-o fa-lg'></span>
            </a>
            <a *ngIf="!category.isActive">
              <span class='fa fa-square-o fa-lg'></span>
            </a>
            Active Keys: {{category.activeKeyCount}} Inactive Keys: {{category.inactiveKeyCount}}
          </div>
          <div class="col-1">
            <a class='btn btn-outline-light pt-0' (click)="$event.stopPropagation(); openEditCategoryModal(category.categoryKey)">
              <span class='fa fa-edit fa-lg'></span>
            </a>
          </div>
        </div>
      </div>
      <div class="row mb-1 mt-0">
        <div class="col-10">
          <div *ngIf="isLoadingKeys[category.categoryKey]">
            <app-folding-cube></app-folding-cube>
          </div>
        </div>
        <div class="col-2">
          <a *ngIf="selectedProgram" class="btn btn-outline-info btn-sm text-info" (click)="openCreateKeyModal(category.categoryKey)">
            <span class="fa fa-plus"></span> Add Key</a>
        </div>
      </div>
      <div *ngIf="!isLoadingKeys[category.categoryKey]" class="row">
        <div nowrap *ngFor="let key of categoryKeys[category.categoryKey];" class="col-3 nowrap">
          <a class='btn btn-outline-light pt-0' (click)="$event.stopPropagation(); openEditKeyModal(category.categoryKey, key.name)">
            <span class='fa fa-edit fa-lg'></span>
          </a>
          <span nowrap [class.text-danger]="!key.isActive">{{key.name}}</span>
        </div>
      </div>
    </accordion-group>
  </accordion>
  <div class="row">
    <div *ngIf="noMoreResults && (hasSearched || hasLoaded)" class="my-3">
      <p>No more results.</p>
    </div>
    <button *ngIf="!noMoreResults && !isLoading && !isSearching && categories.length > 0" type="button" class="btn btn-sm btn-primary my-3"
      (click)="loadMore()">
      Load More...
      <span *ngIf="fetchingMore" class="fa fa-spin fa-spinner"></span>
    </button>
  </div>
</div>
