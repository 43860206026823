import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit, TemplateRef } from '@angular/core';
import { Alert } from '../../../shared/models/alert.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramApiService } from '../../../core/apis/moderation-api/program-api.service';
import { StoreApiService } from '../../../core/apis/moderation-api/store-api.service';
import { DatePipe } from '@angular/common';
import { StoreModel } from '../../../shared/models/webapi/response/StoreModel';
import { StoreResultsModel } from '../../../shared/models/webapi/response/StoreResultsModel';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CreateEditStoreModel } from '../../../shared/models/webapi/request/CreateEditStoreModel';
import { Constants } from '../../../constants';
import { CookieService } from 'ngx-cookie-service';
var StoresComponent = /** @class */ (function () {
    function StoresComponent(route, programApi, storeApi, router, datePipe, bsModalService, cookieService) {
        this.route = route;
        this.programApi = programApi;
        this.storeApi = storeApi;
        this.router = router;
        this.datePipe = datePipe;
        this.bsModalService = bsModalService;
        this.cookieService = cookieService;
        // store related
        this.programStores = new StoreResultsModel();
        this.hasStores = false;
        this.selectedStores = [];
        // filtering related
        this.searchTerm = '';
        this.filteredStores = [];
        // page directives
        this.isProcessing = true;
        this.allSelected = false;
        this.newRetailerText = '';
        this.storeSort = function (a, b) {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
        };
        this.programStores.stores = new Array();
    }
    StoresComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoadingPrograms = true;
        this.programApi.getPrograms().subscribe(function (model) {
            _this.programs = model.programs;
            _this.isLoadingPrograms = false;
            if (_this.programs.length === 0) {
                _this.alert = Alert.danger('No programs available for this account', 30000);
            }
            else if (_this.programs.length === 1) {
                _this.selectedProgram = _this.programs[0];
                _this.onSelectProgram();
            }
            else if (_this.cookieService.get(Constants.HeaderProgramKey)) {
                var programKey_1 = _this.cookieService.get(Constants.HeaderProgramKey);
                _this.selectedProgram = _this.programs.filter(function (p) { return p.programKey === programKey_1; })[0];
                _this.onSelectProgram();
            }
        });
    };
    StoresComponent.prototype.onOpenCreate = function (template) {
        this.createModalRef = this.bsModalService.show(template);
    };
    StoresComponent.prototype.onOpenEdit = function (template) {
        // open the edit modal
        this.editStore = tslib_1.__assign({}, this.selectedStores[0]);
        this.editModalRef = this.bsModalService.show(template);
    };
    StoresComponent.prototype.onOpenDelete = function (template) {
        this.deleteModalRef = this.bsModalService.show(template);
    };
    StoresComponent.prototype.onDeleteConfirmed = function () {
        var _this = this;
        this.deleteModalRef.hide();
        this.selectedStores.forEach(function (store) {
            _this.storeApi.deleteStore(store, _this.selectedProgram.programKey).subscribe(function () {
                _this.selectedStores.splice(_this.selectedStores.indexOf(store), 1);
                _this.programStores.stores.splice(_this.programStores.stores.indexOf(store), 1);
                _this.filterStores();
            });
        }, this);
    };
    StoresComponent.prototype.onEditStore = function () {
        var _this = this;
        // update the model
        this.editStore.name = this.editStore.name.trim();
        this.editModalRef.hide();
        if (this.editStore.name === '') {
            return;
        }
        this.isProcessing = true;
        this.storeApi.updateStore(this.editStore, this.selectedProgram.programKey).subscribe(function (storeKey) {
            _this.isProcessing = false;
            if (_this.editStore.storeKey === storeKey.key) {
                var existingStore = _this.programStores.stores.find(function (x) { return x.storeKey === storeKey.key; });
                existingStore.name = _this.editStore.name;
                _this.filterStores(); // update sorting
                return;
            }
            var removedStore = _this.programStores.stores.find(function (x) { return x.storeKey === _this.editStore.storeKey; });
            _this.programStores.stores.splice(_this.programStores.stores.indexOf(removedStore), 1);
            _this.selectedStores = new Array();
            if (!_this.programStores.stores.find(function (x) { return x.storeKey === storeKey.key; })) {
                _this.editStore.storeKey = storeKey.key;
                _this.programStores.stores.push(_this.editStore);
            }
            else {
                var match = _this.programStores.stores.find(function (x) { return x.storeKey === storeKey.key; });
                match.selected = true;
                _this.selectedStores.push(match);
            }
            _this.filterStores();
        });
    };
    StoresComponent.prototype.onSelectProgram = function () {
        var _this = this;
        if (this.selectedProgram) {
            this.cookieService.set(Constants.HeaderProgramKey, this.selectedProgram.programKey);
            this.isProcessing = true;
            this.storeApi.getStoresForProgram(this.selectedProgram.programKey).subscribe(function (storeResults) {
                if (storeResults) {
                    _this.programStores = storeResults;
                    _this.hasStores = _this.programStores.stores.length > 0;
                    _this.filteredStores = _this.programStores.stores;
                    _this.filterStores(); // sort
                    _this.isProcessing = false;
                }
                else {
                    // TODO: Send them somewhere important
                    _this.router.navigateByUrl('/unauthorized');
                }
            });
        }
        else {
            this.cookieService.delete(Constants.HeaderProgramKey);
            this.clearResults();
        }
    };
    StoresComponent.prototype.onSelectStore = function (store) {
        if (store.selected) {
            this.selectedStores.push(store);
            if (this.selectedStores.length === this.programStores.stores.length) {
                this.allSelected = true;
            }
        }
        else {
            this.selectedStores.splice(this.selectedStores.indexOf(store), 1);
            if (this.selectedStores.length === 0) {
                this.allSelected = false;
            }
        }
    };
    StoresComponent.prototype.onSelectAllStores = function () {
        var _this = this;
        this.programStores.stores.filter(function (x) { return x.selected !== _this.allSelected; }).forEach(function (store) {
            store.selected = _this.allSelected;
            _this.onSelectStore(store);
        }, this);
    };
    StoresComponent.prototype.ngAfterViewInit = function () { };
    StoresComponent.prototype.clearResults = function () {
        this.programStores = new StoreResultsModel();
        this.programStores.stores = new Array();
        this.selectedStores = new Array();
        this.isProcessing = false;
        this.searchTerm = '';
        this.filteredStores = new Array();
    };
    StoresComponent.prototype.filterStores = function () {
        var _this = this;
        if (this.searchTerm.trim() === '') {
            this.filteredStores = this.programStores.stores.sort(this.storeSort);
            return;
        }
        this.filteredStores = this.programStores.stores
            .filter(function (x) { return x.name.toLocaleLowerCase().indexOf(_this.searchTerm.trim().toLocaleLowerCase()) > -1; })
            .sort(this.storeSort);
    };
    StoresComponent.prototype.clearTerm = function () {
        this.searchTerm = '';
        this.filterStores();
    };
    StoresComponent.prototype.onCreateStores = function () {
        var _this = this;
        this.newRetailerText = this.newRetailerText.trim();
        var newRetailerTexts = [];
        this.newRetailerText.split('\n').forEach(function (text) {
            text = text.trim();
            if (text === '') {
                return;
            }
            if (newRetailerTexts.find(function (x) { return x.toLowerCase() === text.toLowerCase(); })) {
                return;
            }
            newRetailerTexts.push(text);
        }, this);
        this.createModalRef.hide();
        if (newRetailerTexts.length === 0) {
            return;
        }
        this.isProcessing = true; // Display the loader gif
        newRetailerTexts.forEach(function (text) {
            var existingStore = _this.programStores.stores.find(function (x) { return x.name.toLocaleLowerCase() === text.toLocaleLowerCase(); });
            if (existingStore) {
                // the store name already exists, but possibly cased differently
                if (text === existingStore.name) {
                    return;
                } // the names are exactly the same
                existingStore.name = text; // the name is only cased differently, and will not be updated in data
                return;
            }
            var createStore = new CreateEditStoreModel();
            createStore.programKey = _this.selectedProgram.programKey;
            createStore.name = text;
            _this.storeApi.createStore(createStore, _this.selectedProgram.programKey).subscribe(function (keyResult) {
                var newStore = new StoreModel();
                newStore.name = text;
                newStore.selected = false;
                newStore.storeKey = keyResult.key;
                _this.programStores.stores.push(newStore);
                _this.filterStores();
            });
        }, this);
        this.isProcessing = false; // Hide the loader gif,   would be nice to track the threads and turn this off after all threads complete.
    };
    StoresComponent.prototype.toggleShouldAutoApprove = function (store) {
        store.autoApproveUpdating = true;
        store.autoApproveHadError = false;
        this.storeApi.setStoreAutoApprove(this.selectedProgram.programKey, store.storeKey, !store.shouldAutoApprove).subscribe(function (_) {
            store.autoApproveUpdating = false;
        }, function (_) {
            store.autoApproveUpdating = false;
            store.autoApproveHadError = true;
            store.shouldAutoApprove = !store.shouldAutoApprove;
        });
    };
    return StoresComponent;
}());
export { StoresComponent };
