<div class="modal-header">
  <h5 class="modal-title">Add Category</h5>
  <button type="button" class="close" (click)="bsModalRef.hide()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-10">
      <div class="form-group">
        <label for="categoryName" class="form-label" id="categoryName-label">Name</label>
        <input [(ngModel)]="category.name" type="text" class="form-control" name="categoryName" id="categoryName"
          required minlength="1" maxlength="64" required />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="categoryKeys" class="form-label" id="categoryKeys-label">Keys</label>
        <textarea #values [ngModel]="category.keys" placeholder="Enter Keys separated by commas" #values class="form-control" id="categoryKeys" name="categoryKeys"
          rows="10" (change)="keyValuesChanged(values.value)"></textarea>
      </div>
    </div>
  </div>
  <alert *ngIf="alert" [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{alert.message}}</alert>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="isSubmitting" class="btn btn-success" (click)="saveCategory()">
    Save <span *ngIf="isSubmitting" class="fa-spin fa-spinner fa"></span>
  </button>
  <button type="button" class="btn btn-danger" (click)="bsModalRef.hide()">Cancel</button>
</div>
