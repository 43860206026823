import { RuleKind } from '../../rule-kind.enum';
var CreateEditRuleModel = /** @class */ (function () {
    function CreateEditRuleModel() {
        this.stores = new Array();
        this.products = new Array();
        // View model fields
        this.allStores = new Array();
        this.filteredStores = new Array();
        this.storePageNumber = 1;
        this.filteredProducts = new Array();
        this.productFilter = '';
        this.productPageNumber = 1;
        this.showSelectedProductsOnly = false;
        this.kind = RuleKind.Boolean;
        this.ruleKey = '';
        this.text = '';
        this.passingBooleanValue = true;
        this.showSelectedStoresOnly = false;
        this.showSelectedProductsOnly = false;
        this.storeFilter = '';
        this.productFilter = '';
        this.storePageNumber = 1;
        this.productPageNumber = 1;
    }
    return CreateEditRuleModel;
}());
export { CreateEditRuleModel };
