import { HttpErrorResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import * as HttpStatus from 'http-status-codes';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(injector, router, cookieService) {
        this.injector = injector;
        this.router = router;
        this.cookieService = cookieService;
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var requestToForward = req;
        if (this.oidcSecurityService === undefined) {
            this.oidcSecurityService = this.injector.get(OidcSecurityService);
        }
        if (this.oidcSecurityService !== undefined) {
            var token = this.oidcSecurityService.getToken();
            if (token !== '') {
                var tokenValue = 'Bearer ' + token;
                var headers = { 'Authorization': tokenValue };
                requestToForward = req.clone({ setHeaders: headers });
            }
        }
        else {
            console.debug('OidcSecurityService undefined: NO auth header!');
        }
        return next.handle(requestToForward)
            .pipe(tap(function () { }, // Do nothing on success
        function (// Do nothing on success
        error) {
            if (error instanceof HttpErrorResponse && error.status === HttpStatus.UNAUTHORIZED) {
                console.debug('Response UNAUTHORIZED, redirecting to session expired page.');
                _this.router.navigateByUrl('/sessionexpired');
            }
        }));
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
