import { OnInit, OnDestroy } from '@angular/core';
import { OidcSecurityService, AuthorizationState } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Constants } from '../../../constants';
import { CrossAccountService } from '../../pages/cross-account/cross-account.service';
import { ModerationApiService } from '../../apis/moderation-api/moderation-api.service';
import { ApplicationVersion } from '../../../../version';
import { environment } from '../../../../environments/environment';
var TopNavComponent = /** @class */ (function () {
    function TopNavComponent(oidc, router, cookieService, crossAccountService, moderationApi) {
        var _this = this;
        this.oidc = oidc;
        this.router = router;
        this.cookieService = cookieService;
        this.crossAccountService = crossAccountService;
        this.moderationApi = moderationApi;
        this.showAccountSelector = false;
        this.isAccountSelectorCollapsed = true;
        this.isGlobalsSelectorCollapsed = true;
        this.apiVersion = '...'; // retrieved later
        this.environmentIndicator = 'prd';
        this.accountName = cookieService.get(Constants.CookieAdminAccountName);
        this.version = ApplicationVersion.version;
        this.oidc.onAuthorizationResult.subscribe(function (result) {
            _this.isAuthorized = result.authorizationState === AuthorizationState.authorized;
        });
        this.environmentIndicator = this.getEnvironmentIndicator();
    }
    TopNavComponent.prototype.getEnvironmentIndicator = function () {
        // HACK: Using an existing piece of data that is already in the environment files so they do not have to be updated when upgrading to this version
        var url = environment.oidc.redirectUrl;
        if (url.match(/localhost/i)) {
            return 'loc';
        }
        else if (url.match(/\.dev\./i)) {
            return 'dev';
        }
        else if (url.match(/\.tst\./i)) {
            return 'tst';
        }
        else if (url.match(/\.staging\./i)) {
            return 'stg';
        }
        return 'prd';
    };
    TopNavComponent.prototype.urlBase64Decode = function (str) {
        if (!str) {
            return str;
        }
        var output = str.replace('-', '+').replace('_', '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                throw Error('Illegal base64url string!');
        }
        return window.atob(output);
    };
    TopNavComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.oidc.getIsAuthorized().subscribe(function (isAuthorized) {
            _this.isAuthorized = isAuthorized;
            _this.moderationApi.getVersion().subscribe(function (data) {
                _this.apiVersion = data.version;
            });
            if (_this.isAuthorized) {
                var token = _this.oidc.getToken();
                // const payload: PrizelogicTokenPayloadModel = JSON.parse(this.urlBase64Decode(token.split('.')[1]));
                var isCrossAccount = true;
                // if (typeof payload.cross_account === 'string') {
                //   isCrossAccount = (<string>payload.cross_account).match(/true/i).length > 0;
                // } else if (typeof payload.cross_account === 'boolean') {
                //   isCrossAccount = <boolean>payload.cross_account;
                // }
                _this.showAccountSelector = isCrossAccount;
            }
        });
        this.oidc.getUserData().subscribe(function (userData) {
            _this.userData = userData;
        });
        this.accountChangedSubscription = this.crossAccountService.accountChanged.subscribe(function (account) {
            if (account) {
                _this.accountName = account.accountName;
            }
            else {
                _this.accountName = '';
            }
        });
    };
    TopNavComponent.prototype.ngOnDestroy = function () {
        if (this.accountChangedSubscription) {
            this.accountChangedSubscription.unsubscribe();
        }
    };
    TopNavComponent.prototype.logon = function () {
        this.router.navigateByUrl('/logon');
    };
    TopNavComponent.prototype.logoff = function () {
        this.oidc.logoff();
    };
    TopNavComponent.prototype.switchAccount = function () {
        this.isAccountSelectorCollapsed = true;
        this.accountName = '';
        this.cookieService.delete(Constants.HeaderAdminAccountKey);
        this.cookieService.delete(Constants.CookieAdminAccountName);
        this.router.navigate(['/.cross-account'], { queryParams: { returnUrl: '/' } });
    };
    return TopNavComponent;
}());
export { TopNavComponent };
